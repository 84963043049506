import axios from '@/utilities/axios-global'

const GET_TENANT_SETUP = async (context, payload) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/tenantSetup`)
    context.commit('SET_TENANT_SETUP', response.data)

    if (response.data.imageUrl) {
      const precacheImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onload = () => resolve()
          img.onerror = reject
          img.src = src
        })
      }

      await precacheImage(response.data.imageUrl)
    }

    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_TENANT_SETUP = async (context, payload) => {
  try {
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/tenantSetup`, payload)
    context.commit('SET_TENANT_SETUP', response.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_TENANT_SETUP,
  PUT_TENANT_SETUP
}
