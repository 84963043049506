import axios from '@/utilities/axios-global'

const GET_INPATIENT_REVIEW = async (context, drgTableId) => {
  try {
    const response = await axios.get(context.rootState.user.baseApiUrl + `/reviews/inpatient/${drgTableId}`)
    context.commit('SET_INPATIENT_REVIEW', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const PUT_INPATIENT_REVIEW = async (context, payload) => {
  try {
    const response = await axios.put(context.rootState.user.baseApiUrl + `/reviews/inpatient/${payload.inpatientReview.drgTableId}?changedField=${payload.changedField}`, payload.inpatientReview)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_INPATIENT_DX = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/inpatient/${payload.dxRow.drgTableId}/dx/${payload.dxRow.dxId}?changedField=${payload.changedField}`, payload.dxRow)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const PUT_INPATIENT_DX_POAASSIGNMENTS = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/inpatient/${payload.drgTableId}/dx/poaassignments?changedField=${payload.changedField}`, payload.allDxCodes)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const POST_INPATIENT_DX = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const drgTableId = payload.length ? payload[0].drgTableId : payload.drgTableId
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/${drgTableId}/dx`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const DELETE_INPATIENT_DX = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/inpatient/${payload.drgTableId}/dx/${payload.dxId}`, payload)
    context.commit('DELETE_DX_ROW', payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const PUT_INPATIENT_DXS_FOR_RESEQUENCE = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/inpatient/${payload[0].drgTableId}/dx/resequence`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const PUT_INPATIENT_PX = async (context, payload) => {
  try {
    context.commit('SET_PX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/inpatient/${payload.pxRow.drgTableId}/px/${payload.pxRow.pxId}?changedField=${payload.changedField}`, payload.pxRow)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_PX_LOADING', false)
  }
}

const POST_INPATIENT_PX = async (context, payload) => {
  try {
    context.commit('SET_PX_LOADING', true)
    const drgTableId = payload.length ? payload[0].drgTableId : payload.drgTableId
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/${drgTableId}/px`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_PX_LOADING', false)
  }
}

const DELETE_INPATIENT_PX = async (context, payload) => {
  try {
    context.commit('SET_PX_LOADING', true)
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/inpatient/${payload.drgTableId}/px/${payload.pxId}`, payload)
    context.commit('DELETE_PX_ROW', payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_PX_LOADING', false)
  }
}

const PUT_INPATIENT_PXS_FOR_RESEQUENCE = async (context, payload) => {
  try {
    context.commit('SET_PX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/inpatient/${payload[0].drgTableId}/px/resequence`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_PX_LOADING', false)
  }
}

const LOAD_IP_LIST_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/paginated/${payload.projectID}`, payload)
    const wrapper = response.data
    if (context.state.ipListSelected.length > 0) {
      wrapper.data = wrapper.data.map(x => {
        x.checked = context.state.ipListSelected.some(y => y.drgTableId === x.drgTableId)
        return x
      })
    }
    return wrapper
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_IP_LIST_ALLIDS = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/allids/${payload.projectID}`, payload)
    context.commit('SET_IPLIST_ALLIDS', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_IPLIST_STATS = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/stats/${payload.projectID}`, payload)
    context.commit('SET_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_IPLIST_PROJECT_STATS = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/stats/${payload.projectID}`, payload)
    context.commit('SET_PROJECT_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_IP_RECORD_MULTIPLE = async (context, list) => {
  try {
    await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/multiple`, list)
  } catch (e) {
    if (e.response.data.message) {
      throw new Error(e.response.data.message)
    }
    throw new Error('Error Creating Records')
  }
}

const POST_DRG_TEMPLATE_MULTIPLE = async (context, list) => {
  try {
    await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/importTemplate`, list)
  } catch (e) {
    if (e.response.data.message) {
      throw new Error(e.response.data.message)
    }
    throw new Error('Error Creating Records')
  }
}

const IP_RECORD_EXISTS = async (context, newReview) => {
  try {
    return axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/exists`, newReview)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_IP_RECORD = async (context, newReview) => {
  try {
    const userEmail = context.rootState.user.user.email
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient?userEmail=${userEmail}`, newReview)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const DELETE_IP_RECORD = async (context, drgTableId) => {
  try {
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/inpatient/${drgTableId}`)
    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const RUN_IP_MASSUPDATE = async (context, massUpdateModel) => {
  try {
    await axios.put(`${context.rootState.user.baseApiUrl}/reviews/inpatient/massupdate`, massUpdateModel)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const RUN_IP_MASSDELETE = (context, ids) => {
  try {
    const response = axios.put(`${context.rootState.user.baseApiUrl}/reviews/inpatient/massdelete/`, ids)
    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_PRINCIPALDXANALYZE = async (context, payload) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reviews/inpatient/principaldxanalyze/${payload.drgtableId}`)
    // context.commit('SET_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_NOTIFY_CODERS = async (context, payload) => {
  try {
    await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/notifycoders/`, payload)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_INPATIENT_REVIEW,
  PUT_INPATIENT_REVIEW,
  PUT_INPATIENT_DX,
  DELETE_INPATIENT_DX,
  POST_INPATIENT_DX,
  PUT_INPATIENT_DX_POAASSIGNMENTS,
  PUT_INPATIENT_DXS_FOR_RESEQUENCE,
  PUT_INPATIENT_PX,
  POST_INPATIENT_PX,
  DELETE_INPATIENT_PX,
  PUT_INPATIENT_PXS_FOR_RESEQUENCE,
  LOAD_IP_LIST_PAGINATED,
  GET_IPLIST_STATS,
  IP_RECORD_EXISTS,
  GET_IP_LIST_ALLIDS,
  POST_IP_RECORD,
  POST_IP_RECORD_MULTIPLE,
  DELETE_IP_RECORD,
  RUN_IP_MASSUPDATE,
  GET_PRINCIPALDXANALYZE,
  POST_NOTIFY_CODERS,
  POST_DRG_TEMPLATE_MULTIPLE,
  RUN_IP_MASSDELETE,
  GET_IPLIST_PROJECT_STATS
}
