import { updateField } from 'vuex-map-fields'

const SET_MODIFIERS = (state, payload) => {
  state.modifierList = payload
}

const SET_SELECTED = (state, payload) => {
  payload.forEach(x => {
    const found = state.modifierList.find(y => y.modifierId === x.modifierId)
    if (found) {
      found.selected = x.selected
    }
  })
  state.modifierList = [...state.modifierList] // copy the array to trigger reactivity
}

const PUSH_MODIFIER = (state, payload) => {
  state.modifierList.push(payload)
}

const SPLICE_MODIFIER = (state, payload) => {
  const index = state.modifierList.findIndex(x => x.modifierId === payload.modifierId)
  state.modifierList.splice(index, 1)
}

const SET_MODIFIER_LOADING = (state, payload) => {
  state.modifierListLoading = payload
}

const UPDATE_MODIFIER = (state, payload) => {
  const index = state.modifierList.findIndex(x => x.modifierId === payload.modifierId)
  state.modifierList.splice(index, 1, payload)
}

export default {
  updateField,
  SET_MODIFIERS,
  SET_SELECTED,
  PUSH_MODIFIER,
  SPLICE_MODIFIER,
  SET_MODIFIER_LOADING,
  UPDATE_MODIFIER
}
