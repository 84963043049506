import axios from '@/utilities/axios-global'

const POST_REBUTTAL = async (context, newRebuttal) => {
  try {
    const response = await axios.post(context.rootState.user.baseApiUrl + '/reviews/rebuttals', newRebuttal)
    context.commit('SET_REBUTTAL', response.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_REBUTTAL_COMMENT = async (context, comment) => {
  try {
    const response = await axios.put(context.rootState.user.baseApiUrl + `/reviews/rebuttals/${comment.rebuttalId}/comments/${comment.commentId}`, comment)
    context.commit('SET_COMMENT', response.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_REBUTTAL = async (context, rebuttal) => {
  try {
    await axios.put(context.rootState.user.baseApiUrl + `/reviews/rebuttals/${rebuttal.rebuttalId}`, rebuttal)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const DELETE_REBUTTAL = async (context, id) => {
  try {
    await axios.delete(context.rootState.user.baseApiUrl + `/reviews/rebuttals/${id}`)
    context.commit('DELETE_REBUTTAL', id)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_REBUTTAL_COMMENT = async (context, payload) => {
  try {
    const response = await axios.post(context.rootState.user.baseApiUrl + `/reviews/rebuttals/${payload.rebuttalId}/comments`, payload)
    context.commit('ADD_COMMENT', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  POST_REBUTTAL,
  DELETE_REBUTTAL,
  PUT_REBUTTAL,
  POST_REBUTTAL_COMMENT,
  PUT_REBUTTAL_COMMENT
}
