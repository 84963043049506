import axios from '@/utilities/axios-global'

const GET_PRACTICES = async (context, clientNumber) => {
  try {
    context.commit('SET_PRACTICES_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/practices`)
    context.commit('SET_PRACTICES', response.data)
    context.commit('SET_PRACTICES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PRACTICES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_ASSOCIATE_PROVIDER_NEW = async (context, payload) => {
  try {
    context.commit('SET_PRACTICES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/practices/associateprovider`
    const response = await axios.post(url, payload)
    context.commit('PUSH_PRACTICE_PROVIDER_NEW', payload)
    context.commit('SET_PRACTICES_LOADING', false)
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_PRACTICES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_DISASSOCIATE_PROVIDER_NEW = async (context, payload) => {
  try {
    context.commit('SET_PRACTICES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/practices/associateprovider`
    await axios.put(url, payload)
    context.commit('SPLICE_PRACTICE_PROVIDER_NEW', payload)
    context.commit('SET_PRACTICES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PRACTICES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_PRACTICE = async (context, practice) => {
  try {
    context.commit('SET_PRACTICES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/practices`
    const response = await axios.post(url, practice)
    context.commit('PUSH_PRACTICE', response.data)
    context.commit('SET_PRACTICES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PRACTICES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_PRACTICE = async (context, practice) => {
  try {
    context.commit('SET_PRACTICES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/practices/${practice.practiceId}`
    await axios.put(url, practice)
    // context.commit('PUSH_PERDIEM', perDiem)
    context.commit('SET_PRACTICES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PRACTICES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_PRACTICE = async (context, practice) => {
  try {
    context.commit('SET_PRACTICES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/practices/${practice.practiceId}`
    await axios.delete(url)
    context.commit('REMOVE_CLIENTPRACTICE', practice)
    context.commit('SET_PRACTICES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PRACTICES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_PRACTICES,
  POST_ASSOCIATE_PROVIDER_NEW,
  DELETE_DISASSOCIATE_PROVIDER_NEW,
  POST_PRACTICE,
  PUT_PRACTICE,
  DELETE_PRACTICE
}
