import axios from '@/utilities/axios-global'

const GET_RECOMMENDATION_LIBRARY = async (context, query) => {
  // convert the recordTypes array into a series of &recordTypes= params
  let recordTypeSuffix = ''
  if (query.recordTypes) {
    for (let i = 0; i < query.recordTypes.length; i++) {
      recordTypeSuffix += '&recordTypes=' + query.recordTypes[i]
    }
  }

  const url = context.rootState.user.baseApiUrl + '/reviews/recommendation-library?recommendationType=' + query.recommendationType + recordTypeSuffix

  try {
    const response = await axios.get(url)
    context.commit('SET_RECOMMENDATION_LIBRARY', response.data)
  } catch (err) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message)
    }
    throw new Error('Error Getting Recommendation Library List')
  }
}

const GET_RECOMMENDATION_LIBRARY_ALL = async context => {
  const url = context.rootState.user.baseApiUrl + '/reviews/recommendation-library'

  try {
    const response = await axios.get(url)
    context.commit('SET_RECOMMENDATION_LIBRARY', response.data)
  } catch (err) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message)
    }
    throw new Error('Error Getting Recommendation Library List')
  }
}

const GET_RECOMMENDATION_LIBRARY_ALL_PRINTABLE = async context => {
  const url = context.rootState.user.baseApiUrl + '/reviews/recommendation-library/printable'

  try {
    const response = await axios.get(url)
    context.commit('SET_RECOMMENDATION_LIBRARY_PRINTABLE', response.data)
  } catch (err) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message)
    }
    throw new Error('Error Getting Recommendation Library List')
  }
}

const GET_RECOMMENDATION_RECORD = async (context, recommendationId) => {
  const url = context.rootState.user.baseApiUrl + '/reviews/recommendation-library/' + recommendationId

  try {
    const response = await axios.get(url)
    context.commit('SET_RECOMMENDATION', response.data)
  } catch (err) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message)
    }
    throw new Error('Error Getting Recommendation Library Record')
  }
}

const DELETE_RECOMMENDATION_RECORD = async (context, recommendationId) => {
  const url = context.rootState.user.baseApiUrl + '/reviews/recommendation-library/' + recommendationId

  try {
    await axios.delete(url)
    context.commit('DELETE_RECOMMENDATION', recommendationId)
  } catch (err) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message)
    }
    throw new Error('Error Deleting Recommendation Library Record')
  }
}

const PUT_RECOMMENDATION_RECORD = async (context, recommendationRecord) => {
  const url = context.rootState.user.baseApiUrl + '/reviews/recommendation-library/' + recommendationRecord.id

  try {
    const response = await axios.put(url, recommendationRecord)
    context.commit('SET_RECOMMENDATION', response.data)
  } catch (err) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message)
    }
    throw new Error('Error Saving Recommendation Library Record')
  }
}

const POST_RECOMMENDATION_RECORD = async (context, newRecommendation) => {
  const url = context.rootState.user.baseApiUrl + '/reviews/recommendation-library'

  try {
    const response = await axios.post(url, newRecommendation)
    context.commit('PUSH_RECOMMENDATION_LIBRARY', response.data)
  } catch (err) {
    if (err.response.data.message) {
      throw new Error(err.response.data.message)
    }
    throw new Error('Error Creating Recommendation Library Record')
  }
}

export default {
  GET_RECOMMENDATION_LIBRARY,
  GET_RECOMMENDATION_LIBRARY_ALL,
  GET_RECOMMENDATION_RECORD,
  POST_RECOMMENDATION_RECORD,
  PUT_RECOMMENDATION_RECORD,
  DELETE_RECOMMENDATION_RECORD,
  GET_RECOMMENDATION_LIBRARY_ALL_PRINTABLE
}
