import axios from '@/utilities/axios-global'

const LOAD_IPREPORTDATA_FILTERED = async (context, payload) => {
  try {
    context.commit('SET_REPORTS_IP_REPORTDATALOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reports/ipreportdata`, payload.inpatientReport)
    context.commit('SET_REPORTS_INPATIENT_DATA', response.data)
    context.commit('SET_REPORTS_IP_REPORTDATALOADING', false)
    return response.data
  } catch (err) {
    context.commit('SET_REPORTS_IP_REPORTDATALOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_PROREPORTDATA_FILTERED = async (context, payload) => {
  try {
    context.commit('SET_REPORTS_PRO_REPORTDATALOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reports/proreportdata`, payload.proReport)
    context.commit('SET_REPORTS_PRO_DATA', response.data)
    context.commit('SET_REPORTS_PRO_REPORTDATALOADING', false)
    return response.data
  } catch (err) {
    context.commit('SET_REPORTS_PRO_REPORTDATALOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_OPREPORTDATA_FILTERED = async (context, payload) => {
  try {
    context.commit('SET_REPORTS_OP_REPORTDATALOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reports/opreportdata`, payload.outpatientReport)
    context.commit('SET_REPORTS_OUTPATIENT_DATA', response.data)
    context.commit('SET_REPORTS_OP_REPORTDATALOADING', false)
    return response.data
  } catch (err) {
    context.commit('SET_REPORTS_OP_REPORTDATALOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_ASCREPORTDATA_FILTERED = async (context, payload) => {
  try {
    context.commit('SET_REPORTS_OP_REPORTDATALOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reports/opreportdata`, payload.outpatientReport)
    context.commit('SET_REPORTS_ASC_DATA', response.data)
    context.commit('SET_REPORTS_OP_REPORTDATALOADING', false)
    return response.data
  } catch (err) {
    context.commit('SET_REPORTS_OP_REPORTDATALOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_REPORTCSV = async (context, payload) => {
  try {
    switch (payload.type) {
      case 'ip':
        context.commit('SET_REPORTS_IP_REPORTDATALOADING', true)
        break
      case 'pro':
        context.commit('SET_REPORTS_PRO_REPORTDATALOADING', true)
        break
    }

    const url = `${context.rootState.user.baseApiUrl}/reports/buildreportcsv`
    const response = await axios.post(url, payload)
    switch (payload.type) {
      case 'ip':
        context.commit('SET_REPORTS_IP_REPORTDATALOADING', false)
        break
      case 'pro':
        context.commit('SET_REPORTS_PRO_REPORTDATALOADING', false)
        break
    }

    return response.data
  } catch (err) {
    console.log(err)
    switch (payload.type) {
      case 'ip':
        context.commit('SET_REPORTS_IP_REPORTDATALOADING', false)
        break
      case 'pro':
        context.commit('SET_REPORTS_PRO_REPORTDATALOADING', false)
        break
    }

    throw new Error(err.response.data.message)
  }
}

export default {
  LOAD_IPREPORTDATA_FILTERED,
  LOAD_PROREPORTDATA_FILTERED,
  LOAD_OPREPORTDATA_FILTERED,
  POST_REPORTCSV,
  LOAD_ASCREPORTDATA_FILTERED
}
