<template>
  <div>
    <h1 class="page-header">Not Authorized</h1>
    You are not authorized for the requested page. Please check with your system administrator or return
    <router-link to="/">home</router-link>.
  </div>
</template>

<script>
  export default {
    name: 'NotAuthorized'
  }
</script>
