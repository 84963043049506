import { updateField } from 'vuex-map-fields'

const SET_AUDITOR_DASHBOARD = (state, payload) => {
  state.auditorDashboard = payload
}

const SET_AUDITOR_DASHBOARD_USERS = (state, payload) => {
  state.auditorDashboardUsers = payload
}

export default {
  updateField,
  SET_AUDITOR_DASHBOARD,
  SET_AUDITOR_DASHBOARD_USERS
}
