import { updateField } from 'vuex-map-fields'

const SET_BLENDED_RATES_LOADING = (state, payload) => {
  state.blendedRatesLoading = payload
}

const SET_BLENDED_RATES = (state, payload) => {
  state.blendedRatesList = payload
}

const UPDATE_BLENDED_RATES = (state, payload) => {
  if (payload) {
    const index = state.blendedRatesList.findIndex(f => f.blendedRateId === payload.blendedRateId)
    state.blendedRatesList.splice(index, 1, payload)
  }
}

const SPLICE_BLENDED_RATES = (state, blendedRateId) => {
  const index = state.blendedRatesList.findIndex(x => x.blendedRateId === blendedRateId)
  state.blendedRatesList.splice(index, 1)
}

const PUSH_BLENDED_RATES = (state, payload) => {
  state.blendedRatesList.push(payload)
}

export default {
  updateField,
  SET_BLENDED_RATES,
  UPDATE_BLENDED_RATES,
  PUSH_BLENDED_RATES,
  SET_BLENDED_RATES_LOADING,
  SPLICE_BLENDED_RATES
}
