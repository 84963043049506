import axios from '@/utilities/axios-global'

const GET_CLIENTS = async (context, clientNumber) => {
  try {
    context.commit('SET_CLIENT_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/clients/list`)
    context.commit('SET_CLIENTS', response.data)
    context.commit('SET_CLIENT_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CLIENT_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_CLIENTS = async (context, payload) => {
  try {
    context.commit('SET_CLIENT_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/clients/${payload.clientId}`
    const response = await axios.put(url, payload)
    context.commit('UPDATE_CLIENT', response.data)
    context.commit('SET_CLIENT_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CLIENT_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_CLIENT = async (context, payload) => {
  try {
    context.commit('SET_CLIENT_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/clients`, payload.clientList)
    context.commit('PUSH_CLIENT', response.data)
    context.commit('SET_CLIENT_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CLIENT_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_CLIENT = async (context, clientId) => {
  try {
    context.commit('SET_CLIENT_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/clients/${clientId}`)
    context.commit('SPLICE_CLIENT', clientId)
    context.commit('SET_CLIENT_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CLIENT_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_CLIENTS,
  PUT_CLIENTS,
  POST_CLIENT,
  DELETE_CLIENT
}
