import axios from '@/utilities/axios-global'

const GET_BLENDED_RATES = async (context) => {
  try {
    context.commit('SET_BLENDED_RATES_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/blendedRates`)
    context.commit('SET_BLENDED_RATES', response.data)
    context.commit('SET_BLENDED_RATES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_BLENDED_RATES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_BLENDED_RATES = async (context, payload) => {
  try {
    context.commit('SET_BLENDED_RATES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/blendedRates/${payload.blendedRateId}`
    const response = await axios.put(url, payload)
    context.commit('UPDATE_BLENDED_RATES', response.data)
    context.commit('SET_BLENDED_RATES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_BLENDED_RATES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_BLENDED_RATES = async (context, payload) => {
  try {
    context.commit('SET_BLENDED_RATES_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/blendedRates`, payload)
    context.commit('PUSH_BLENDED_RATES', response.data)
    context.commit('SET_BLENDED_RATES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_BLENDED_RATES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_BLENDED_RATES = async (context, blendedRateId) => {
  try {
    context.commit('SET_BLENDED_RATES_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/blendedRates/${blendedRateId}`)
    context.commit('SPLICE_BLENDED_RATES', blendedRateId)
    context.commit('SET_BLENDED_RATES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_BLENDED_RATES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_BLENDED_RATES,
  PUT_BLENDED_RATES,
  POST_BLENDED_RATES,
  DELETE_BLENDED_RATES
}
