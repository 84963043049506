import axios from '@/utilities/axios-global'
// import hiaEventBus from '@/mixins/hiaEvent-Bus'

const GET_EXAMELEMENTS = (context) => {
  return new Promise((resolve, reject) => {
    const url = process.env.VUE_APP_API_BASE_URL + '/api/pro/examelements'
    axios.get(url).then(response => {
      context.commit('SET_EXAMELEMENTS', response.data)
      resolve(response)
    }, error => {
      reject(error)
      console.log(error)
    })
  })
}

const POST_PRORECORD_EXAMDETAILS = (context, proRecordExamDetails) => {
  return new Promise((resolve, reject) => {
    const url = process.env.VUE_APP_API_BASE_URL + '/api/reviews/pro/' + proRecordExamDetails.proRecordId + '/examdetails'
    axios.post(url, proRecordExamDetails).then(response => {
      // context.commit('PUSH_PRORECORD_EXAMDETAILS', proRecordExamDetails)
      resolve(response.data)
    }, error => {
      reject(error)
      console.log(error)
    })
  })
}

const DELETE_PRORECORD_EXAMDETAILS = (context, proRecordExamDetails) => {
  return new Promise((resolve, reject) => {
    const url = process.env.VUE_APP_API_BASE_URL + '/api/reviews/pro/' + proRecordExamDetails.proRecordId + '/examdetails'
    axios.delete(url, { data: proRecordExamDetails }).then(response => {
      // context.commit('SPLICE_PRORECORD_EXAMDETAILS', proRecordExamDetails)
      resolve(response.data)
    }, error => {
      reject(error)
      console.log(error)
    })
  })
}
export default {
  GET_EXAMELEMENTS,
  POST_PRORECORD_EXAMDETAILS,
  DELETE_PRORECORD_EXAMDETAILS
}
