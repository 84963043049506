import { updateField } from 'vuex-map-fields'

const SET_PRO_COMPLIANCE_REASONFORCHANGE = (state, payload) => {
  state.proComplianceReasonForChangeList = payload
}

const UPDATE_PRO_COMPLIANCE_REASONFORCHANGE = (state, payload) => {
  if (payload) {
    const index = state.proComplianceReasonForChangeList.findIndex(x => x.reasonForChangeId === payload.reasonForChangeId)
    state.proComplianceReasonForChangeList.splice(index, 1, payload)
  }
}

const PUSH_PRO_COMPLIANCE_REASONFORCHANGE = (state, payload) => {
  state.proComplianceReasonForChangeList.push(payload)
}

const SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING = (state, payload) => {
  state.proComplianceReasonForChangeLoading = payload
}

const CLEAR_PRO_COMPLIANCE_REASONFORCHANGE = (state, payload) => {
  state.proComplianceReasonForChangeList = []
}

const SPLICE_PRO_COMPLIANCE_REASONFORCHANGE = (state, reasonForChangeId) => {
  const index = state.proComplianceReasonForChangeList.findIndex(x => x.reasonForChangeId === reasonForChangeId)
  state.proComplianceReasonForChangeList.splice(index, 1)
}

export default {
  updateField,
  SET_PRO_COMPLIANCE_REASONFORCHANGE,
  UPDATE_PRO_COMPLIANCE_REASONFORCHANGE,
  PUSH_PRO_COMPLIANCE_REASONFORCHANGE,
  SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING,
  CLEAR_PRO_COMPLIANCE_REASONFORCHANGE,
  SPLICE_PRO_COMPLIANCE_REASONFORCHANGE
}
