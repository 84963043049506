
const csvGenerator = {
  methods: {
    arrayToCsv: function (array = [], filename = '', headers = [], excludeHeaders = []) {
      if (!array || !array.length) {
        return
      }

      const separator = ','
      let keys = []
      // keep only the headers passed in; headers are key/value pair for pretty headers; remove excludeHeaders
      if (headers.length) {
        keys = headers.filter(f => !excludeHeaders.find(({ v }) => f.key === v)).map(x => x.key)
      } else {
        keys = Object.keys(array[0])
        keys = keys.filter((v) => !excludeHeaders.includes(v))
      }

      const csvContent =
        csvGenerator.methods.headerFormat(headers, keys, separator) +
        '\n' +
        array.map(row => {
          return keys.map(k => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k]

            if (csvGenerator.methods.isArrayObject(cell)) {
              cell = ''
            }

            if (cell === '—') {
              cell = '-'
            }

            cell = cell instanceof Date
              ? cell.toLocaleString()
              : `${cell.toString().replace(/['"]+/g, '')}`

            if (cell.search(/("|,|\n)/g) >= 0 || cell.search(/(\r\n|\n|\r)/gm) >= 0) {
              cell = `"${cell.toString().replace(/['"]+/g, '')}"`
            }

            return cell
          }).join(separator)
        }).join('\n')

      csvGenerator.methods.generateLink(csvContent, filename)
    },
    tableToCsv: function (table, filename = '', headers = [], excludeHeaders = [], bedSizeActive = false, currentClient = '', showTitle = true) {
      if (!table || table === undefined) {
        return
      }

      const table2 = table.cloneNode(true)
      // remove element fixed table clone
      if (table2.querySelectorAll('.el-table__fixed').length) {
        const temp = table2.querySelectorAll('.el-table__fixed')
        for (let e = 0; e < temp.length; e++) {
          table2.removeChild(temp[e])
        }
      }

      // convert html table to array
      let tableInfo
      if (!bedSizeActive) {
        tableInfo = Array.prototype.map.call(table2.querySelectorAll('tr'), function (tr) {
          return Array.prototype.map.call(tr.querySelectorAll('th,td'), function (td) {
            return td.innerText.trim() === '—' ? '-' : td.innerText.trim()
          })
        })
      } else {
        tableInfo = csvGenerator.methods.bedSizeRowExtractor(table2, currentClient, showTitle)
      }

      // get heading on first row and rebuild array with keys
      const heading = tableInfo.shift()
      tableInfo = tableInfo.map((x, i) => {
        const z = {}
        heading.forEach((y, j) => {
          z[y] = x[j]
        })
        return z
      })

      csvGenerator.methods.arrayToCsv(tableInfo, filename, headers, excludeHeaders)
    },
    generateLink(csvContent, filename) {
      // colorado
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      // const file = new File([blob], filename)
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('style', 'display: none; float: left; position: absolute;')
          link.setAttribute('download', filename)
          link.setAttribute('target', '_blank')
          link.id = 'hiddenLinkCSV'
          link.href = url
          link.onclick = function (e) {
            e.cancelBubble = true
          }
          document.getElementsByTagName('body')[0].appendChild(link)
          document.getElementById('hiddenLinkCSV').click()
          const timerId = setInterval(() => {
            URL.revokeObjectURL(url)
            document.getElementById('hiddenLinkCSV').remove()
            clearInterval(timerId)
          }, 500)
        }
      }
    },
    headerFormat(headers, keys, separator) {
      return headers.length ? headers.map(x => x.value).join(separator) : keys.join(separator)
    },
    isArrayObject: function (value) {
      if (Array.isArray(value)) { return true }
      if (value && typeof value === 'object' && value.constructor === Object) { return true }
    },
    escapeRegExp: function (text) {
      return text.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&')
    }
  }
}

export default csvGenerator
