import { updateField } from 'vuex-map-fields'

const SET_CODE_TYPES_LOADING = (state, payload) => {
  state.codeTypesLoading = payload
}

const SET_CODE_TYPES = (state, payload) => {
  state.codeTypesList = payload
}

const UPDATE_CODE_TYPES = (state, payload) => {
  if (payload) {
    const index = state.codeTypesList.findIndex(f => f.codeNumber === payload.codeNumber)
    state.codeTypesList.splice(index, 1, payload)
  }
}

const SPLICE_CODE_TYPES = (state, codeNumber) => {
  const index = state.codeTypesList.findIndex(x => x.codeNumber === codeNumber)
  state.codeTypesList.splice(index, 1)
}

const PUSH_CODE_TYPES = (state, payload) => {
  state.codeTypesList.push(payload)
}

export default {
  updateField,
  SET_CODE_TYPES,
  UPDATE_CODE_TYPES,
  PUSH_CODE_TYPES,
  SET_CODE_TYPES_LOADING,
  SPLICE_CODE_TYPES
}
