import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
  userProfile: {
    userProfileId: null,
    userId: null,
    profile: {
      disableCoderResponse: false
    }
  },
  userProfileLoading: false
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
