import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  dateRangePickerConfig: {
    shortcuts: [{
      text: 'Last month',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: 'Last 3 months',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: 'Last year',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: 'Prev 2 years',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 730)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: 'Prev 3 years',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 1095)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: 'Year to date',
      onClick(picker) {
        const end = new Date()
        const start = new Date(new Date().getFullYear(), 0, 1)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: 'This Week',
      onClick(picker) {
        const today = new Date()
        const start = new Date(today.setDate(today.getDate() - today.getDay()))
        const end = new Date(today.setDate(today.getDate() - today.getDay() + 6))
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: 'Next Week',
      onClick(picker) {
        const today = new Date()
        const start = new Date(today.setDate(today.getDate() - today.getDay()))
        const end = new Date(today.setDate(today.getDate() - today.getDay() + 13))
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: 'Next Month',
      onClick(picker) {
        const start = new Date()
        const end = new Date()
        end.setMonth(start.getMonth() + 2, 0)
        picker.$emit('pick', [start, end])
      }
    }
    ]
  },
  dateRange: [],
  helixUpdateMessage: false
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
