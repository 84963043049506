import axios from '@/utilities/axios-global'

const GET_ASC_PATIENT_TYPES = async (context) => {
  try {
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/ascpatienttypes`)
    context.commit('SET_ASC_PATIENT_TYPES', response.data)
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_ASC_PATIENT_TYPES = async (context, payload) => {
  try {
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/ascpatienttypes/${payload.patientTypeId}`
    const response = await axios.put(url, payload)
    context.commit('UPDATE_ASC_PATIENT_TYPES', response.data)
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_ASC_PATIENT_TYPES = async (context, payload) => {
  try {
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/ascpatienttypes`, payload.ascPatientType)
    context.commit('PUSH_ASC_PATIENT_TYPES', response.data)
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_ASC_PATIENT_TYPES = async (context, patientTypeId) => {
  try {
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/ascpatienttypes/${patientTypeId}`)
    context.commit('SPLICE_ASC_PATIENT_TYPES', patientTypeId)
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_ASC_PATIENT_TYPES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_ASC_PATIENT_TYPES,
  PUT_ASC_PATIENT_TYPES,
  POST_ASC_PATIENT_TYPES,
  DELETE_ASC_PATIENT_TYPES
}
