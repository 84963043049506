import axios from '@/utilities/axios-global'

const GET_REASONFORCHANGES = async (context) => {
  try {
    context.commit('SET_REASONFORCHANGE_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reasonforchange`)
    context.commit('SET_REASONFORCHANGE', response.data)
    context.commit('SET_REASONFORCHANGE_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_REASONFORCHANGE_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_REASONFORCHANGES = async (context, payload) => {
  try {
    context.commit('SET_REASONFORCHANGE_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/reasonforchange/${payload.reasonForChangeId}`
    const response = await axios.put(url, payload)
    context.commit('UPDATE_REASONFORCHANGE', response.data)
    context.commit('SET_REASONFORCHANGE_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_REASONFORCHANGE_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_REASONFORCHANGES = async (context, payload) => {
  try {
    context.commit('SET_REASONFORCHANGE_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reasonforchange`, payload.reasonForChange)
    context.commit('PUSH_REASONFORCHANGE', response.data)
    context.commit('SET_REASONFORCHANGE_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_REASONFORCHANGE_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_REASONFORCHANGES = async (context, reasonForChangeId) => {
  try {
    context.commit('SET_REASONFORCHANGE_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/reasonforchange/${reasonForChangeId}`)
    context.commit('SPLICE_REASONFORCHANGE', reasonForChangeId)
    context.commit('SET_REASONFORCHANGE_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_REASONFORCHANGE_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_REASONFORCHANGES,
  PUT_REASONFORCHANGES,
  POST_REASONFORCHANGES,
  DELETE_REASONFORCHANGES
}
