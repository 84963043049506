
const SET_USERPROFILE = (state, userProfiledata) => {
  if (userProfiledata.profile) {
    state.userProfile.userProfileId = userProfiledata.userProfileId
    state.userProfile.userId = userProfiledata.userId
    state.userProfile.profile = JSON.parse(userProfiledata.profile)
  }
}

const SET_USERPROFILELOADING = (state, payload) => {
  state.notificationsLoading = payload
}

export default {
  SET_USERPROFILE,
  SET_USERPROFILELOADING
}
