import axios from '@/utilities/axios-global'

const GET_CLIENT_CHECKLISTS = async (context) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/client-checklist`)
    context.commit('SET_CLIENT_CHECKLIST', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_CLIENT_CHECKLIST = async (context, payload) => {
  try {
    const url = `${context.rootState.user.baseApiUrl}/client-checklist`
    const response = await axios.post(url, payload.checklist)
    context.commit('PUSH_CLIENT_CHECKLIST', response.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_CLIENT_CHECKLIST = async (context, checklist) => {
  try {
    const url = `${context.rootState.user.baseApiUrl}/client-checklist/${checklist.checklistId}`
    const response = await axios.put(url, checklist)
    context.commit('UPDATE_CLIENT_CHECKLIST', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const DELETE_CLIENT_CHECKLIST = async (context, checklist) => {
  try {
    const url = `${context.rootState.user.baseApiUrl}/client-checklist/${checklist.checklistId}`
    await axios.delete(url)
    context.commit('SPLICE_CLIENT_CHECKLIST', checklist)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_CLIENT_CHECKLISTS,
  DELETE_CLIENT_CHECKLIST,
  PUT_CLIENT_CHECKLIST,
  POST_CLIENT_CHECKLIST
}
