<template>
  <div class="notificationsDrawerTemplate">
    <!-- drawer  -->
    <el-drawer class="noficationsDrawer" title="Notifications" :visible="showNotifications" direction="rtl" :modal="false" :wrapperClosable="false" :before-close="closeNotifcationsDrawer" :destroy-on-close="true">

      <el-tabs class="notificationTabs" v-model="activeTab" @tab-click="tabClick">
        <el-tab-pane label="Active" name="active">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-input :disabled="!this.notificationData.length" v-model="notificationSearch" size="mini" placeholder="Search Notifications">
              </el-input>
            </el-col>
            <el-col :span="12">
              <el-date-picker class="pull-right hiaRangePicker" size="mini" format="MM/dd/yyyy" v-model="dateRange" type="daterange" range-separator="To" start-placeholder="Start date" end-placeholder="End date" @change="handleCriteriaChange" style="z-index: 1; width: 100%;" :picker-options="dateRangePickerConfig">
              </el-date-picker>
            </el-col>
          </el-row>
          <!-- <el-popover class="dismissPopOver" placement="top" width="160" v-model="visible">
        <p>Are you sure to dismiss all?</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="visible = false">cancel</el-button>
          <el-button type="primary" size="mini" @click="notificationDismissAll()">confirm</el-button>
        </div>
        <el-button :disabled="!this.notificationData.length" type="warning" size="mini" slot="reference" class="dismissedButton" plain>Dismiss All</el-button>
      </el-popover> -->

          <!-- <el-button style="position: absolute;z-index: 1;margin: 8px 0px 0px 0px;right: 15px;" type="warning" size="mini" plain class="dismissedButton">
        Dismiss All
      </el-button> -->
          <el-table size="mini" ref="notificationsTable" class="notificationsTable" :data="compNotifications" style="width: 100%" :show-header="true" @row-click="handleRowClick" max-height="600" :row-class-name="notificationRowClassName" @expand-change="notificationRowExpandToggle">
            <div slot="empty">
              <i class="el-icon-chat-line-round"></i> No Notificatons
            </div>
            <el-table-column type="expand" width="15">
              <template slot-scope="props">
                <!-- <el-button type="info" size="mini" plain @click="handleViewFullScreen(props.row)" class="dismissedButton pull-right">
              View Fullscreen
            </el-button> -->
                <div>
                  <i title="view full screen" class="el-icon-top-right pull-right inlinePreview" @click="handleViewFullScreen(props.row)"></i>
                  <!-- <el-button style="padding: 7px !important;" type="primary" plain class="pull-right" title="view full screen" icon="el-icon-top-right" circle @click="handleViewFullScreen(props.row)"></el-button> -->
                </div>
                <div v-if="props.row['body'] && props.row.body.length" class="notificationBody">
                  <span v-html="getBody(props.row.body, props.row)"></span>
                </div>
                <div style="margin: 0px 0px 10px 0px;" v-if="props.row.media.length" width="100%;">
                  <el-carousel :class="props.row.media.length === 1 ? 'single' : ''" height="100%;" indicator-position="outside" :autoplay="false" arrow="always">
                    <el-carousel-item v-for="item in props.row.media" :key="item.id">
                      <el-image @click="handlePreview(props, `inline_preview_${props.$index}`)" v-if="item.type !== 'video'" style="width: 100%;display: inline;" :src="item.url" fit="contain">
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                      <div v-if="item.type === 'video'" style="width: 100%; display: inline;">
                        <iframe :src="item.url" allowfullscreen style="border:none; height: 100%; width: 100%"></iframe>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                  <!-- <el-button type="info" size="mini" plain @click="handlePreview(props)" class="dismissedButton">
              Preivew larger
            </el-button> -->
                  <el-image :id="`inline_preview_${props.$index}`" style="width: 0px; height: 0px;" :src="props.row.media.filter(f => f.type !== 'video').map(m => m.url)[0]" :preview-src-list="props.row.media.filter(f => f.type !== 'video').map(m => m.url)" fit="contain">
                  </el-image>
                </div>
                <div style="margin: 0px 0px 10px 0px;" v-if="props.row.links.length">
                  <el-button v-for="item in props.row.links" :key="item.id" type="info" size="mini" plain @click="item.target === 'internal' ? $router.push(item.url) :  notificationButtonUrl(item)" class="dismissedButton">
                    {{item.title}}
                  </el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Title" prop="title" class="title" sortable>
              <template slot-scope="props">
                <span :style="props.row.acknowledged ? 'font-weight: 400;' : 'font-weight: bolder;' ">{{ getTitle(props.row.title, props.row)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="Date" prop="startDate" width="87" sortable>
              <template slot-scope="props">
                <div>
                  {{ props.row.startDate ? formatDate(props.row.startDate) : '' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="" width="35">
              <template slot-scope="props">
                <i title="dismiss" class="el-icon-close dismissIcon" @click.stop="dismissActivateNotification(props.row, true)"></i>
                <!-- <el-button title="dismiss notification" class="dismissButton" icon="el-icon-close" size="mini" circle @click.stop="dismissNotification(props.row)"></el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Dismissed" name="dismissed">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-input :disabled="!this.notificationData.length" v-model="notificationSearch" size="mini" placeholder="Search Notifications">
              </el-input>
            </el-col>
            <el-col :span="12">
              <el-date-picker class="pull-right hiaRangePicker" size="mini" format="MM/dd/yyyy" v-model="dateRange" type="daterange" range-separator="To" start-placeholder="Start date" end-placeholder="End date" @change="handleCriteriaChange" style="z-index: 1; width: 100%;" :picker-options="dateRangePickerConfig">
              </el-date-picker>
            </el-col>
          </el-row>
          <el-table size="mini" ref="notificationsTableDismissed" class="notificationsTable" :data="compNotificationsDismissed" style="width: 100%" :show-header="true" @row-click="handleDismissedRowClick" max-height="600" :row-class-name="notificationRowClassName" @expand-change="notificationRowExpandToggle">
            <div slot="empty">
              <i class="el-icon-chat-line-round"></i> No Notificatons
            </div>
            <el-table-column type="expand" width="15">
              <template slot-scope="props">
                <!-- <el-button type="info" size="mini" plain @click="handleViewFullScreen(props.row)" class="dismissedButton pull-right">
              View Fullscreen
            </el-button> -->
                <div>
                  <i title="view full screen" class="el-icon-top-right pull-right inlinePreview" @click="handleViewFullScreen(props.row)"></i>
                  <!-- <el-button style="padding: 7px !important;" type="primary" plain class="pull-right" title="view full screen" icon="el-icon-top-right" circle @click="handleViewFullScreen(props.row)"></el-button> -->
                </div>
                <div v-if="props.row['body'] && props.row.body.length" class="notificationBody">
                  <span v-html="getBody(props.row.body, props.row)"></span>
                </div>
                <div style="margin: 0px 0px 10px 0px;" v-if="props.row.media.length" width="100%;">
                  <el-carousel :class="props.row.media.length === 1 ? 'single' : ''" height="100%;" indicator-position="outside" :autoplay="false" arrow="always">
                    <el-carousel-item v-for="item in props.row.media" :key="item.id">
                      <el-image @click="handlePreview(props, `inline_preview_${props.$index}`)" v-if="item.type !== 'video'" style="width: 100%;display: inline;" :src="item.url" fit="contain">
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                      <div v-if="item.type === 'video'" style="width: 100%; display: inline;">
                        <iframe :src="item.url" allowfullscreen style="border:none; height: 100%; width: 100%"></iframe>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                  <!-- <el-button type="info" size="mini" plain @click="handlePreview(props)" class="dismissedButton">
              Preivew larger
            </el-button> -->
                  <el-image :id="`inline_preview_${props.$index}`" style="width: 0px; height: 0px;" :src="props.row.media.filter(f => f.type !== 'video').map(m => m.url)[0]" :preview-src-list="props.row.media.filter(f => f.type !== 'video').map(m => m.url)" fit="contain">
                  </el-image>
                </div>
                <div style="margin: 0px 0px 10px 0px;" v-if="props.row.links.length">
                  <el-button v-for="item in props.row.links" :key="item.id" type="info" size="mini" plain @click="item.target === 'internal' ? $router.push(item.url) :  notificationButtonUrl(item)" class="dismissedButton">
                    {{item.title}}
                  </el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Title" prop="title" class="title" sortable>
              <template slot-scope="props">
                <span :style="props.row.acknowledged ? 'font-weight: 400;' : 'font-weight: bolder;' ">{{ getTitle(props.row.title, props.row)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="Date" prop="startDate" width="87" sortable>
              <template slot-scope="props">
                <div>
                  {{ props.row.startDate ? formatDate(props.row.startDate) : '' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="" width="35">
              <template slot-scope="props">
                <i title="mark as active" class="el-icon-refresh-right activeIcon" @click.stop="dismissActivateNotification(props.row, false)"></i>
                <!-- <el-button title="dismiss notification" class="dismissButton" icon="el-icon-close" size="mini" circle @click.stop="dismissNotification(props.row)"></el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

    </el-drawer>
    <!-- end drawer  -->
    <!-- preview item -->
    <el-dialog :visible.sync="videoDialogVisible" :show-close="true" :close-on-click-modal="false">
      <span style="font-size: 20px;color: #72767b;" slot="title">
        {{getTitle(this.currentItem.title, this.currentItem)}}
      </span>
      <div style="margin: 0px 0px 10px 0px;" v-if="this.currentItem.body.length" class="notificationBody">
        <span v-html="getBody(this.currentItem.body, this.currentItem)"></span>
      </div>
      <div style="margin: 0px 0px 0px 0px;" v-if="this.currentItem.media.length" width="100%;">
        <el-carousel :class="this.currentItem.media.filter(f => f.type !== 'banner').length === 1 ? 'single' : ''" height="410px" indicator-position="outside" :autoplay="false" arrow="always">
          <el-carousel-item v-for="item in this.currentItem.media.filter(f => f.type !== 'banner')" :key="item.id">
            <el-image @click="handlePreview(currentItem, `modal_preview_${currentItem.id}`)" v-if="item.type !== 'video'" style="width: 100%;display: inline;" :src="item.url" fit="contain">
            </el-image>
            <div v-if="item.type === 'video'" style="width: 100%; display: inline;">
              <iframe v-if="videoDialogVisible" :src="item.url" allowfullscreen style="border:none; height: 100%; width: 100%"></iframe>
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-image :id="`modal_preview_${this.currentItem.id}`" style="width: 0px; height: 0px;" :src="this.currentItem.media.filter(f => f.type !== 'video' && f.type !== 'banner').map(m => m.url)[0]" :preview-src-list="this.currentItem.media.filter(f => f.type !== 'video' && f.type !== 'banner').map(m => m.url)" fit="contain">
        </el-image>
      </div>
      <div style="margin: 0px 0px 10px 0px;" v-if="this.currentItem.links.length">
        <el-button v-for="item in this.currentItem.links" :key="item.id" type="info" size="mini" plain @click="item.target === 'internal' ? $router.push(item.url) :  notificationButtonUrl(item)" class="dismissedButton">
          {{item.title}}
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="hiaButton" @click="videoDialogVisible = false" plain round>Close</el-button>
      </span>
    </el-dialog>
    <!-- end preview item -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import hiaFilters from '@/mixins/hiaFilters'
// import dateHelper from '@/mixins/date-helper'
import { format, parseISO } from 'date-fns'

export default {
  name: 'notificationsView',
  props: [],
  mixins: [hiaFilters],
  data() {
    return {
      activeTab: 'active',
      dateRange: [],
      currentItem: {
        id: 0,
        startDate: '',
        title: '',
        body: '',
        media: [],
        links: [],
        dismissed: false,
        acknowledged: false,
        isBanner: false,
        message: null
      },
      videoDialogVisible: false,
      expandedRows: [],
      notificationSearch: '',
      visible: false,
      notificationDismissedData: []
    }
  },
  created() {
    const now = new Date()
    this.dateRange[0] = format(new Date(now.getFullYear(), 0, 1), 'MM-dd-yyyy')
    this.dateRange[1] = format(new Date(now.getFullYear(), now.getMonth() + 1, 0), 'MM-dd-yyyy')
  },
  methods: {
    ...mapActions('notifications/', ['PUT_ACKNOWLEDGE_NOTIFICATION', 'PUT_DISMISSACTIVATE_NOTIFICATION', 'GET_USER_NOTIFICATIONS']),
    ...mapMutations('notifications/', ['SET_SHOWNOTIFICATIONS']),
    handleRowClick(row, column, event) {
      this.$refs.notificationsTable.toggleRowExpansion(row)
    },
    handleDismissedRowClick(row, column, event) {
      this.$refs.notificationsTableDismissed.toggleRowExpansion(row)
    },
    handlePreview(props, id) {
      const element = document.getElementById(id)
      if (element) {
        element.click()
      }
    },
    getNotificationValues() {
      return 1
    },
    notificationRowClassName({ row, rowIndex }) {
      return row.acknowledged ? 'acknowledged' : ''
    },
    notificationRowExpandToggle(row, expandedRows) {
      this.expandedRows = expandedRows
      if (!row.acknowledged) {
        const payload = {
          row: row,
          acknowledged: true
        }
        this.PUT_ACKNOWLEDGE_NOTIFICATION(payload)
      }
    },
    notificationDismiss(props) {
      if (!props.row.dismissed) {
        props.row.dismissed = true
        // remove and add to notification dismissed table
        const index = this.notificationData.findIndex(x => x.assigneeId === props.row.assigneeId)
        this.notificationData.splice(index, 1)
        this.notificationDismissedData.push(props.row)
      } else {
        props.row.dismissed = false
        // remove and add to notification table
        const index = this.notificationDismissedData.findIndex(x => x.assigneeId === props.row.assigneeId)
        this.notificationDismissedData.splice(index, 1)
        this.notificationData.push(props.row)
      }
    },
    notificationDismissAll() {
      this.visible = false
      const dismissedNotifications = this.notificationData.map(m => {
        m.dismissed = true
        return m
      })
      this.notificationData = []
      this.notificationDismissedData = this.notificationDismissedData.concat(dismissedNotifications)
    },
    notificationButtonUrl(item) {
      const url = !item.url.match(/^https?:\/\//i) ? '//' + item.url : item.url
      window.open(url, '_blank')
    },
    closeNotifcationsDrawer(done) {
      this.SET_SHOWNOTIFICATIONS(false)
      done()
    },
    formatDate(date) {
      return format(parseISO(date), 'MM/dd/yyyy')
    },
    handleViewFullScreen(item) {
      this.videoDialogVisible = true
      this.currentItem = item
    },
    getTitle(title, row) {
      let newTitle = title
      if (row.message) {
        const json = JSON.parse(row.message)
        if (json) {
          newTitle = json.title
        }
      }
      return newTitle
    },
    getBody(body, row) {
      let newBody = body
      if (row.message) {
        const json = JSON.parse(row.message)
        if (json) {
          newBody = json.body
        }
      }
      return newBody
    },
    dismissActivateNotification(row, condition) {
      const payload = {
        row: row,
        condition: condition
      }
      this.PUT_DISMISSACTIVATE_NOTIFICATION(payload)
    },
    handleCriteriaChange() {
      const params = {
        startDate: format(this.dateRange[0], 'MM-dd-yyyy'),
        endDate: format(this.dateRange[1], 'MM-dd-yyyy'),
        userId: this.currentTenantUserId
      }

      // this.loading = true
      this.GET_USER_NOTIFICATIONS(params).then(() => {
        // this.loading = false
      })
    },
    tabClick(tab, event) {
      this.notificationSearch = ''
    }
  },
  computed: {
    ...mapState('user/', ['currentTenantUserId']),
    ...mapState('globals/', ['dateRangePickerConfig']),
    ...mapState('notifications/', ['showNotifications', 'notificationData']),
    compNotifications: function () {
      return this.filterList(this.notificationData.filter(f => f.deleted === false), this.notificationSearch, [], true)
    },
    compNotificationsDismissed: function () {
      return this.filterList(this.notificationData.filter(f => f.deleted === true), this.notificationSearch, [], true)
    }
  }
}

</script>

<style scoped>
/* hia notifications drawer */
.noficationsDrawer {
  margin-top: 60px;
}

.noficationsDrawer >>> #el-drawer__title {
  font-size: 20px;
}

.notificationsTable {
  margin: 5px 0px 0px 0px;
}

/* hia notifications table */
>>> .notificationsTable
  > div
  > table
  > tbody
  > tr
  > td.el-table__expand-column
  > .cell {
  /* display: none; */
}

>>> .notificationsTable
  > div
  > table
  > tbody
  > tr
  > td.el-table__expanded-cell {
  padding: 20px 20px !important;
}

>>> .dismissedButton {
  padding: 2px 10px;
  border-radius: 30px;
}

>>> .notificationBody {
  margin: 0px 0px 15px 0px;
  word-break: normal;
}

>>> .notificationsTable .el-table__expand-icon > .el-icon {
  font-weight: bolder;
  color: #7cbe5f;
}

>>> .notificationsTable .acknowledged .el-table__expand-icon > .el-icon {
  font-weight: bolder;
  color: #ffc72e;
}

>>> .notificationsTable .caret-wrapper {
  display: none;
}

>>> .dismissPopOver {
  position: absolute;
  z-index: 1;
  margin: 6px 0px 0px 0px;
  right: 15px;
}

>>> .dismissedRow,
>>> .acknowledged {
  background-color: #f5f7fa !important;
}

/* lightbox styles */
>>> .el-image > .el-image-viewer__wrapper > .el-image-viewer__btn {
  color: #ffffff;
  opacity: 1;
}

>>> .el-carousel__arrow {
  background-color: rgba(31, 45, 61, 0.61);
}

>>> .el-carousel__indicators--outside button {
  background-color: rgba(31, 45, 61, 0.61);
}

/* remove chrome blue focus bar */
>>> header:focus,
>>> header > span,
>>> .el-drawer__container > div,
>>> header > button > i {
  outline: 0 !important;
}

.notificationsTable .single >>> .el-carousel__indicators,
.notificationsTable .single >>> .el-carousel__arrow {
  display: none;
}

.inlinePreview {
  font-size: 19px;
  color: #57ccf4;
  cursor: pointer;
  margin: -10px 0px 0px 0px;
  padding: 3px 4px 3px 3px;
}

.inlinePreview:hover {
  font-size: 19px;
  color: #005695;
  cursor: pointer;
  margin: -10px 0px 0px 0px;
  background: rgba(87, 204, 244, 0.1);
  padding: 3px 4px 3px 3px;
  border-radius: 50%;
}

>>> .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}

.dismissIcon {
  cursor: pointer;
  color: rgb(245, 108, 108);
  font-size: 13px;
}

.activeIcon {
  cursor: pointer;
  color: #67c23a;
  font-size: 13px;
}

.notificationTabs >>> .el-tabs__nav-wrap::after {
  height: 0px;
}

.notificationTabs >>> .el-tabs__header,
.notificationTabs >>> .el-tab-pane {
  padding: 10px 10px 0;
}
</style>
