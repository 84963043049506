import axios from 'axios'
import router from '../router'

const globalAxios = axios.create()

globalAxios.interceptors.request.use(config => {
  config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('access_token')
  config.headers['Cache-Control'] = 'no-cache'
  config.headers.Pragma = 'no-cache'
  return config
})

globalAxios.interceptors.response.use(null, error => {
  if (error && error.response && error.response.status === 403) {
    router.push('/not-authorized')
  }
  return Promise.reject(error)
})

export default globalAxios
