import { updateField } from 'vuex-map-fields'
import Vue from 'vue'

const SET_INPATIENT_REVIEW = (state, payload) => {
  state.inpatientReview = payload
}

const SET_AUDIT_RESULT = (state, auditResult) => {
  state.inpatientReview.auditResult = auditResult
}

const SET_TRUCODE_SUBSET = (state, payload) => {
  if (state.inpatientReview.drgRecommendationsDx.length === payload.drgRecommendationsDx.length) {
    // loop over codes and update Trucode only
    for (const i in payload.drgRecommendationsDx) {
      const dxIndex = state.inpatientReview.drgRecommendationsDx.findIndex(f => f.dxId === payload.drgRecommendationsDx[i].dxId)
      if (dxIndex !== -1) {
        state.inpatientReview.drgRecommendationsDx[dxIndex].lineSequence = payload.drgRecommendationsDx[i].lineSequence
        state.inpatientReview.drgRecommendationsDx[dxIndex].grouperFlags = payload.drgRecommendationsDx[i].grouperFlags

        state.inpatientReview.drgRecommendationsDx[dxIndex].originalDescCalc = payload.drgRecommendationsDx[i].originalDescCalc
        state.inpatientReview.drgRecommendationsDx[dxIndex].edits = payload.drgRecommendationsDx[i].edits
        state.inpatientReview.drgRecommendationsDx[dxIndex].originalHccs = payload.drgRecommendationsDx[i].originalHccs
        state.inpatientReview.drgRecommendationsDx[dxIndex].hac = payload.drgRecommendationsDx[i].hac
        state.inpatientReview.drgRecommendationsDx[dxIndex].originalPoa = payload.drgRecommendationsDx[i].originalPoa

        state.inpatientReview.drgRecommendationsDx[dxIndex].recommendedDx = payload.drgRecommendationsDx[i].recommendedDx
        state.inpatientReview.drgRecommendationsDx[dxIndex].newPrimary = payload.drgRecommendationsDx[i].newPrimary
        state.inpatientReview.drgRecommendationsDx[dxIndex].recDescCalc = payload.drgRecommendationsDx[i].recDescCalc
        state.inpatientReview.drgRecommendationsDx[dxIndex].recEdits = payload.drgRecommendationsDx[i].recEdits
        state.inpatientReview.drgRecommendationsDx[dxIndex].recommendedHccs = payload.drgRecommendationsDx[i].recommendedHccs
        state.inpatientReview.drgRecommendationsDx[dxIndex].rechac = payload.drgRecommendationsDx[i].rechac
        state.inpatientReview.drgRecommendationsDx[dxIndex].recommendedPoa = payload.drgRecommendationsDx[i].recommendedPoa

        state.inpatientReview.drgRecommendationsDx[dxIndex].level2Dx = payload.drgRecommendationsDx[i].level2Dx
        state.inpatientReview.drgRecommendationsDx[dxIndex].level2Edits = payload.drgRecommendationsDx[i].level2Edits
        state.inpatientReview.drgRecommendationsDx[dxIndex].level2NewPrimary = payload.drgRecommendationsDx[i].level2NewPrimary
        state.inpatientReview.drgRecommendationsDx[dxIndex].level2Hccs = payload.drgRecommendationsDx[i].level2Hccs
        state.inpatientReview.drgRecommendationsDx[dxIndex].level2DescCalc = payload.drgRecommendationsDx[i].level2DescCalc
        state.inpatientReview.drgRecommendationsDx[dxIndex].level2Poa = payload.drgRecommendationsDx[i].level2Poa

        state.inpatientReview.drgRecommendationsDx[dxIndex].hasRecommendation = payload.drgRecommendationsDx[i].hasRecommendation
      }
    }
  } else {
    state.inpatientReview.drgRecommendationsDx = payload.drgRecommendationsDx
  }

  if (state.inpatientReview.drgRecommendationsPx.length === payload.drgRecommendationsPx.length) {
    for (const j in payload.drgRecommendationsPx) {
      const pxIndex = state.inpatientReview.drgRecommendationsPx.findIndex(f => f.pxId === payload.drgRecommendationsPx[j].pxId)
      if (pxIndex !== -1) {
        state.inpatientReview.drgRecommendationsPx[pxIndex].lineSequence = payload.drgRecommendationsPx[j].lineSequence
        state.inpatientReview.drgRecommendationsPx[pxIndex].grouperFlags = payload.drgRecommendationsPx[j].grouperFlags

        state.inpatientReview.drgRecommendationsPx[pxIndex].originalDescCalc = payload.drgRecommendationsPx[j].originalDescCalc
        state.inpatientReview.drgRecommendationsPx[pxIndex].edits = payload.drgRecommendationsPx[j].edits

        state.inpatientReview.drgRecommendationsPx[pxIndex].recommendedPx = payload.drgRecommendationsPx[j].recommendedPx
        state.inpatientReview.drgRecommendationsPx[pxIndex].recDescCalc = payload.drgRecommendationsPx[j].recDescCalc
        state.inpatientReview.drgRecommendationsPx[pxIndex].recEdits = payload.drgRecommendationsPx[j].recEdits

        state.inpatientReview.drgRecommendationsPx[pxIndex].level2Px = payload.drgRecommendationsPx[j].level2Px
        state.inpatientReview.drgRecommendationsPx[pxIndex].level2DescCalc = payload.drgRecommendationsPx[j].level2DescCalc
        state.inpatientReview.drgRecommendationsPx[pxIndex].level2Edits = payload.drgRecommendationsPx[j].level2Edits

        state.inpatientReview.drgRecommendationsPx[pxIndex].hasRecommendation = payload.drgRecommendationsPx[j].hasRecommendation
      }
    }
  } else {
    state.inpatientReview.drgRecommendationsPx = payload.drgRecommendationsPx
  }

  // Update Weight coming back
  state.inpatientReview.originalWeight = payload.originalWeight
  state.inpatientReview.newWeight = payload.newWeight
  state.inpatientReview.l2drgweight = payload.l2drgweight

  // Research pane related
  state.inpatientReview.drgAnalysis_Or = payload.drgAnalysis_Or
  state.inpatientReview.drgAnalysis_Rec = payload.drgAnalysis_Rec
  state.inpatientReview.drgAnalysis_Lvl2 = payload.drgAnalysis_Lvl2
  state.inpatientReview.truCodeTimeStamp = payload.truCodeTimeStamp

  // Handle action plan recommendations adds/deletes
  state.inpatientReview.actionPlansRecommendations = payload.actionPlansRecommendations

  state.inpatientReview.provider = payload.provider
  state.inpatientReview.clientCoder = payload.clientCoder
  state.inpatientReview.clientCDIStaff = payload.clientCDIStaff
  state.inpatientReview.serviceLine = payload.serviceLine
  state.inpatientReview.groupingResults = payload.groupingResults
  state.inpatientReview.pricingResults = payload.pricingResults
  state.inpatientReview.truCodeResponses = payload.truCodeResponses
  state.inpatientReview.psiResults = payload.psiResults
  state.inpatientReview.qualityMeasureResults = payload.qualityMeasureResults
  state.inpatientReview.drgRecommendationsConditionCodes = payload.drgRecommendationsConditionCodes
  state.inpatientReview.payorDetail = payload.payorDetail
  state.inpatientReview.recordValidations = payload.recordValidations
  state.inpatientReview.auditResult = payload.auditResult
  state.inpatientReview.identifierSelectionsArray = payload.identifierSelectionsArray
  state.inpatientReview.identifierSelections = payload.identifierSelections
}

const SET_INPATIENT_DXS = (state, row) => {
  state.inpatientReview.drgRecommendationsDx = row
}

const SET_INPATIENT_DX_ROW = (state, row) => {
  const index = state.inpatientReview.drgRecommendationsDx.findIndex(x => x.dxId === row.dxId)
  Vue.set(state.inpatientReview.drgRecommendationsDx, index, row)
}

const DELETE_DX_ROW = (state, row) => {
  const index = state.inpatientReview.drgRecommendationsDx.findIndex(x => x.dxId === row.dxId)
  state.inpatientReview.drgRecommendationsDx.splice(index, 1)
}

const PUSH_DX_ROW = (state, row) => {
  state.inpatientReview.drgRecommendationsDx.push(row)
}

const SET_INPATIENT_PXS = (state, row) => {
  state.inpatientReview.drgRecommendationsPx = row
}

const SET_INPATIENT_PX_ROW = (state, row) => {
  const index = state.inpatientReview.drgRecommendationsPx.findIndex(x => x.pxId === row.pxId)
  Vue.set(state.inpatientReview.drgRecommendationsPx, index, row)
}

const DELETE_PX_ROW = (state, row) => {
  const index = state.inpatientReview.drgRecommendationsPx.findIndex(x => x.pxId === row.pxId)
  state.inpatientReview.drgRecommendationsPx.splice(index, 1)
}

const PUSH_PX_ROW = (state, row) => {
  state.inpatientReview.drgRecommendationsPx.push(row)
}

const SET_STATISTICS = (state, payload) => {
  state.statistics = payload
}

const SET_PROJECT_STATISTICS = (state, payload) => {
  state.ipProjectStatistics = payload
}

const SET_DX_LOADING = (state, payload) => {
  state.dxLoading = payload
}

const SET_PX_LOADING = (state, payload) => {
  state.pxLoading = payload
}

const SET_IPLIST_SELECTED = (state, payload) => {
  if (payload.checked) {
    state.ipListSelected.push(payload)
  } else {
    state.ipListSelected = state.ipListSelected.filter(x => x.drgTableId !== payload.drgTableId)
  }
}

const SET_IPLIST_ALLIDS = (state, payload) => {
  state.ipListSelected = payload
}

const CLEAR_IPLIST_SELECTED = (state, payload) => {
  state.ipListSelected = []
}

const SET_IPLIST_REFRESH_FUNC = (state, func) => {
  state.refreshIpList = func
}

export default {
  updateField,
  SET_IPLIST_REFRESH_FUNC,
  SET_INPATIENT_REVIEW,
  SET_INPATIENT_DX_ROW,
  SET_AUDIT_RESULT,
  DELETE_DX_ROW,
  PUSH_DX_ROW,
  SET_DX_LOADING,
  SET_PX_LOADING,
  SET_TRUCODE_SUBSET,
  SET_INPATIENT_DXS,
  SET_INPATIENT_PXS,
  SET_INPATIENT_PX_ROW,
  DELETE_PX_ROW,
  PUSH_PX_ROW,
  SET_STATISTICS,
  SET_IPLIST_ALLIDS,
  SET_IPLIST_SELECTED,
  CLEAR_IPLIST_SELECTED,
  SET_PROJECT_STATISTICS
}
