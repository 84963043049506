<template>
  <div>
    <h1 class="page-header">Page Not Found</h1>
    The requested page was not found. Please check the URL and/or return
    <router-link to="/">home</router-link>.
  </div>
</template>

<script>
  export default {
    name: 'NotFound'
  }
</script>
