import axios from '@/utilities/axios-global'

const GET_CLIENT_CHECKLIST_ITEMS = async (context) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/client-checklist-item`)
    context.commit('SET_CLIENT_CHECKLIST_ITEMS', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_CLIENT_CHECKLIST_ITEM = async (context, payload) => {
  try {
    const url = `${context.rootState.user.baseApiUrl}/client-checklist-item`
    const response = await axios.post(url, payload.checklistItem)
    context.commit('PUSH_CLIENT_CHECKLIST_ITEM', response.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_CLIENT_CHECKLIST_ITEM = async (context, checklistItem) => {
  try {
    const url = `${context.rootState.user.baseApiUrl}/client-checklist-item/${checklistItem.itemId}`
    const response = await axios.put(url, checklistItem)
    context.commit('UPDATE_CLIENT_CHECKLIST_ITEM', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const DELETE_CLIENT_CHECKLIST_ITEM = async (context, checklistItem) => {
  try {
    const url = `${context.rootState.user.baseApiUrl}/client-checklist-item/${checklistItem.itemId}`
    await axios.delete(url)
    context.commit('SPLICE_CLIENT_CHECKLIST_ITEM', checklistItem)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_CLIENT_CHECKLIST_ITEMS,
  DELETE_CLIENT_CHECKLIST_ITEM,
  PUT_CLIENT_CHECKLIST_ITEM,
  POST_CLIENT_CHECKLIST_ITEM
}
