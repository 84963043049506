import axios from '@/utilities/axios-global'

const GET_USERPROFILE = async (context, params) => {
  try {
    context.commit('SET_USERPROFILELOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/userprofile/${params.userId}`)
    context.commit('SET_USERPROFILE', response.data)
    context.commit('SET_USERPROFILELOADING', false)
  } catch (err) {
    context.commit('SET_USERPROFILELOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_USERPROFILE = async (context, payload) => {
  try {
    context.commit('SET_USERPROFILELOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/userprofile/${payload.userProfileId}`, payload)
    context.commit('SET_USERPROFILE', response.data)
    context.commit('SET_USERPROFILELOADING', false)
  } catch (err) {
    context.commit('SET_USERPROFILELOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_USERPROFILE = async (context, newProfile) => {
  try {
    context.commit('SET_USERPROFILELOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/userprofile`, newProfile)
    context.commit('SET_USERPROFILE', response.data)
    context.commit('SET_USERPROFILELOADING', false)
  } catch (err) {
    context.commit('SET_USERPROFILELOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_USERPROFILE,
  PUT_USERPROFILE,
  POST_USERPROFILE
}
