import { updateField } from 'vuex-map-fields'
import Vue from 'vue'

const SET_REBUTTAL = (state, payload) => {
  state.rebuttal = payload || {}
}

const SET_COMMENT = (state, payload) => {
  const index = state.rebuttal.rebuttalComments.findIndex(x => x.commentId === payload.commentId)
  Vue.set(state.rebuttal.rebuttalComments, index, payload)
}

const ADD_COMMENT = (state, comment) => {
  if (state.rebuttal && state.rebuttal.rebuttalComments) {
    state.rebuttal.rebuttalComments.push(comment)
  }
}

export default {
  SET_REBUTTAL,
  ADD_COMMENT,
  SET_COMMENT,
  updateField
}
