import { updateField } from 'vuex-map-fields'

const SET_CLIENT_CHECKLIST_ITEMS = (state, payload) => {
  state.clientChecklistItems = payload
}

const PUSH_CLIENT_CHECKLIST_ITEM = (state, payload) => {
  state.clientChecklistItems.push(payload)
}

const SPLICE_CLIENT_CHECKLIST_ITEM = (state, payload) => {
  const index = state.clientChecklistItems.findIndex(x => x.itemId === payload.itemId)
  state.clientChecklistItems.splice(index, 1)
}

const UPDATE_CLIENT_CHECKLIST_ITEM = (state, payload) => {
  const index = state.clientChecklistItems.findIndex(x => x.itemId === payload.itemId)
  state.clientChecklistItems.splice(index, 1, payload)
}

export default {
  updateField,
  SET_CLIENT_CHECKLIST_ITEMS,
  PUSH_CLIENT_CHECKLIST_ITEM,
  SPLICE_CLIENT_CHECKLIST_ITEM,
  UPDATE_CLIENT_CHECKLIST_ITEM
}
