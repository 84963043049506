import axios from '@/utilities/axios-global'

const GET_CDISTAFF_LIST = async (context, clientNumber) => {
  try {
    context.commit('SET_CDISTAFF_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/cdiStaff`)
    context.commit('SET_CDISTAFF', response.data)
    context.commit('SET_CDISTAFF_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CDISTAFF_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_CDISTAFF = async (context, payload) => {
  try {
    context.commit('SET_CDISTAFF_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/cdiStaff`
    const response = await axios.post(url, payload.cdiStaff)
    context.commit('PUSH_CDISTAFF', response.data)
    context.commit('SET_CDISTAFF_LOADING', false)
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_CDISTAFF_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_MERGE_CDISTAFF = (context, payload) => {
  return new Promise((resolve, reject) => {
    context.commit('SET_CDISTAFF_LOADING', true)
    const url = context.rootState.user.baseApiUrl + `/cdiStaff/merge?targetCdiStaffId=${payload.targetCdiStaffId}`
    axios.put(url, payload.cdiStaff).then(response => {
      context.commit('SET_CDISTAFF_LOADING', false)
      payload.cdiStaff.forEach(x => {
        context.commit('SPLICE_CDISTAFF', x)
      })
      resolve()
    }, error => {
      context.commit('SET_CDISTAFF_LOADING', false)
      console.log(error)
      reject(error)
    })
  })
}

const PUT_CDISTAFF = async (context, cdiStaff) => {
  try {
    context.commit('SET_CDISTAFF_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/cdiStaff/${cdiStaff.cdiStaffId}`
    const response = await axios.put(url, cdiStaff)
    context.commit('SET_CDISTAFF_LOADING', false)
    context.commit('UPDATE_CDISTAFF', response.data)
  } catch (err) {
    console.log(err)
    context.commit('SET_CDISTAFF_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_CDISTAFF = async (context, cdiStaff) => {
  try {
    context.commit('SET_CDISTAFF_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/cdiStaff/${cdiStaff.cdiStaffId}`
    await axios.delete(url)
    context.commit('SPLICE_CDISTAFF', cdiStaff)
    context.commit('SET_CDISTAFF_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CDISTAFF_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const LOAD_CDISTAFF_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/cdiStaff/paginated?`, payload.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_CDISTAFF_LIST,
  DELETE_CDISTAFF,
  PUT_CDISTAFF,
  POST_CDISTAFF,
  PUT_MERGE_CDISTAFF,
  LOAD_CDISTAFF_PAGINATED
}
