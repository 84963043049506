import { updateField } from 'vuex-map-fields'

const SET_FINDINGS = (state, payload) => {
  state.auditFindings = payload
  if (state.auditFindings.length > 0) {
    state.activeFinding = state.auditFindings[0]
  } else {
    state.activeFinding = {}
  }
}

const SET_ACTIVE_FINDING = (state, payload) => {
  state.activeFinding = payload
}

const PUSH_FINDING = (state, payload) => {
  state.auditFindings.push(payload)
}

export default {
  SET_FINDINGS,
  SET_ACTIVE_FINDING,
  PUSH_FINDING,
  updateField
}
