import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  user: {
    email: '',
    accessToken: '',
    name: '',
    hasAppAccess: false,
    isTrucodeEnabled: false,
    userTenants: []
  },
  currentTenantUserId: null,
  currentTenantGuid: null,
  currentTenantName: '',
  baseApiUrl: ''
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
