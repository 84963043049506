import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
  recommendationLibrary: [],
  recommendationLibraryPrintable: [],
  showRecommendationsPicker: false,
  currentRecommendationType: '',
  currentRecordType: '',
  recommendationRecord: {}
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
