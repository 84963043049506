import { updateField } from 'vuex-map-fields'

const SET_USERS = (state, payload) => {
  state.users = payload
}

const SET_USER = (state, payload) => {
  state.loadedUser = payload
}

const SET_USER_EMAIL = (state, payload) => {
  state.loadedUser.email = payload
}

const SET_PW_RESET_TICKET = (state, payload) => {
  state.loadedUser.pwResetTicket = payload
}

const SET_ROLES = (state, payload) => {
  state.roles = payload
}

const SET_LICENSE = (state, payload) => {
  state.license = payload
}
const PUSH_USER_ROLE = (state, payload) => {
  state.loadedUser.usersRoles.push(payload)
}

const UPDATE_USER_ROLE = (state, payload) => {
  const index = state.loadedUser.usersRoles.findIndex(x => x.roleId === payload.roleId)
  state.loadedUser.usersRoles.splice(index, 1, payload)
}

const SPLICE_USER_ROLE = (state, id) => {
  const index = state.loadedUser.usersRoles.findIndex(x => x.id === id)
  state.loadedUser.usersRoles.splice(index, 1)
}

const SPLICE_NEW_USER_ROLE = (state, index) => {
  state.loadedUser.usersRoles.splice(index, 1)
}

export default {
  updateField,
  SET_USERS,
  SET_USER,
  SET_PW_RESET_TICKET,
  SET_ROLES,
  PUSH_USER_ROLE,
  SPLICE_USER_ROLE,
  SPLICE_NEW_USER_ROLE,
  UPDATE_USER_ROLE,
  SET_USER_EMAIL,
  SET_LICENSE
}
