import axios from '@/utilities/axios-global'

const GET_CODE_TYPES = async (context) => {
  try {
    context.commit('SET_CODE_TYPES_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/codetypes`)
    context.commit('SET_CODE_TYPES', response.data)
    context.commit('SET_CODE_TYPES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CODE_TYPES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_CODE_TYPES = async (context, payload) => {
  try {
    context.commit('SET_CODE_TYPES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/codetypes/${payload.codeNumber}`
    const response = await axios.put(url, payload)
    context.commit('UPDATE_CODE_TYPES', response.data)
    context.commit('SET_CODE_TYPES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CODE_TYPES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_CODE_TYPES = async (context, payload) => {
  try {
    context.commit('SET_CODE_TYPES_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/codetypes`, payload.codeType)
    context.commit('PUSH_CODE_TYPES', response.data)
    context.commit('SET_CODE_TYPES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CODE_TYPES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_CODE_TYPES = async (context, codeNumber) => {
  try {
    context.commit('SET_CODE_TYPES_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/codetypes/${codeNumber}`)
    context.commit('SPLICE_CODE_TYPES', codeNumber)
    context.commit('SET_CODE_TYPES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CODE_TYPES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_CODE_TYPES,
  PUT_CODE_TYPES,
  POST_CODE_TYPES,
  DELETE_CODE_TYPES
}
