import { updateField } from 'vuex-map-fields'
import Vue from 'vue'

const SET_SHOW_ADD_PROJECT_FORM = (state, payload) => {
  state.showAddProjectForm = payload
}

const SET_NEW_PROJECT_ID = (state, payload) => {
  state.newProjectID = payload
}

const SET_PROJECT_LIST_LOADING = (state, payload) => {
  state.projectLoading = payload
}

const SET_PROJECT_LIST = (state, payload) => {
  state.projectList = payload
}

const SET_PROJECT_LIST_BACKUP = (state, payload) => {
  state.projectListBackup = payload
}

const SET_CALENDAR_PROJECT_LIST = (state, payload) => {
  state.calendarProjectList = payload
}

const SET_PROJECTPK = (state, payload) => {
  state.projectPK = payload
}

const SET_PROJECT = (state, payload) => {
  state.project = payload
}

const SET_PROJECT_LOADING = (state, payload) => {
  state.projectLoading = payload
}

const SET_PROJECT_DRGTABLEIDS = (state, payload) => {
  state.drgTableIds = payload
}

const SET_PROJECT_PRORECORDIDS = (state, payload) => {
  state.proRecordIds = payload
}

const SET_PROJECT_ASCTABLEIDS = (state, payload) => {
  state.ascTableIds = payload
}

const SET_PROJECT_TASK_ROW = (state, row) => {
  const index = state.project.projectTasks.findIndex(x => x.projectsTaskId === row.projectsTaskId)
  Vue.set(state.project.projectTasks, index, row)
}

export default {
  updateField,
  SET_NEW_PROJECT_ID,
  SET_SHOW_ADD_PROJECT_FORM,
  SET_PROJECT_LIST_LOADING,
  SET_PROJECT_LIST,
  SET_CALENDAR_PROJECT_LIST,
  SET_PROJECTPK,
  SET_PROJECT,
  SET_PROJECT_LOADING,
  SET_PROJECT_DRGTABLEIDS,
  SET_PROJECT_PRORECORDIDS,
  SET_PROJECT_ASCTABLEIDS,
  SET_PROJECT_TASK_ROW,
  SET_PROJECT_LIST_BACKUP
}
