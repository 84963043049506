import actions from './actions.js'
import mutations from './mutations'
import getters from './getters.js'

const state = {
  showNotifications: false,
  adminNotificationList: [],
  adminNotification: [],
  notificationData: [],
  notificationsLoading: false
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
