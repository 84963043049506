import { updateField } from 'vuex-map-fields'

const SET_CLIENT_CHECKLIST = (state, payload) => {
  state.clientChecklists = payload
}

const PUSH_CLIENT_CHECKLIST = (state, payload) => {
  state.clientChecklists.push(payload)
}

const SPLICE_CLIENT_CHECKLIST = (state, payload) => {
  const index = state.clientChecklists.findIndex(x => x.checklistId === payload.checklistId)
  state.clientChecklists.splice(index, 1)
}

const UPDATE_CLIENT_CHECKLIST = (state, payload) => {
  const index = state.clientChecklists.findIndex(x => x.checklistId === payload.checklistId)
  state.clientChecklists.splice(index, 1, payload)
}

export default {
  updateField,
  SET_CLIENT_CHECKLIST,
  PUSH_CLIENT_CHECKLIST,
  SPLICE_CLIENT_CHECKLIST,
  UPDATE_CLIENT_CHECKLIST
}
