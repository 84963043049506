import actions from './actions.js'
import mutations from './mutations'
import getters from './getters.js'

const state = {
  blogFeed: [],
  clientNavSelection: ''
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
