const SET_PULLLIST_BUILDS = (state, payload) => {
  state.pullListBuilds = payload
}

const SET_PULLLIST_BUILD = (state, payload) => {
  state.pullListBuild = payload
}

const SET_TARGET_TEMPLATES = (state, payload) => {
  state.targetTemplates = payload
}

export default {
  SET_PULLLIST_BUILDS,
  SET_PULLLIST_BUILD,
  SET_TARGET_TEMPLATES
}
