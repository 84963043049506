/* eslint-disable */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AuthService from './auth/AuthService'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import '../public/static/css/helixTheme/index.css'
import { ClientTable, ServerTable } from 'vue-tables-2'
import HiaElSelect from './components/controls/HiaElSelect'
import VueLocalStorage from 'vue-ls'
import VueMask from 'v-mask'
import HiaAuthorization from './mixins/hiaAuthorization'
import draggable from 'vuedraggable'
import HiaVueTable2 from './components/controls/HiaVueTable2'
import PortalVue from 'portal-vue'

const auth = new AuthService()

Vue.config.productionTip = false
Vue.mixin(HiaAuthorization)
Vue.use(Element, { locale: locale, size: 'mini' })
Vue.use(VueMask)
Vue.use(PortalVue)
Vue.component('draggable', draggable)
Vue.use(ClientTable, {
  texts: {
    defaultOption: 'Select All',
    filter: '',
    filterBy: '...'
  },
  filterByColumn: true,
  perPage: 20,
  highlightMatches: true,
  sortIcon: { is: 'none' },

}, false, 'bootstrap3', { dataTable: HiaVueTable2 })
Vue.use(ServerTable, {
  texts: {
    defaultOption: 'Select All'
  }
}, false, 'bootstrap3', { dataTable: HiaVueTable2 })
Vue.use(VueLocalStorage)
Vue.component('hia-el-select', HiaElSelect)

function main() {
  checkAuth()
  initializeAppInsights()
}

function checkAuth() {
  auth.authNotifier.on('authChange', authState => {
    if (authState.authenticated) {
      // redirect the user back to the original route after the Auth0 login and callback process is finished
      const redirectPath = window.localStorage.getItem('redirectPath')
      if (redirectPath) {
        window.history.replaceState(null, null, redirectPath)
      }
      setUser()
    }
  })

  // if this is a callback from Auth0, the url will contain 'access_token'
  if (window.location.href.indexOf('access_token') > -1) {
    auth.handleAuthentication()
  } else {
    if (!auth.isAuthenticated()) {
      // if blocked user show message
      window.localStorage.setItem('redirectPath', window.location.pathname + window.location.search + window.location.hash)
      if (window.location.href.indexOf('error_description=user%20is%20blocked') > -1 || window.location.href.indexOf('error_description=Access%20denied.') > -1) {
        showBlockedUserMessage()
        return
      }
      auth.login()
    } else {
      auth.scheduleRenewal()
      setUser()
    }
  }
}

// app insights
function initializeAppInsights() {
  var appInsights = window.appInsights || (function (config) {
    function i(config) { t[config] = function () { var i = arguments; t.queue.push(function () { t[config].apply(t, i) }) } } var t = { config: config }; var u = document; var e = window; var o = 'script'; var s = 'AuthenticatedUserContext'; var h = 'start'; var c = 'stop'; var l = 'Track'; var a = l + 'Event'; var v = l + 'Page'; var y = u.createElement(o); var r; var f; y.src = config.url || 'https://az416426.vo.msecnd.net/scripts/a/ai.0.js'; u.getElementsByTagName(o)[0].parentNode.appendChild(y); try { t.cookie = u.cookie } catch (p) { } for (t.queue = [], t.version = '1.0', r = ['Event', 'Exception', 'Metric', 'PageView', 'Trace', 'Dependency']; r.length;)i('track' + r.pop()); return i('set' + s), i('clear' + s), i(h + a), i(c + a), i(h + v), i(c + v), i('flush'), config.disableExceptionTracking || (r = 'onerror', i('_' + r), f = e[r], e[r] = function (config, i, u, e, o) { var s = f && f(config, i, u, e, o); return s !== !0 && t['_' + r](config, i, u, e, o), s }), t
  }(
    {
      instrumentationKey: '713c2088-3ed6-43ed-a615-346ee6da0809'
    }))

  window.appInsights = appInsights
  appInsights.trackPageView()
}

function setAppInsightsUser(userName) {
  if (window.appInsights) {
    appInsights.setAuthenticatedUserContext(userName, userName)
  }
}

function setUser() {
  const accessToken = window.localStorage.getItem('access_token')
  const idToken = window.localStorage.getItem('id_token')
  const parsedIdToken = parseJwt(idToken)
  const parsedAccessToken = parseJwt(accessToken)

  const user = {
    email: parsedIdToken.email,
    accessToken: accessToken,
    auth0Id: parsedAccessToken.sub,
    userTenants: [],
    hasAppAccess: false,
    logout: function () {
      auth.logout()
    }
  }

  store.commit('user/SET_USER', user)
  store.dispatch('user/GET_PROFILE').then((response) => {
    setTimeout(() => {
      const el = document.getElementById('cronLoader')
      el.className += ' fade-out'
      // realtime user check
      if (store.state.user.user.userTenants.length === 0) {
        showNoTenantsMessage()
        return
      }
      // set isTrucodeEnabled local storage
      window.localStorage.setItem('isTrucodeEnabled', store.state.user.user.isTrucodeEnabled)

      if (!store.state.user.user.hasAppAccess) {
        showBlockedUserMessage()
        return
      }

      setTimeout(() => {
        document.body.className += ' appBackground'
        initializeVue()
      }, 0)
    }, 0)
  })
  setAppInsightsUser(parsedIdToken.email)
}

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jwt = JSON.parse(window.atob(base64))
  return jwt
}

function showNoTenantsMessage() {
  document.body.classList.remove('appBackground')
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = '/static/css/auth0.css'
  document.head.appendChild(link)
  document.getElementById('loader-wrapper').innerHTML = '<div id="auth0-lock-container-1" class="auth0-lock-container"><div data-reactroot="" class="auth0-lock auth0-lock-opened"><div class="auth0-lock-center"><form class="auth0-lock-widget" method="post"><div class="auth0-lock-widget-container"><div class="auth0-lock-cred-pane auth0-lock-quiet"><div class="auth0-lock-header"><div class="auth0-lock-header-bg auth0-lock-blur-support"><div class="auth0-lock-header-bg-blur" style="background-image: url(&quot;https://nucleus.hiacode.com/static/images/nucleus-logo-poweredby.svg&quot;);"></div><div class="auth0-lock-header-bg-solid" style="background-color: rgb(0, 71, 118);"></div></div><div class="auth0-lock-header-welcome"><img class="auth0-lock-header-logo" height="137" src="https://nucleus.hiacode.com/static/images/nucleus-logo-poweredby.svg"><div class="auth0-lock-name" title="HIA Cron">HIA Cron</div></div></div><div><div></div></div><div style="position: relative;"><span><div class="" style=""><div style="visibility: inherit;"><div class="auth0-lock-view-content"><div style="position: relative;"><div class="auth0-lock-body-content"><div class="auth0-lock-content"><div class="auth0-lock-form"><div><div> <p clsas="auth0-lock-alternative" style="/* color: #333333; */color: rgb(0, 71, 118);font-weight: bold;font-size: 15px;">Your account does not have access to any tenants. Please contact HIA for support.</p><p class="auth0-lock-alternative" style="margin-top: 10px;"><a class="auth0-lock-alternative-link" href="mailto:nucleus@hiacode.atlassian.net?subject=Nucleus Account Help&amp;body=Please include your name and email.">Need Account Help?</a></p><p></p></div></div></div></div></div></div></div></div></div></span></div></div></div></form></div></div></div>'
}

function showBlockedUserMessage() {
  document.body.classList.remove('appBackground')
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = '/static/css/auth0.css'
  document.head.appendChild(link)
  document.getElementById('loader-wrapper').innerHTML = '<div id="auth0-lock-container-1" class="auth0-lock-container"><div data-reactroot="" class="auth0-lock auth0-lock-opened"><div class="auth0-lock-center"><form class="auth0-lock-widget" method="post"><div class="auth0-lock-widget-container"><div class="auth0-lock-cred-pane auth0-lock-quiet"><div class="auth0-lock-header"><div class="auth0-lock-header-bg auth0-lock-blur-support"><div class="auth0-lock-header-bg-blur" style="background-image: url(&quot;https://nucleus.hiacode.com/static/images/nucleus-logo-poweredby.svg&quot;);"></div><div class="auth0-lock-header-bg-solid" style="background-color: rgb(0, 71, 118);"></div></div><div class="auth0-lock-header-welcome"><img class="auth0-lock-header-logo" height="137" src="https://nucleus.hiacode.com/static/images/nucleus-logo-poweredby.svg"><div class="auth0-lock-name" title="HIA Client Portal">HIA Client Portal</div></div></div><div><div></div></div><div style="position: relative;"><span><div class="" style=""><div style="visibility: inherit;"><div class="auth0-lock-view-content"><div style="position: relative;"><div class="auth0-lock-body-content"><div class="auth0-lock-content"><div class="auth0-lock-form"><div><div> <p clsas="auth0-lock-alternative" style="/* color: #333333; */color: rgb(0, 71, 118);font-weight: bold;font-size: 15px;">Your Nucleus account is locked. Please contact HIA for support.</p><p class="auth0-lock-alternative" style="margin-top: 10px;"><a class="auth0-lock-alternative-link" href="mailto:nucleus@hiacode.atlassian.net?subject=Nucleus Account Help&amp;body=Please include your name and email.">Need Account Help?</a></p><p></p></div></div></div></div></div></div></div></div></div></span></div></div></div></form></div></div></div>'
}

function initializeVue() {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

main()
