import { updateField } from 'vuex-map-fields'

const SET_CDISTAFF = (state, payload) => {
  state.cdiStaffList = payload
}

const SET_SELECTED = (state, payload) => {
  payload.forEach(x => {
    const found = state.cdiStaffList.find(y => y.cdiStaffId === x.cdiStaffId)
    if (found) {
      found.selected = x.selected
    }
  })
  state.cdiStaffList = [...state.cdiStaffList] // copy the array to trigger reactivity
}

const PUSH_CDISTAFF = (state, payload) => {
  state.cdiStaffList.push(payload)
}

const SPLICE_CDISTAFF = (state, payload) => {
  const index = state.cdiStaffList.findIndex(x => x.cdiStaffId === payload.cdiStaffId)
  state.cdiStaffList.splice(index, 1)
}

const SET_CDISTAFF_LOADING = (state, payload) => {
  state.cdiStaffLoading = payload
}

const UPDATE_CDISTAFF = (state, payload) => {
  const index = state.cdiStaffList.findIndex(x => x.cdiStaffId === payload.cdiStaffId)
  state.cdiStaffList.splice(index, 1, payload)
}

export default {
  updateField,
  SET_CDISTAFF,
  SET_SELECTED,
  PUSH_CDISTAFF,
  SPLICE_CDISTAFF,
  SET_CDISTAFF_LOADING,
  UPDATE_CDISTAFF
}
