import axios from '@/utilities/axios-global'

export function GET_BLOG_FEED(context, offset) {
  return new Promise((resolve, reject) => {
    axios.get(`${context.rootState.user.baseApiUrl}/utilities/blogposts/${offset}`).then(response => {
      context.commit('SET_BLOG_FEED', response.data)
      resolve(response)
    }, error => {
      console.log(error)
      reject(error)
    })
  })
}

export default {
  GET_BLOG_FEED
}
