import { updateField } from 'vuex-map-fields'

const SET_PAYORDETAIL = (state, payload) => {
  state.payorDetails = payload
}

const SET_PAYORDETAIL_LOADING = (state, payload) => {
  state.payorDetailsLoading = payload
}

const PUSH_PAYORDETAIL = (state, payload) => {
  state.payorDetails.push(payload)
}

const UPDATE_PAYORDETAIL = (state, payload) => {
  const index = state.payorDetails.findIndex(x => x.payorDetailId === payload.payorDetailId)
  state.payorDetails.splice(index, 1, payload)
}

const REMOVE_PAYORDETAIL_BY_PAYORNUMBER = (state, payorNumber) => {
  state.payorDetails = state.payorDetails.filter(x => x.payorNumberId !== payorNumber)
}

const SPLICE_PAYORDETAIL = (state, payorDetailId) => {
  const index = state.payorDetails.findIndex(x => x.payorDetailId === payorDetailId)
  state.payorDetails.splice(index, 1)
}

export default {
  updateField,
  SET_PAYORDETAIL,
  SET_PAYORDETAIL_LOADING,
  PUSH_PAYORDETAIL,
  SPLICE_PAYORDETAIL,
  UPDATE_PAYORDETAIL,
  REMOVE_PAYORDETAIL_BY_PAYORNUMBER
}
