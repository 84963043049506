import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  requiredFields: [
    {
      requiredFieldsId: null,
      reviewType: 'ip',
      requiredFields: null,
      requiredFieldsData: []
    },
    {
      requiredFieldsId: null,
      reviewType: 'op',
      requiredFields: null,
      requiredFieldsData: []
    },
    {
      requiredFieldsId: null,
      reviewType: 'pro',
      requiredFields: null,
      requiredFieldsData: []
    }
  ],
  requiredFieldsLoading: false,
  ipRequiredFieldsGridData: [],
  opRequiredFieldsGridData: [],
  proRequiredFieldsGridData: []
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
