import reportQueries from './reportQueries/store'
import dashboards from './dashboards/store'

import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
  inpatientReport: {
    reportType: '',
    globalSearch: '',
    defaultDateRange: '',
    inpatientData: {
      inpatientListView: [],
      drgTableIds: '',
      filters: [],
      vueTableData: {},
      paginatedCount: 0
    }
  },
  outpatientReport: {
    reportType: '',
    globalSearch: '',
    defaultDateRange: '',
    outpatientData: {
      outpatientListView: [],
      ascTableIds: '',
      filters: [],
      vueTableData: {},
      paginatedCount: 0
    }
  },
  ambulatoryReport: {
    reportType: '',
    globalSearch: '',
    defaultDateRange: '',
    outpatientData: {
      outpatientListView: [],
      ascTableIds: '',
      filters: [],
      vueTableData: {},
      paginatedCount: 0
    }
  },
  proReport: {
    reportType: '',
    globalSearch: '',
    defaultDateRange: '',
    proData: {
      proRecordListView: [],
      proRecordIds: '',
      filters: [],
      vueTableData: {},
      paginatedCount: 0
    }
  },
  ipReportDataLoading: false,
  opReportDataLoading: false,
  proReportDataLoading: false
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    reportQueries,
    dashboards
  }
}
