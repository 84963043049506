import axios from '@/utilities/axios-global'

const GET_CODER_IP_EXISTS = async context => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/coder/inpatient/exists`)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_CODER_PRO_EXISTS = async context => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/coder/pro/exists`)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_CODER_OP_EXISTS = async context => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/coder/outpatient/exists`)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_CODER_IP_LIST_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/coder/inpatient/paginated`, payload)
    context.commit('SET_IP_LIST_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_CODER_OP_LIST_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/coder/outpatient/paginated?outpatientGrouperId=1`, payload)
    context.commit('SET_OP_LIST_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_CODER_AMB_LIST_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/coder/outpatient/paginated?outpatientGrouperId=2`, payload)
    context.commit('SET_AMB_LIST_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_IP_WORKSHEET = async (context, drgTableId) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/coder/inpatient/worksheet/${drgTableId}`)
    context.commit('SET_IP_WORKSHEET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_CODER_PRO_LIST_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/coder/pro/paginated`, payload)
    context.commit('SET_PRO_LIST_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_PRO_WORKSHEET = async (context, proRecordId) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/coder/pro/worksheet/${proRecordId}`)
    context.commit('SET_PRO_WORKSHEET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_OP_WORKSHEET = async (context, ascTableId) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/coder/outpatient/worksheet/${ascTableId}`)
    context.commit('SET_OP_WORKSHEET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const RUN_IP_MASS_ACKNOWLEDGE_NO_CHANGES = (context, payload) => {
  try {
    const response = axios.put(`${context.rootState.user.baseApiUrl}/coder/inpatient/massacknowledgenochanges`, payload)
    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const RUN_OP_MASS_ACKNOWLEDGE_NO_CHANGES = (context, payload) => {
  try {
    const response = axios.put(`${context.rootState.user.baseApiUrl}/coder/outpatient/massacknowledgenochanges`, payload)
    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const RUN_PRO_MASS_ACKNOWLEDGE_NO_CHANGES = (context, payload) => {
  try {
    const response = axios.put(`${context.rootState.user.baseApiUrl}/coder/pro/massacknowledgenochanges`, payload)
    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  LOAD_CODER_IP_LIST_PAGINATED,
  GET_IP_WORKSHEET,
  LOAD_CODER_PRO_LIST_PAGINATED,
  GET_PRO_WORKSHEET,
  GET_CODER_IP_EXISTS,
  GET_CODER_PRO_EXISTS,
  LOAD_CODER_OP_LIST_PAGINATED,
  LOAD_CODER_AMB_LIST_PAGINATED,
  GET_OP_WORKSHEET,
  GET_CODER_OP_EXISTS,
  RUN_IP_MASS_ACKNOWLEDGE_NO_CHANGES,
  RUN_OP_MASS_ACKNOWLEDGE_NO_CHANGES,
  RUN_PRO_MASS_ACKNOWLEDGE_NO_CHANGES
}
