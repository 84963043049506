import { updateField } from 'vuex-map-fields'

const SET_CHECKLISTS = (state, checklistItems) => {
  if (checklistItems) {
    const uniqueChecklistIds = [...new Set(checklistItems.map(item => item.checklistId))]
    const checklists = uniqueChecklistIds.map(x => {
      return {
        checklistId: x,
        codeTypeId: checklistItems.find(y => y.checklistId === x).codeTypeId,
        checklistItems: checklistItems.filter(y => y.checklistId === x)
      }
    })

    state.auditChecklists = checklists
    if (state.auditChecklists.length > 0) {
      state.activeChecklist = state.auditChecklists[0]
    } else {
      state.activeChecklist = {}
    }
  } else {
    state.auditChecklists = []
  }
}

const CONCAT_CHECKLIST_ITEMS = (state, checklistItems) => {
  const uniqueChecklistIds = [...new Set(checklistItems.map(item => item.checklistId))]
  const checklists = uniqueChecklistIds.map(x => {
    return {
      checklistId: x,
      codeTypeId: checklistItems.find(y => y.checklistId === x).codeTypeId,
      checklistItems: checklistItems.filter(y => y.checklistId === x)
    }
  })

  state.auditChecklists = state.auditChecklists.concat(checklists)
}

const SET_ACTIVE_CHECKLIST_FROM_ITEMS = (state, checklistItems) => {
  const uniqueChecklistIds = [...new Set(checklistItems.map(item => item.checklistId))]
  state.activeChecklist = {
    checklistId: uniqueChecklistIds[0],
    codeTypeId: checklistItems[0].codeTypeId,
    checklistItems: checklistItems
  }
}

const SET_ACTIVE_CHECKLIST = (state, payload) => {
  state.activeChecklist = payload
}

const PUSH_CHECKLIST = (state, payload) => {
  state.auditChecklists.push(payload)
}

const REMOVE_CHECKLIST = (state, checklistId) => {
  state.auditChecklists = state.auditChecklists.filter(x => x.checklistId !== checklistId)
}

export default {
  SET_CHECKLISTS,
  SET_ACTIVE_CHECKLIST,
  PUSH_CHECKLIST,
  REMOVE_CHECKLIST,
  CONCAT_CHECKLIST_ITEMS,
  SET_ACTIVE_CHECKLIST_FROM_ITEMS,
  updateField
}
