import axios from '@/utilities/axios-global'

const GET_PROJECT_TASKS = async (context) => {
  try {
    context.commit('SET_PROJECT_TASK_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/client-project-tasks`)
    context.commit('SET_PROJECT_TASKS', response.data)
    context.commit('SET_PROJECT_TASK_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_TASK_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_PROJECT_TASK = async (context, payload) => {
  try {
    context.commit('SET_PROJECT_TASK_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/client-project-tasks`
    const response = await axios.post(url, payload.task)
    context.commit('PUSH_PROJECT_TASK', response.data)
    context.commit('SET_PROJECT_TASK_LOADING', false)
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_TASK_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_PROJECT_TASK = async (context, task) => {
  try {
    context.commit('SET_PROJECT_TASK_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/client-project-tasks/${task.taskId}`
    const response = await axios.put(url, task)
    context.commit('SET_PROJECT_TASK_LOADING', false)
    context.commit('UPDATE_PROJECT_TASK', response.data)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_TASK_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_PROJECT_TASK = async (context, task) => {
  try {
    context.commit('SET_PROJECT_TASK_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/client-project-tasks/${task.taskId}`
    await axios.delete(url)
    context.commit('SPLICE_PROJECT_TASK', task)
    context.commit('SET_PROJECT_TASK_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_TASK_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_PROJECT_TASKS,
  DELETE_PROJECT_TASK,
  PUT_PROJECT_TASK,
  POST_PROJECT_TASK
}
