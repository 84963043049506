import axios from '@/utilities/axios-global'
// import hiaEventBus from '@/mixins/hiaEvent-Bus'

const PUT_ACKNOWLEDGE_NOTIFICATION = async (context, payload) => {
  try {
    context.commit('SET_NOTIFICATIONSLOADING', true)

    const response = await axios.put(`${context.rootState.user.baseApiUrl}/notifications/acknowledge/${payload.row.userId}/${payload.row.assigneeId}`, payload)
    context.commit('UPDATE_USER_NOTIFICATIONS', response.data)
    context.commit('SET_NOTIFICATIONSLOADING', false)
  } catch (err) {
    context.commit('SET_NOTIFICATIONSLOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_DISMISSACTIVATE_NOTIFICATION = async (context, payload) => {
  try {
    context.commit('SET_NOTIFICATIONSLOADING', true)

    const response = await axios.put(`${context.rootState.user.baseApiUrl}/notifications/dismissactivate/${payload.row.userId}/${payload.row.assigneeId}/${payload.condition}`)
    context.commit('DISMISSACTIVATE_USER_NOTIFICATIONS', response.data)
    context.commit('SET_NOTIFICATIONSLOADING', false)
  } catch (err) {
    context.commit('SET_NOTIFICATIONSLOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_BLOB = async (context, payload) => {
  try {
    context.commit('SET_NOTIFICATIONSLOADING', true)
    const bodyFormData = new FormData()
    bodyFormData.set('file', payload.file)

    const url = `${context.rootState.user.baseApiUrl}/notifications/postblob`
    const response = await axios.post(url, bodyFormData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    context.commit('SET_NOTIFICATIONSLOADING', false)
    return response.data
  } catch (err) {
    context.commit('SET_NOTIFICATIONSLOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const DELETE_BLOB = async (context, payload) => {
  try {
    context.commit('SET_NOTIFICATIONSLOADING', true)

    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/blobstore/?pathAndFileName=notifications/${payload}`)
    context.commit('SET_NOTIFICATIONSLOADING', false)
    return response.data
  } catch (err) {
    context.commit('SET_NOTIFICATIONSLOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_NOTIFICATION = async (context, payload) => {
  try {
    context.commit('SET_NOTIFICATIONSLOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/notifications`, payload)
    context.commit('PUSH_ADMIN_NOTIFICATIONS', response.data)
    context.commit('SET_ADMIN_NOTIFICATION', response.data)
    context.commit('SET_NOTIFICATIONSLOADING', false)
  } catch (err) {
    context.commit('SET_NOTIFICATIONSLOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const DELETE_NOTIFICATION = async (context, payload) => {
  try {
    context.commit('SET_NOTIFICATIONSLOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/notifications/delete/${payload.id}`, payload)
    context.commit('DELETE_ADMIN_NOTIFICATIONS', payload.id)
    context.commit('SET_NOTIFICATIONSLOADING', false)
    return response
  } catch (err) {
    context.commit('SET_NOTIFICATIONSLOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_ADMIN_NOTIFICATIONS = async (context, payload) => {
  try {
    context.commit('SET_NOTIFICATIONSLOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/notifications/adminlist/${payload.startDate}/${payload.endDate}`)
    context.commit('SET_ADMIN_NOTIFICATIONS', response.data)
    context.commit('SET_NOTIFICATIONSLOADING', false)
  } catch (err) {
    context.commit('SET_NOTIFICATIONSLOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_ADMIN_NOTIFICATION = async (context, id) => {
  try {
    context.commit('SET_NOTIFICATIONSLOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/notifications/${id}`)
    context.commit('SET_ADMIN_NOTIFICATION', response.data)
    context.commit('SET_NOTIFICATIONSLOADING', false)
  } catch (err) {
    context.commit('SET_NOTIFICATIONSLOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_ADMIN_NOTIFICATION = async (context, payload) => {
  try {
    context.commit('SET_NOTIFICATIONSLOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/notifications/${payload.id}`, payload)
    context.commit('SET_NOTIFICATIONSLOADING', false)
    return response
  } catch (err) {
    context.commit('SET_NOTIFICATIONSLOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_USER_NOTIFICATIONS = async (context, params) => {
  try {
    context.commit('SET_NOTIFICATIONSLOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/notifications/userlist/${params.userId}?startDate=${params.startDate}&endDate=${params.endDate}`)
    context.commit('SET_USER_NOTIFICATIONS', response.data)
    context.commit('SET_NOTIFICATIONSLOADING', false)
  } catch (err) {
    context.commit('SET_NOTIFICATIONSLOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  POST_NOTIFICATION,
  POST_BLOB,
  DELETE_BLOB,
  GET_ADMIN_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  PUT_ADMIN_NOTIFICATION,
  GET_ADMIN_NOTIFICATION,
  GET_USER_NOTIFICATIONS,
  PUT_ACKNOWLEDGE_NOTIFICATION,
  PUT_DISMISSACTIVATE_NOTIFICATION
}
