import axios from '@/utilities/axios-global'

const POST_BLOB = async (context, payload) => {
  const bodyFormData = new FormData()
  bodyFormData.append('file', payload.blob)

  let url = `${context.rootState.user.baseApiUrl}/blobstore?blobPathPrefix=${encodeURIComponent(payload.blobPathPrefix)}&fileExtension=${payload.fileExtension}`

  if (payload.resizeWidth) {
    url += `&resizeWidth=${payload.resizeWidth}`
  }

  try {
    const response = await axios.post(url, bodyFormData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    return response
  } catch (e) {
    throw new Error(e)
  }
}

export default {
  POST_BLOB
}
