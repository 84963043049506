import { updateField } from 'vuex-map-fields'

const SET_PRACTICES = (state, payload) => {
  state.practices = payload
}

const PUSH_PRACTICE = (state, payload) => {
  state.practices.push(payload)
}

const REMOVE_CLIENTPRACTICE = (state, practice) => {
  const len = state.practices.length
  for (let i = 0; i < len; i++) {
    if (state.practices[i].practiceId === practice.practiceId) {
      state.practices.splice(i, 1)
      break
    }
  }
}

const PUSH_PROVIDER = (state, payload) => {
  const len = state.practices.length
  for (let i = 0; i < len; i++) {
    if (state.practices[i].practiceId === payload.practiceId) {
      state.practices[i].clientPracticesProviders.push(payload)
      break
    }
  }
}

const PUSH_PRACTICE_PROVIDER_NEW = (state, payload) => {
  const practice = state.practices.find(x => x.practiceId === payload.practiceId)
  practice.clientPracticesProvidersNew.push({
    practiceId: practice.practiceId,
    providerId: payload.providerId,
    provider: {
      name: payload.providerName
    }
  })
}

const SPLICE_PRACTICE_PROVIDER_NEW = (state, payload) => {
  const practice = state.practices.find(x => x.practiceId === payload.practiceId)
  const index = practice.clientPracticesProvidersNew.findIndex(x => x.providerId === payload.providerId)
  practice.clientPracticesProvidersNew.splice(index, 1)
}

const SET_PRACTICES_LOADING = (state, payload) => {
  state.practicesLoading = payload
}

export default {
  updateField,
  SET_PRACTICES,
  PUSH_PRACTICE,
  REMOVE_CLIENTPRACTICE,
  PUSH_PROVIDER,
  PUSH_PRACTICE_PROVIDER_NEW,
  SPLICE_PRACTICE_PROVIDER_NEW,
  SET_PRACTICES_LOADING
}
