<template>
  <div :class="applyOuterClasses()">
    <!-- <transition name="el-fade-in-linear"> -->
    <!-- <el-collapse-transition> -->
    <el-carousel v-show="compNotifications" :class="applyInnerClasses()" style="width: 60%; margin: 0 auto;"
      indicator-position="outside" :autoplay="false" arrow="always">
      <el-carousel-item v-for="item in compNotifications" :key="item.id">
        <span class="container">
          <el-image class="bannerImage" style="width: 100%;display: inline;"
            :src="item.media.find(f => f.type === 'banner') ? item.media.find(f => f.type === 'banner').url : ''"
            fit="contain">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <div class="middle">
            <el-button class="cta" type="warning" @click="showNotificationDialog(item)">
              View
              Message</el-button>
          </div>
        </span>
      </el-carousel-item>
    </el-carousel>
    <!-- </transition> -->
    <!-- </el-collapse-transition> -->
    <el-dialog :visible.sync="videoDialogVisible" :before-close="close" :show-close="true"
      :close-on-click-modal="false">
      <span style="font-size: 20px;color: #72767b;" slot="title">
        {{this.currentItem.title}}
      </span>
      <div v-if="this.currentItem.body.length" class="notificationBody">
        {{this.currentItem.body}}
      </div>
      <div style="margin: 0px 0px 0px 0px;" v-if="this.currentItem.media.length" width="100%;">
        <el-carousel :class="this.currentItem.media.filter(f => f.type !== 'banner').length === 1 ? 'single' : ''"
          height="410px" indicator-position="outside" :autoplay="false" arrow="always">
          <el-carousel-item v-for="item in this.currentItem.media.filter(f => f.type !== 'banner')" :key="item.id">
            <el-image @click="handlePreview(currentItem)" v-if="item.type !== 'video'"
              style="width: 100%;display: inline;" :src="item.url" fit="contain">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <div v-if="item.type === 'video'" style="width: 100%; display: inline;">
              <iframe v-if="videoDialogVisible" :src="item.url" allowfullscreen
                style="border:none; height: 100%; width: 100%"></iframe>
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-image :id="`modal_preview_${this.currentItem.id}`" style="width: 0px; height: 0px;"
          :src="this.currentItem.media.filter(f => f.type !== 'video').map(m => m.url)[0]"
          :preview-src-list="this.currentItem.media.filter(f => f.type !== 'video' && f.type !== 'banner').map(m => m.url)"
          fit="contain">
        </el-image>
      </div>
      <div style="margin: 0px 0px 10px 0px;" v-if="this.currentItem.links.length">
        <el-button v-for="item in this.currentItem.links" :key="item.id" type="info" size="mini" plain
          @click="notificationButtonUrl(item)" class="dismissedButton">
          {{item.title}}
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import hiaFilters from '@/mixins/hiaFilters'

  export default {
    name: 'notificationsBanner',
    props: {
      previewItem: Array
    },
    mixins: [hiaFilters],
    data() {
      return {
        videoUrl: '',
        videoDialogVisible: false,
        currentItem: {
          id: 0,
          startDate: '',
          title: '',
          body: '',
          media: [],
          links: [],
          dismissed: false,
          acknowledged: false,
          isBanner: false
        }
      }
    },
    methods: {
      ...mapActions('notifications/', ['PUT_ACKNOWLEDGE_NOTIFICATION']),
      ...mapMutations('notifications/', ['SET_SHOWNOTIFICATIONS']),
      bannerLength() {
        // let media = this.notificationData.filter(f => f.isBanner).length
        // let banners = media.filter(f => f.type === 'banner')
      },
      showNotificationDialog(item) {
        this.currentItem = item
        this.videoDialogVisible = true
      },
      close() {
        if (this.previewItem && this.previewItem.length) {
          this.videoDialogVisible = false
          return
        }
        console.log(this.currentItem)
        if (!this.currentItem.acknowledged) {
          const payload = {
            row: this.currentItem,
            acknowledged: true
          }
          this.PUT_ACKNOWLEDGE_NOTIFICATION(payload).then(response => {
          })
        }
        this.currentItem = {
          id: 0,
          startDate: '',
          title: '',
          body: '',
          media: [],
          links: [],
          dismissed: false,
          acknowledged: false,
          isBanner: false
        }
        this.videoDialogVisible = false
      },
      handlePreview(item) {
        const element = document.getElementById(`modal_preview_${item.id}`)
        if (element) {
          element.click()
        }
      },
      notificationButtonUrl(item) {
        window.open(item.url, '_blank')
      },
      applyInnerClasses() {
        let classes = 'mainBanner '

        if (this.compNotifications.length === 1) {
          classes += 'single '
        }

        return classes
      },
      applyOuterClasses() {
        let classes = 'bannerNotifications '

        if (this.compNotifications.length === 0) {
          classes += 'hidden'
        }
        return classes
      }
    },
    computed: {
      ...mapState('notifications/', ['showNotifications', 'notificationData']),
      compNotifications: function () {
        if (this.previewItem && this.previewItem.length) {
          return this.previewItem
        }
        return this.notificationData.length ? this.notificationData.filter(f => f.isBanner && f.acknowledged === false) : []
      }
    }
  }

</script>

<style scoped>
  .bannerNotifications {
    margin: 20px 0px 0px 0px;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    opacity: 1;
  }

  .bannerNotifications.hidden {
    opacity: 0;
    display: none;
  }

  .mainBanner>>>.el-carousel__container {
    height: 350px;
  }

  >>>.bannerImage {
    opacity: 1;
  }

  >>>.middle {
    transition: 0.3s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background: rgba(255, 255, 255, 0.85);
    width: 100%;
    height: 100%;
  }

  >>>.cta {
    /* position: absolute; */
    /* left: 50%; */
    margin: 150px auto;
  }

  .container:hover .bannerImage {
    /* opacity: 0.3; */
  }

  .container:hover .middle {
    opacity: 1;
  }

  >>>.el-carousel__arrow {
    background-color: rgba(31, 45, 61, 0.61);
  }

  >>>.el-carousel__indicators--outside button {
    background-color: rgba(31, 45, 61, 0.61);
  }

  .bannerNotifications .single>>>.el-carousel__indicators,
  .bannerNotifications .single>>>.el-carousel__arrow {
    display: none;
  }

  /* lightbox styles */
  >>>.el-image>.el-image-viewer__wrapper>.el-image-viewer__btn {
    color: #ffffff;
    opacity: 1;
  }

  >>>.image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #f5f7fa;
    color: #909399;
    font-size: 30px;
  }

  >>>.notificationBody {
    margin: 0px 0px 10px 0px;
    word-break: normal;
  }
</style>
