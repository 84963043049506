import { updateField } from 'vuex-map-fields'

const SET_PROJECT_PROCESSES = (state, payload) => {
  state.projectProcessesList = payload
}

const PUSH_PROJECT_PROCESS = (state, payload) => {
  state.projectProcessesList.push(payload)
}

const SPLICE_PROJECT_PROCESS = (state, payload) => {
  const index = state.projectProcessesList.findIndex(x => x.processId === payload.processId)
  state.projectProcessesList.splice(index, 1)
}

const SET_PROJECT_PROCESSES_LOADING = (state, payload) => {
  state.projectProcessListLoading = payload
}

const UPDATE_PROJECT_PROCESS = (state, payload) => {
  const index = state.projectProcessesList.findIndex(x => x.processId === payload.processId)
  state.projectProcessesList.splice(index, 1, payload)
}

export default {
  updateField,
  SET_PROJECT_PROCESSES,
  PUSH_PROJECT_PROCESS,
  SPLICE_PROJECT_PROCESS,
  SET_PROJECT_PROCESSES_LOADING,
  UPDATE_PROJECT_PROCESS
}
