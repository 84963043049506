import axios from '@/utilities/axios-global'

const GET_MODIFIERS = async (context, clientNumber) => {
  try {
    context.commit('SET_MODIFIER_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/modifiers`)
    context.commit('SET_MODIFIERS', response.data)
    context.commit('SET_MODIFIER_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_MODIFIER_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_MODIFIER = async (context, payload) => {
  try {
    context.commit('SET_MODIFIER_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/modifiers`
    const response = await axios.post(url, payload.modifier)
    context.commit('PUSH_MODIFIER', response.data)
    context.commit('SET_MODIFIER_LOADING', false)
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_MODIFIER_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_MODIFIER = async (context, modifier) => {
  try {
    context.commit('SET_MODIFIER_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/modifiers/${modifier.modifierId}`
    const response = await axios.put(url, modifier)
    context.commit('SET_MODIFIER_LOADING', false)
    context.commit('UPDATE_MODIFIER', response.data)
  } catch (err) {
    console.log(err)
    context.commit('SET_MODIFIER_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_MODIFIER = async (context, modifier) => {
  try {
    context.commit('SET_MODIFIER_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/modifiers/${modifier.modifierId}`
    await axios.delete(url)
    context.commit('SPLICE_MODIFIER', modifier)
    context.commit('SET_MODIFIER_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_MODIFIER_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_MODIFIERS,
  DELETE_MODIFIER,
  PUT_MODIFIER,
  POST_MODIFIER
}
