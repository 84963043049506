import { updateField } from 'vuex-map-fields'

const SET_PAYORLIST_LOADING = (state, payload) => {
  state.payorListLoading = payload
}

const SET_PAYORLIST = (state, payload) => {
  state.payorList = payload
}

const UPDATE_PAYORLIST = (state, payload) => {
  if (payload) {
    const index = state.payorList.findIndex(f => f.payorNumber === payload.payorNumber)
    state.payorList.splice(index, 1, payload)
  }
}

const PUSH_PAYORLIST = (state, payload) => {
  state.payorList.push(payload)
}

const SPLICE_PAYORLIST = (state, payorNumber) => {
  const index = state.payorList.findIndex(x => x.payorNumber === payorNumber)
  state.payorList.splice(index, 1)
}

export default {
  updateField,
  SET_PAYORLIST,
  UPDATE_PAYORLIST,
  SET_PAYORLIST_LOADING,
  PUSH_PAYORLIST,
  SPLICE_PAYORLIST
}
