const GET_GROUPERVERSIONSFORGROUPER = (state) => (grouper) => {
  if (state.dropdowns.grouperVersions) {
    const filteredGrouperVersions = state.dropdowns.grouperVersions.list.filter(gv => gv.key === grouper)
    // now map this to our expected key/value style
    const localGrouperVersions = filteredGrouperVersions.map(x => ({ key: x.value, value: x.value }))
    return localGrouperVersions
  }
  return null
}

const GET_GROUPERPRICERS = (state) => (grouper, version) => {
  if (state.dropdowns.pricers) {
    let filteredPricers = []
    if (grouper && !version) {
      filteredPricers = state.dropdowns.pricers.list.filter((p, index, self) => {
        const splitKey = p.key.split('|')
        // when not dealing with versions, we need to prevent returning dupes by using
        // findIndex to only return the first match
        return grouper === splitKey[0] && index === self.findIndex(first => first.value === p.value)
      })
    }
    if (grouper && version) {
      filteredPricers = state.dropdowns.pricers.list.filter((p, index, self) => {
        const splitKey = p.key.split('|')
        return grouper === splitKey[0] && version === splitKey[1]
      })
    }
    // now map this to our expected key/value style
    const localPricers = filteredPricers.map(x => ({ key: x.value, value: x.value }))
    return localPricers
  }
  return null
}

const GET_COLUMNFILTERARRAY = (state) => (dropdownName) => {
  const returnArray = []
  let currArray = []
  currArray = state.dropdowns[dropdownName].list
  const length = currArray.length

  for (let j = 0; j < length; j++) {
    const newElement = {
      id: currArray[j].key,
      text: currArray[j].value
    }
    returnArray[j] = newElement
  }
  return returnArray
}

const GET_VALUEFROMKEY = (state) => (listName, key) => {
  const localList = state.dropdowns[listName].list.filter(c => c.key === key)
  if (localList.length > 0) {
    return localList[0].value
  }
  return null
}

export default {
  GET_GROUPERVERSIONSFORGROUPER,
  GET_GROUPERPRICERS,
  GET_COLUMNFILTERARRAY,
  GET_VALUEFROMKEY
}
