import axios from '@/utilities/axios-global'

const GET_AVOIDABLEDAYREASONS = async (context) => {
  try {
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/avoidabledayreasons`)
    context.commit('SET_AVOIDABLEDAYREASON', response.data)
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', false)
  } catch (err) {
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', false)
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_AVOIDABLEDAYREASONS = async (context, payload) => {
  try {
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/avoidabledayreasons/${payload.avoidableDayReasonId}`
    const response = await axios.put(url, payload)
    context.commit('UPDATE_AVOIDABLEDAYREASON', response.data)
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_AVOIDABLEDAYREASONS = async (context, payload) => {
  try {
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/avoidabledayreasons`, payload.avoidableDayReason)
    context.commit('PUSH_AVOIDABLEDAYREASON', response.data)
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_AVOIDABLEDAYREASONS = async (context, avoidableDayReasonId) => {
  try {
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/avoidabledayreasons/${avoidableDayReasonId}`)
    context.commit('SPLICE_AVOIDABLEDAYREASON', avoidableDayReasonId)
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_AVOIDABLEDAYREASONS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_AVOIDABLEDAYREASONS,
  PUT_AVOIDABLEDAYREASONS,
  POST_AVOIDABLEDAYREASONS,
  DELETE_AVOIDABLEDAYREASONS
}
