<template>
  <div>
    <el-input v-show="!showSelect" :placeholder="placeholder" type="text" readonly :disabled="disabled" :value="getLabel(value)" @focus="handleFocus"
      @mouseover.native="handleMouseOver" size="mini">
      <template slot="suffix">
        <i v-if="!disabled" @click="handleFocus" style="margin-top: 5px; margin-right: 7px; font-size: 14px; cursor: pointer;" class="el-icon-arrow-down"></i>
      </template>
    </el-input>
    <el-select v-show="showSelect" ref="theSelect" :disabled="disabled" @visible-change="handleVisbleChange" @blur="handleBlur(isVisible)"
      @mouseleave.native="handleMouseLeave(isVisible)" v-on:input="handleInputEvent" :default-first-option="true" size="mini" :value="value" :placeholder="placeholder"
      :clearable="clearable" :filterable="filterable" :filter-method="filterMethod !== undefined ? filterMethod : defaultFilterMethod"
      @keydown.enter.native="handleEnterKeyMethod" input="value">
      <template v-if="showSelect">
        <el-option v-for="item in dropdownsDeepCopy" :key="item[itemkey]" :label="item[label]" :value="item[itemkey]" :disabled="item[inactiveProp]">
        </el-option>
      </template>
    </el-select>
  </div>
</template>

<script>
  export default {
    name: 'hia-el-select',
    props: {
      value: [String, Number],
      dropdowns: Array,
      disabled: Boolean,
      itemkey: String,
      label: String,
      clearable: Boolean,
      filterable: {
        type: Boolean,
        default: true
      },
      inactiveProp: {
        type: String,
        default: 'inactive'
      },
      placeholder: String,
      filterMethod: Function
    },
    data() {
      return {
        showSelect: false,
        isVisible: false,
        dropdownsDeepCopy: []
      }
    },
    mounted: function () {
    },
    created: function () {
      this.handleReset()
    },
    methods: {
      handleFocus() {
        this.showSelect = true
        this.$nextTick(() => {
          if (this.$refs.theSelect) {
            this.$refs.theSelect.focus()
          }
        })
      },
      handleMouseOver() {
        // console.log('mouse over')
        // this.showSelect = true
      },
      handleVisbleChange(isVisible) {
        // console.log('element dropdown opened or closed')
        if (isVisible) {
          this.isVisible = true
        } else {
          this.isVisible = false
          this.showSelect = false
          if (this.filterMethod !== undefined) {
            this.$emit('reset')
          } else {
            this.handleReset()
          }
        }
      },
      handleBlur(isVisible) {
        // console.log('blur')
        if (!isVisible) {
          this.isVisible = false
        }
      },
      handleMouseLeave(isVisible) {
        // console.log('mouse leave')
        this.mouseIsOver = false
        if (!isVisible) {
          this.showSelect = false
          this.isVisible = false
        }
      },
      handleInputEvent(value) {
        this.$emit('input', value)
        this.isVisible = false
        this.$refs.theSelect.focus()
      },
      getLabel(key) {
        const localObj = this.dropdowns
        const length = localObj ? localObj.length : 0
        for (let i = 0; i < length; i++) {
          if (localObj[i][this.itemkey] === key) {
            return localObj[i][this.label]
          }
        }
      },
      handleEnterKeyMethod() {
        this.$emit('enterKeyMethod')
      },
      defaultFilterMethod(query) {
        if (query !== null) {
          this.dropdownsDeepCopy = this.dropdowns.filter(option => {
            return query.length <= 3 ? option[this.label].toLowerCase().startsWith(query.toLowerCase()) : (option[this.label].toLowerCase().indexOf(query.toLowerCase()) > -1)
          })
        }
      },
      handleReset() {
        if (this.dropdowns) {
          this.dropdownsDeepCopy = JSON.parse(JSON.stringify(this.dropdowns))
        }
      }
    },
    watch: {
      value() {
        // this.$refs['theSelect'].focus()
      },
      dropdowns: {
        handler: function (val) {
          this.dropdownsDeepCopy = this.dropdowns
        },
        deep: true
      }
    },
    beforeDestroy() {
      this.$el = null
    }
  }
</script>

<style scoped>
  >>>.el-icon-circle-close {
    margin-top: -2px;
  }
</style>
