<template>
  <div id="app">
    <div :class="isFullScreen ? 'navContainer displayNone' : 'navContainer'">
      <div id="logo" style="height: 45px; margin: 5px 0px 0px 0px; width: 60px; z-index: 1; position:relative; float: left; opacity: .7;">
        <img src="/static/images/Atom-Audit-Logo-only.png" height="50px;" />
      </div>
      <el-menu id="topNav" :router="true" mode="horizontal" :default-active="getActiveMenuItem()" background-color="rgb(238, 238, 238)" text-color="#fff"
        active-text-color="#ffd04b">
        <el-menu-item class="desktop" index="/"> <i class="mdi mdi-home-variant" style="font-size: 16px; margin: 0px 5px 0px 0px; background: transparent;"></i>Home
        </el-menu-item>
        <el-menu-item v-if="inpatientReviewer() || outpatientReviewer() || ambulatoryReviewer() || providerReviewer()" class="desktop" index="/projects"> <i
            class="mdi mdi-briefcase-outline" style="font-size: 16px; margin: 0px 5px 0px 0px; background: transparent;"></i>Projects </el-menu-item>
        <el-menu-item v-if="coder()" class="desktop" index="/my-projects"> <i class="mdi mdi-file-document-multiple-outline"
            style="font-size: 18px; margin: 0px 5px 0px 0px; background: transparent;"></i>My Projects </el-menu-item>
        <el-menu-item v-if="systemManagement()" class="desktop" index="/managed-lists"> <i class="mdi mdi-format-list-numbered"
            style="font-size: 18px; margin: 0px 5px 0px 0px; background: transparent;"></i>Managed Lists </el-menu-item>
        <el-submenu v-if="inpatientReviewer() || outpatientReviewer() || ambulatoryReviewer() || providerReviewer() || coder() || auditorMetrics()" class="desktop reportsLink"
          index="7">
          <template slot="title"> <i class="mdi mdi-chart-bar" style="font-size: 18px; margin: 0px 5px 0px 0px; background: transparent;"></i>Reports </template>
          <el-menu-item v-if="inpatientReviewer() || outpatientReviewer() || providerReviewer() || coder()" class="profileSubItem" index="/reports/filtered-reports">Filtered
            Reports</el-menu-item>
          <el-menu-item v-if="auditorMetrics()" class="profileSubItem" index="/reports/auditor-metrics">Auditor Metrics</el-menu-item>
          <el-menu-item v-if="isProRiskAnalysisEnabled()" class="profileSubItem" index="/reports/pro-risk-analysis">Professional Fee Risk Analysis</el-menu-item>
        </el-submenu>
        <el-submenu id="navProfile" style="float: right;" index="8">
          <template slot="title">
            <avatar class="desktop" :fullname="user.fullName" :image="user.imageData" :size="30"></avatar>
            <span class="tabletNavIcon">
              <i class="mdi mdi-menu"></i>
            </span>
          </template>
          <el-menu-item class="profileSubItem tabletMenuItem" index="/"> <i class="mdi mdi-home-variant"
              style="font-size: 16px;margin: 0px 5px 0px 0px; background: transparent;"></i>Home </el-menu-item>
          <el-menu-item v-if="inpatientReviewer() || outpatientReviewer() || ambulatoryReviewer() || providerReviewer()" class="profileSubItem tabletMenuItem"
            index="/projects"> <i class="mdi mdi-briefcase-outline" style="font-size: 16px; margin: 0px 5px 0px 0px; background: transparent;"></i>Projects </el-menu-item>
          <el-menu-item v-if="coder()" class="profileSubItem tabletMenuItem" index="/my-projects"> <i class="mdi mdi-file-document-multiple-outline"
              style="font-size: 18px; margin: 0px 5px 0px 0px; background: transparent;"></i>My Projects </el-menu-item>
          <el-menu-item v-if="systemManagement()" class="profileSubItem tabletMenuItem" index="/managed-lists"> <i class="mdi mdi-format-list-numbered"
              style="font-size: 18px; margin: 0px 5px 0px 0px; background: transparent;"></i>Managed Lists </el-menu-item>
          <el-menu-item v-if="inpatientReviewer() || outpatientReviewer() || ambulatoryReviewer() || providerReviewer() || coder() || auditorMetrics()"
            class="profileSubItem tabletMenuItem"> <i class="mdi mdi-chart-bar" style="font-size: 18px; margin: 0px 5px 0px 0px; background: transparent;"></i>Reports
          </el-menu-item>
          <el-menu-item v-if="inpatientReviewer() || outpatientReviewer() || ambulatoryReviewer() || providerReviewer() || coder()" class="profileSubItem tabletMenuItem"
            index="/reports/filtered-reports"> <span style="margin: 0px 0px 0px 24px;"></span>Filtered Reports </el-menu-item>
          <el-menu-item v-if="auditorMetrics()" class="profileSubItem tabletMenuItem" index="/reports/auditor-metrics"> <span style="margin: 0px 0px 0px 24px;"></span>Auditor
            Metrics </el-menu-item>
          <el-menu-item v-if="isProRiskAnalysisEnabled()" class="profileSubItem tabletMenuItem" index="/reports/pro-risk-analysis"> <span
              style="margin: 0px 0px 0px 24px;"></span>Professional Fee Risk Analysis</el-menu-item>

          <el-menu-item v-if="inpatientReviewer() || outpatientReviewer() || ambulatoryReviewer() || providerReviewer()" class="profileSubItem" index @click="userProfile"> <i
              class="el-icon-s-tools" style="font-size: 16px;margin: 0px 0px 0px -2px; background: transparent;"></i>My Profile </el-menu-item>
          <el-menu-item class="profileSubItem" index @click="changePassword"> <i class="mdi mdi-account-key"
              style="font-size: 16px;margin: 0px 5px 0px 0px; background: transparent;"></i>Change Password </el-menu-item>
          <el-menu-item v-if="userAdmin()" class="profileSubItem" index @click="openUserAdmin()"> <i class="mdi mdi-account-supervisor"
              style="font-size: 16px;margin: 0px 5px 0px 0px; background: transparent;"></i>User Admin </el-menu-item>
          <a target="_blank" href="https://docs.atom-audit.com/" style="color: unset;">
            <el-menu-item class="profileSubItem" index> <i class="mdi mdi-help-circle-outline"
                style="font-size: 16px;margin: 0px 5px 0px 0px; background: transparent;"></i>Help Documentation </el-menu-item>
          </a>
          <el-menu-item class="profileSubItem" index @click="logout"> <i class="mdi mdi-logout-variant"
              style="font-size: 16px;margin: 0px 5px 0px 0px; background: transparent;"></i>Logout </el-menu-item>
        </el-submenu>
        <el-menu-item style="float: right; background-color: rgb(238, 238, 238) !important;" id="notificationsTab" class="desktop"
          @click.native.prevent="SET_SHOWNOTIFICATIONS(true)">
          <el-badge :value="notificationData.filter(f => f.acknowledged === false && f.deleted === false).length" :max="99" class="badgeItem"
            :hidden="notificationData.filter(f => f.acknowledged === false && f.deleted === false).length ? false : true">
            <span>
              <i class="el-icon-bell"></i>
            </span>
          </el-badge>
        </el-menu-item>
        <el-menu-item style="float: right;" index="10" v-if="user.userTenants.length > 1">
          <el-select v-model="currentTenantComputed" style="width: 200px;" id="tenant">
            <el-option v-for="tenant in user.userTenants" :key="tenant.tenantGuid" :value="tenant.tenantGuid" :label="tenant.tenantName"> {{ tenant.tenantName }}</el-option>
          </el-select>
        </el-menu-item>
      </el-menu>
    </div>
    <el-container>
      <el-main class="mainContainer">
        <notificationBanner></notificationBanner>
        <keep-alive
          include="projectList,reviewList,proReviewList,project,managedLists,inpatientList,proList,opList,InpatientReview,OutpatientReview,ProReview,coderProject,coderProjectList,coderProList,coderOutpatientList,coderInpatientList,coderAmbulatoryList">
          <router-view v-if="!loadingNewTenant && currentTenantComputed"></router-view>
          <!-- <router-view /> -->
        </keep-alive>
      </el-main>
      <el-footer height="100%" :class="isFullScreen ? 'appFooter displayNone' : 'appFooter'">
        <section class="footerSection">
          <el-row :gutter="10">
            <el-col class="textLeft" :span="12">
              <div style="height: 65px;"><img src="/static/images/atom-audit-white-logo.png" height="100%" /></div>
              <p class="hiaFooterDetails">&copy; {{ getCurrentYear() }} Health Information Associates</p>
            </el-col>
            <el-col class="footerLinks textRight" :span="12">
              <a href="https://atom-audit.com/legal" target="_blank">Legal</a>
              <a href="https://atom-audit.com/privacy" target="_blank">Privacy Policy</a>
              <a href="mailto:support@atom-audit.com">Support</a>
            </el-col>
          </el-row>
        </section>
      </el-footer>
    </el-container>
    <!-- drawer  -->
    <notificationsView></notificationsView>
    <!-- end drawer  -->
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import avatar from '@/components/avatar'
  import dateHelper from '@/mixins/date-helper'
  import notificationBanner from '@/views/notifications/notificationBanner'
  import notificationsView from '@/views/notifications/notificationsView'

  export default {
    name: 'cron',
    mixins: [dateHelper],
    data() {
      return {
        loading: false,
        isFullScreen: false,
        loadingNewTenant: false
      }
    },
    methods: {
      ...mapActions('user/', ['CHANGE_PASSWORD']),
      ...mapActions('user/', ['CHANGE_TENANT']),
      ...mapMutations('notifications/', ['SET_SHOWNOTIFICATIONS']),
      ...mapMutations('dropdowns/', ['REMOVE_ALL_CACHED_DROPDOWNS']),
      handleSidebarAndNavbarForRoute() {
        if (this.shouldSetFullScreen()) {
          this.isFullScreen = true
        }
      },
      shouldSetFullScreen() {
        const path = window.location.pathname
        if (path.startsWith('/trucode/codebooks') || path.startsWith('/trucode/references') || path.startsWith('/trucode/principaldx')) {
          return true
        }

        const urlParams = new URLSearchParams(window.location.search)

        if (urlParams.get('fullscreen') === 'true') {
          return true
        }

        return false
      },
      getActiveMenuItem() {
        const path = window.location.pathname
        switch (true) {
          case path.includes('my-projects'):
            return '/my-projects'
          case path.includes('home'):
            return '/'
          case path.includes('projects'):
            return '/projects'
          case path.includes('reviews'):
            return '/projects'
          case path.includes('managed-lists'):
            return '/managed-lists'
          case path.includes('reports/filtered-reports'):
            return '/reports/filtered-reports'
          case path.includes('reports/auditor-metrics'):
            return '/reports/auditor-metrics'
          case path.includes('reports/pro-risk-analysis'):
            return '/reports/pro-risk-analysis'
          default:
            return '/'
        }
      },
      logout() {
        this.user.logout()
      },
      changePassword() {
        this.CHANGE_PASSWORD()
      },
      openUserAdmin() {
        this.$router.push('/user-admin')
      },
      userProfile() {
        this.$router.push('/my-profile')
      },
      showHelixUpdateMessage() {
        // create vnode
        const h = this.$createElement
        this.$message({
          showClose: false,
          message: h('span', null, [
            'System Update Available.  Please ',
            h(
              'span',
              {
                on: {
                  click: function () {
                    location.reload()
                  }
                },
                attrs: { id: 'systemUpdateLink' }
              },
              'Refresh'
            )
          ]),
          duration: 0,
          type: 'warning'
        })
      }
    },
    created: function () {
      this.handleSidebarAndNavbarForRoute()
    },
    mounted: function () { },
    watch: {
      $route() {
        this.handleSidebarAndNavbarForRoute(this.$route)
      },
      helixUpdateMessage: function () {
        if (this.helixUpdateMessage) {
          this.showHelixUpdateMessage()
        }
      }
    },
    computed: {
      ...mapState('globals/', ['helixUpdateMessage']),
      ...mapState('notifications/', ['showNotifications', 'notificationData']),
      ...mapState('user/', ['user', 'currentTenantGuid', 'currentTenantName']),
      currentTenantComputed: {
        get: function () {
          return this.currentTenantGuid
        },
        set: function (newVal) {
          this.loadingNewTenant = true
          // set new tenant guid
          this.CHANGE_TENANT(newVal)
          this.REMOVE_ALL_CACHED_DROPDOWNS()
          window.localStorage.removeItem('atomAuditProfile')

          // force everything to reload with the new client
          this.$nextTick(() => {
            // redirect back home if in the middle of any item with an ID; project, record etc.
            const segments = this.$route.path.split('/')
            if (/^\d+$/.test(segments[segments.length - 1]) && segments.length > 2) {
              window.location.href = '/'
            } else {
              window.location.reload()
            }
            this.loadingNewTenant = false
          })
          return newVal
        }
      }
    },
    components: {
      avatar,
      notificationBanner,
      notificationsView
    }
  }
</script>

<style>
  @media print {
    body {
      -webkit-print-color-adjust: exact;
    }

    @page {
      size: letter landscape;
    }
  }

  #app {}

  p,
  h1,
  h2,
  h3,
  h4 {
    font-weight: normal;
  }

  hr {
    border: 1px solid #c0c4cc;
  }

  a {
    color: #337ab7;
    text-decoration: none;
  }

  /* global worksheet styles */

  .worksheet {
    font-size: 14px;
  }

  .worksheet table img {
    max-width: 100%;
  }

  .worksheet table {
    border-spacing: 0px;
    border-collapse: separate !important;
    table-layout: fixed;
    border-right: solid #ebeef5 1px;
    border-left: solid #ebeef5 1px;
    width: 100%;
  }

  .worksheet table:last-of-type {
    border: solid #ebeef5 1px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .worksheet table:first-of-type {
    border: solid #ebeef5 1px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .worksheet table td:not(:last-child) {
    border-right: 1px solid #ebeef5;
  }

  .worksheet table tr:not(:first-child) td {
    border-top: 1px solid #ebeef5 !important;
  }

  .worksheet td {
    /* border-top: none !important;
    border-bottom: none !important; */
    margin: 0px;
    padding: 4px 8px 4px 8px;
    word-break: break-word;
  }

  .worksheet th {
    border-top: none !important;
    border-bottom: none !important;
    margin: 0px;
    padding: 4px;
    word-break: break-word;
  }

  .link-button {
    color: #337ab7;
    text-decoration: none;
    cursor: pointer;
  }

  .popover {
    word-break: break-word !important;
    text-align: left !important;
  }

  .el-popover {
    box-shadow: 0 6px 12px 0 rgb(0 0 0 / 30%) !important;
    border: 1px solid #e6a23c !important;
  }

  .el-popper[x-placement^='bottom'] .popper__arrow {
    border-bottom-color: #e6a23c !important;
  }

  .el-popper[x-placement^='top'] .popper__arrow {
    border-top-color: #e6a23c !important;
  }

  .el-popper[x-placement^='left'] .popper__arrow {
    border-left-color: #e6a23c !important;
  }

  .el-popper[x-placement^='right'] .popper__arrow {
    border-right-color: #e6a23c !important;
  }

  /* .popper__arrow {
  border-bottom-color: #e6a23c !important;
} */

  .el-loading-spinner {
    top: 25% !important;
    margin-top: 0px !important;
  }

  .el-loading-mask {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }

  .mainContainer {
    /* padding-right: 65px !important;
    padding-left: 65px !important; */
    padding-left: 1.5% !important;
    padding-right: 1.5% !important;
    min-height: 75vh;
    background-color: #ffffff;
    /* padding: 0px !important; */
  }

  .dottedUnderline {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
  }

  .el-main {
    overflow: inherit !important;
  }

  .actionButtons {
    list-style-type: none;
  }

  .pull-right {
    float: right !important;
  }

  .top-margin {
    margin-top: 20px;
  }

  .top-margin-30 {
    margin-top: 30px;
  }

  .heading {
    border-bottom: 1px solid #c0c4cc;
    padding-bottom: 8px;
  }

  .header-row {
    padding-bottom: 9px;
    margin-bottom: 10px;
    margin-top: 0px;
    /* border-bottom: 1px solid #b4bccc; */
  }

  .editorButtons {
    padding: 0 !important;
    display: inline-block;
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
  }

  .editorButtons li {
    display: inline;
    list-style: none;
  }

  .editorButtons li>.el-button--mini {
    padding: 5px;
  }

  #actionTakenCol,
  #supportingDocsCol,
  #codingRefCol {
    transition: 0.3s margin ease-in-out;
  }

  .editorExpand {
    width: 100% !important;
    /* transition: 1.3s margin ease-in-out; */
  }

  .editorCollapse {
    width: 33.333333% !important;
    /* transition: 0.3s margin ease-in-out; */
  }

  .editorHide {
    width: 0%;
    display: none;
  }

  #systemUpdateLink {
    color: #42b983;
    font-weight: bold;
    cursor: pointer;
  }

  .nav-link {
    text-decoration: none !important;
    line-height: 0px;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: 0px !important;
    text-align: left;
  }

  /* Vue Tables 2 Global Styles */

  input[name='vf__delete'],
  input[name='vf__inactive'],
  input[name='vf__selected'],
  input[name='vf__Stats'],
  input[name='vf__isPublic'],
  input[name='vf__queryActions'],
  input[name='vf__ambulatoryReview'],
  input[name='vf__outpatientReview'] {
    display: none;
  }

  .VueTables__inactive-filter-wrapper {
    width: 40px;
  }

  .VueTables__delete-filter-wrapper {
    width: 40px;
  }

  /* TOP NAVIGATION SECTION */

  .reportsLink a {
    color: #909399;
  }

  .reportsLink .router-link-active {
    color: #333333;
  }

  #navProfile.is-active .el-submenu__title {
    background: transparent !important;
  }

  #topNav li.el-menu-item {
    padding: 0 10px;
  }

  #topNav li:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: #333333 !important;
  }

  #topNav li:hover i {
    color: #333333 !important;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }

  #topNav li i {
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }

  #topNav li a {
    display: inline-block;
  }

  /* #topNav li.el-submenu {
  padding: 0 10px;
  background-color: transparent !important;
} */

  #topNav li .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: #333333 !important;
  }

  #topNav li .el-submenu__title:hover i {
    color: #333333 !important;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }

  #topNav li .el-submenu__title i {
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }

  .navContainer {
    background-color: #eeeeee;
    /* padding-right: 75px;
    padding-left: 75px;
    margin-right: auto;
    margin-left: auto; */
    padding-left: 1.5%;
    padding-right: 1.5%;
    height: 60px;
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.5);
    z-index: 1;
    position: relative;
  }

  /* IE only */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  .navContainer {
    box-shadow: 1px 0.5px 3px 0 rgba(0, 0, 0, 0.5);
  }

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    .navContainer {
      box-shadow: 1px 0.5px 3px 0 rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (min-width: 1580px) {

    .navContainer,
    .mainContainer,
    .footerContainer {
      /* padding-right: 200px !important;
    padding-left: 200px !important; */
    }
  }

  .el-menu-item {
    color: #999999 !important;
  }

  .el-submenu__title {
    color: #999999 !important;
  }

  .el-menu--horizontal>.el-menu-item.is-active,
  .el-submenu.is-active .el-submenu__title {
    background-color: rgba(255, 255, 255, 0.5) !important;
    border-bottom: 2px solid #005695 !important;
    border-bottom-color: #005695 !important;
    color: #333333 !important;
  }

  .el-submenu.is-active .el-submenu__title i {
    color: #333333 !important;
  }

  /* PROFILE NAVIGATION  */
  /*updated navProfile*/
  #profileMenu {
    height: 60px;
    float: right;
  }

  #profileMenu>#navProfile {
    height: 60px;
  }

  #profileMenu>li>div.el-submenu__title {
    height: 60px;
    padding-top: 10px;
  }

  /* #navProfile>div:hover {
    background-color: rgba(255, 255, 255, .5) !important;
    color: #333333 !important;
    height: 60px;
  } */

  #navProfile>div:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: #333333 !important;
  }

  #navProfile>div>.el-submenu__icon-arrow {
    display: none;
  }

  .profileSubItem {
    text-align: left;
  }

  .profileSubItem:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: #333333 !important;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }

  .profileSubItem:hover i {
    color: #333333 !important;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }

  .profileSubItem i {
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }

  /* tablet styles*/
  .desktop {
    display: initial;
  }

  .tabletNavIcon {
    display: none;
  }

  .tabletMenuItem {
    display: none;
  }

  #navProfile .el-submenu__title {
    border-bottom-color: transparent !important;
  }

  /* media queries */

  @media screen and (max-width: 1579px) and (min-width: 1310px) {

    /* client nav selector */
    .singleClient {
      left: 65px !important;
    }
  }

  @media screen and (max-width: 1310px) and (min-width: 0px) {

    /* navigation menu */
    .desktop {
      display: none !important;
    }

    .tabletClientSelect {
      float: none !important;
      margin: 0 auto !important;
      width: 300px;
      margin-top: 10px !important;
    }

    .hiaTable table {
      border-top: 1px solid #b4bccc;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      border-collapse: collapse;
      color: #606266;
      width: 100%;
    }

    .tabletNavIcon {
      display: block;
      font-size: 35px;
    }

    .tabletMenuItem {
      display: block;
    }

    .el-menu--horizontal>.el-submenu .el-submenu__title {
      padding: 0 10px;
    }

    /* main container */
    .navContainer,
    .mainContainer,
    .footerContainer {
      /* padding-right: 10px !important;
    padding-left: 10px !important; */
    }

    /* document manager */
    .actionButtons {
      padding-inline-start: 2px;
    }

    /* pro specialties modal */
    .cptPerProvider {
      margin-top: 30px;
    }

    /* client nav selector */
    .singleClient {
      left: 10px !important;
    }
  }

  /*updated navProfile*/
  #profileMenu {
    height: 60px;
    float: right;
  }

  #profileMenu>#navProfile {
    height: 60px;
  }

  #profileMenu>li>div.el-submenu__title {
    height: 60px;
    padding-top: 10px;
  }

  #navProfile>div:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: #333333 !important;
  }

  #navProfile>div>.el-submenu__icon-arrow {
    display: none;
  }

  .profileSubItem i {
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }

  /* UTILITIES */
  .textLeft {
    text-align: left;
  }

  .textRight {
    text-align: right;
  }

  /* HEADER */
  .appHeader {
    padding: 0px 0px 0px 0px !important;
    z-index: 1;
    position: relative;
    height: 60px;
    background-color: #eee;
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.5);
  }

  .headerSection {
    margin: 0px 1.5% 0px 1.5%;
  }

  .headerWithBorder {
    padding-bottom: 5px;
    margin: 0px 0px 15px 0px;
    border-bottom: 1px solid #eee;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: rgb(119, 119, 119);
  }

  /* MAIN */
  .mainSection {
    margin: 20px 1.5% 20px 1.5%;
  }

  /* FOOTER */
  .appFooter {
    padding: 0px 0px 0px 0px !important;
    background-color: #58595b;
    box-shadow: 0px -1px 1px 0 rgba(0, 0, 0, 0.5);
  }

  .skyBlueFooter {
    width: 6.25%;
    height: 0.05in;
    background: #57ccf4;
    float: left;
  }

  .goldFooter {
    width: 6.25%;
    height: 0.05in;
    background: #ffc72e;
    float: left;
  }

  .magentaFooter {
    width: 6.25%;
    height: 0.05in;
    background: #f90089;
    float: left;
  }

  .greenFooter {
    width: 6.25%;
    height: 0.05in;
    background: #7cbe5f;
    float: left;
  }

  .footerSection {
    margin: 20px 1.5% 0px 1.5%;
    height: 100px;
  }

  .hiaFooterDetails {
    font-size: 13px;
    margin: 3px 0 0 0px;
    opacity: 0.7;
    color: #ffffff;
  }

  .footerLinks a {
    color: #ffffff;
    opacity: 0.7;
    text-decoration: none;
    margin: 0px 15px 0px 15px;
    vertical-align: middle;
  }

  /* HIA Date Range Picker */
  .hiaRangePicker.el-range-editor--mini.el-input__inner {
    /* height: 22px; */
  }

  .hiaRangePicker.el-range-editor.el-input__inner {
    padding: 0px 0px 0px 5px;
  }

  .el-range-separator {
    padding: 0px 2px 0px 2px !important;
  }

  .el-form--label-top .el-input--mini {
    margin-top: 8px;
  }

  .hiaTable {
    width: 100%;
  }

  /* hiatable */
  .hiaTable .table-responsive {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .hiaTable table {
    border-top: 1px solid #b4bccc;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    width: 100%;
    border-collapse: collapse;
    color: #606266;
  }

  .hiaTable .VueTables__filters-row {
    background-color: black;
  }

  .hiaTable .VuePagination__count {
    font-size: 12px;
  }

  .hiaTable table tr th {
    font-size: 11px;
    line-height: 12px;
    padding: 5px;
    background: #eee;
    text-align: left;
    vertical-align: top;
    border: 1px solid #b4bccc;
  }

  .hiaTable td {
    border-top: 1px solid #b4bccc;
    border-bottom: 1px solid #b4bccc;
  }

  .hiaTable table tr td {
    font-size: 14px;
    line-height: 12px;
    padding: 6px 4px 6px 4px;
    border: 1px solid #b4bccc;
  }

  .delete-button {
    cursor: pointer;
  }

  input[name='vf__delete'],
  input[name='vf__selected'],
  input[name='vf__Stats'] {
    display: none;
  }

  .VueTables__delete-filter-wrapper {
    width: 40px;
  }

  .hiaTable table tr:nth-child(odd) {
    background: rgba(109, 207, 246, 0.1);
  }

  .hiaTable table .VueTables__row:hover {
    background: rgba(255, 196, 12, 0.2) !important;
  }

  .hiaButton:focus {
    background: transparent;
    border-color: #dcdfe6 !important;
    color: #606266 !important;
  }

  .hiaButton.el-button--text:hover,
  .hiaButton.el-button--text:active {
    background: transparent;
    border-color: transparent !important;
    color: #005695 !important;
    border-right: 1px solid #dcdfe6 !important;
  }

  /* accordion */
  .el-collapse-item__header {
    height: 40px !important;
    padding-left: 2px;
    border-bottom: 1px solid #b4bccc;
  }

  .el-collapse-item.is-active .el-collapse-item__header {
    background-color: #eee;
  }

  .el-collapse-item__header:hover {
    background-color: #eee;
  }

  /* notification style */
  #notificationsTab .el-badge__content {
    background-color: #f56c6c !important;
    margin: 8px -12px 0px 0px;
    -webkit-animation: breathing 2s ease-out infinite normal;
    animation: breathing 2s ease-out infinite normal;
  }

  #notificationsTab .el-icon-bell {
    margin-right: 0px;
  }

  .stats .el-badge__content {
    /* background-color: #005695 !important; */
    background-color: #f4f4f5 !important;
    color: #303133 !important;
  }

  .stats .el-tag {
    background-color: transparent !important;
    color: #909399 !important;
  }

  .stats .el-tag {
    border-color: #e9e9eb !important;
  }

  @-webkit-keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }

    25% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    60% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }

  @keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    25% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    60% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  }

  /* new hiatable styles */
  .hiaTable table {
    border-top: 0px solid transparent;
  }

  .hiaTable td {
    border-top: 1px solid #ebeef5 !important;
    border-bottom: 1px solid #ebeef5 !important;
  }

  .hiaTable table thead tr th {
    background: #ffffff !important;
    border: 0px solid transparent !important;
    color: #909399;
    font-size: 12px;
    padding: 3px;
  }

  .hiaTable table tr td {
    font-size: 12px;
    line-height: 12px;
    padding: 6px 4px 6px 4px;
    border: 0px solid transparent;
  }

  .hiaTable .VueTables__filters-row input {
    font-size: 12px;
    height: 20px;
    box-sizing: border-box;
    border: 1px solid #dcdfe6;
    box-shadow: inset 0 0px 0px rgb(0 0 0 / 8%);
  }

  .hiaTable .VueTables__filters-row select {
    font-size: 12px;
    height: 20px;
    padding: 0;
  }

  .hiaTable .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0px 0px 0px;
    border-radius: 4px;
  }

  .hiaTable .pagination>li:first-child>a,
  .hiaTable .pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .hiaTable .pagination>li>a,
  .hiaTable .pagination>li>span {
    margin: 0 3px;
    font-size: 12px;
    position: relative;
    float: left;
    padding: 1px 8px;
    line-height: 22px;
    color: #606266;
    text-decoration: none;
    background-color: #f4f4f5;
    border: 0px solid transparent;
    border-radius: 2px;
    height: 22px;
    font-weight: 500;
  }

  .hiaTable .pagination>li>a.active {
    background-color: #005695;
    color: #fff;
    border-radius: 50%;
  }

  .hiaTable .VueTables__filters-row input {
    font-size: 13px;
    height: 24px;
    box-sizing: border-box;
  }

  .hiaTable .VueTables__filters-row select {
    font-size: 13px;
    height: 24px;
    padding: 0;
  }

  .hiaTable .VueTables__highlight b {
    background: #ffffe0;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 22px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  /* table pagination */

  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
  }

  .pagination>li {
    display: inline;
    cursor: pointer;
  }

  .pagination>li:first-child>a,
  .pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .pagination>.disabled>a,
  .pagination>.disabled>a:focus,
  .pagination>.disabled>a:hover,
  .pagination>.disabled>span,
  .pagination>.disabled>span:focus,
  .pagination>.disabled>span:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
  }

  .pagination>li>a,
  .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
  }

  .pagination>.active>a,
  .pagination>.active>a:focus,
  .pagination>.active>a:hover,
  .pagination>.active>span,
  .pagination>.active>span:focus,
  .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
  }

  /* Form */

  input {
    border: 1px solid #b4bccc;
    border-radius: 4px;
  }

  .el-form-item__label {
    vertical-align: middle !important;
    float: left !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #48576a !important;
    line-height: 1 !important;
    padding: 6px 0 0 !important;
    box-sizing: border-box !important;
  }

  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 8px !important;
  }

  .el-form-item--mini .el-form-item__content,
  .el-form-item--mini .el-form-item__label {
    line-height: 1 !important;
  }

  .el-input--mini .el-input__icon {
    line-height: 22px !important;
  }

  .el-form-item__error {
    position: relative !important;
  }

  .el-select {
    width: 100%;
  }

  /* all inputs */
  .el-input.is-disabled .el-input__inner,
  .el-textarea.is-disabled .el-textarea__inner {
    color: black !important;
    background-color: #eaeaea !important;
  }

  .hiaTable .VueTables__limit {
    display: none;
  }

  .hiaTable .VueTables__highlight b {
    background: #ffffe0;
  }

  /* Form */

  textarea:disabled {
    border-color: #e4e7ed;
    background-color: #eaeaea;
  }

  input {
    border: 1px solid #b4bccc;
    border-radius: 4px;
    padding: 1px 5px 1px 5px;
  }

  input:disabled {
    color: black !important;
    background-color: #eaeaea !important;
  }

  .el-form-item__label {
    vertical-align: middle !important;
    float: left !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #48576a !important;
    line-height: 1 !important;
    padding: 6px 0 0 !important;
    box-sizing: border-box !important;
  }

  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 8px !important;
  }

  .el-form-item--mini .el-form-item__content,
  .el-form-item--mini .el-form-item__label {
    line-height: 1 !important;
  }

  .el-date-editor {
    width: 100% !important;
  }

  .el-input--mini .el-input__icon {
    /* line-height: 22px !important; */
  }

  .el-form-item__error {
    position: relative !important;
  }

  .el-select {
    width: 100%;
  }

  .deleteCode ::placeholder {
    text-decoration: line-through;
  }

  .el-scrollbar__wrap {
    max-width: 900px;
  }

  .el-select-dropdown__item {
    white-space: nowrap;
    height: auto;
  }

  /* all inputs */
  .el-input--mini .el-input__inner {
    /* height: 22px !important; */
  }

  .el-select .el-select__input,
  .el-select .el-input__inner {
    /* height: fit-content !important; */
    /* min-height: 22px !important; */
  }

  .el-input.is-disabled .el-input__inner {
    color: black !important;
    background-color: #eaeaea !important;
  }

  /* center the close icon in the middle of the select */
  .el-input__suffix-inner .el-icon-circle-close {
    margin-top: 0px !important;
  }

  *,
  *:focus,
  *:hover {
    outline: none;
  }

  /* Fullscreen */
  .displayNone {
    display: none !important;
  }

  /* icons */
  .actionsHamburger {
    margin-top: 4px;
    cursor: pointer;
    font-size: 25px;
  }

  /* inpatient review */
  .codeTotalHeader {
    font-weight: normal;
    color: #005695;
  }

  /* buttons  */
  .hiaButton:hover,
  .hiaButton:active {
    background: transparent;
    border-color: #005695 !important;
    color: #005695 !important;
  }

  .hiaButton.is-disabled:hover {
    color: #c0c4cc !important;
    cursor: not-allowed !important;
    background-image: none !important;
    background-color: transparent !important;
    border-color: #ebeef5 !important;
  }

  .hiaButton:focus {
    background: transparent;
    border-color: #dcdfe6 !important;
    color: #606266 !important;
  }

  .hiaButton.el-button--text:hover,
  .hiaButton.el-button--text:active {
    background: transparent;
    border-color: transparent !important;
    color: #005695 !important;
    border-right: 1px solid #dcdfe6 !important;
  }

  /* accordion */
  .el-collapse-item__header {
    height: 40px !important;
    padding-left: 2px;
    border-bottom: 1px solid #b4bccc;
  }

  .el-collapse-item.is-active .el-collapse-item__header {
    background-color: #eee;
  }

  .el-collapse-item__header:hover {
    background-color: #eee;
  }

  /* notification style */
  #notificationsTab .el-badge__content {
    background-color: #f56c6c !important;
    margin: 8px -12px 0px 0px;
    -webkit-animation: breathing 2s ease-out infinite normal;
    animation: breathing 2s ease-out infinite normal;
  }

  #notificationsTab .el-icon-bell {
    margin-right: 0px;
  }

  .stats .el-badge__content {
    /* background-color: #005695 !important; */
    background-color: #f4f4f5 !important;
    color: #303133 !important;
  }

  .stats .el-tag {
    background-color: transparent !important;
    color: #909399 !important;
  }

  .stats .el-tag {
    border-color: #e9e9eb !important;
  }

  @-webkit-keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }

    25% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    60% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }

  @keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    25% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    60% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  }

  /* new hiatable styles */
  .hiaTable table {
    border-top: 0px solid transparent;
  }

  .hiaTable td {
    border-top: 1px solid #ebeef5 !important;
    border-bottom: 1px solid #ebeef5 !important;
  }

  .hiaTable table thead tr th {
    background: #ffffff !important;
    border: 0px solid transparent !important;
    color: #909399;
    font-size: 12px;
    padding: 3px;
  }

  .hiaTable table tr td {
    font-size: 12px;
    line-height: 12px;
    padding: 6px 4px 6px 4px;
    border: 0px solid transparent;
  }

  .hiaTable .VueTables__filters-row input {
    font-size: 12px;
    height: 20px;
    box-sizing: border-box;
    border: 1px solid #dcdfe6;
    box-shadow: inset 0 0px 0px rgb(0 0 0 / 8%);
  }

  .hiaTable .VueTables__filters-row select {
    font-size: 12px;
    height: 20px;
    padding: 0;
  }

  .hiaTable tbody tr:nth-child(odd) {
    background: #fafafa;
  }

  .hiaTable .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0px 0px 0px;
    border-radius: 4px;
  }

  .hiaTable .pagination>li:first-child>a,
  .hiaTable .pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .hiaTable .pagination>li>a,
  .hiaTable .pagination>li>span {
    margin: 0 3px;
    font-size: 12px;
    position: relative;
    float: left;
    padding: 1px 8px;
    line-height: 22px;
    color: #606266;
    text-decoration: none;
    background-color: #f4f4f5;
    border: 0px solid transparent;
    border-radius: 2px;
    height: 22px;
    font-weight: 500;
  }

  .hiaTable .pagination>li>a.active {
    background-color: #005695;
    color: #fff;
    border-radius: 50%;
  }

  .hiaTable .el-tag--mini {
    height: 16px;
    line-height: 10px;
    margin-right: 1px;
  }

  .hiaTable .el-tag--mini>div {
    margin-top: 2px;
  }

  /* tab styles */
  .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    color: #005695 !important;
  }

  .el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    color: #005695 !important;
  }

  .el-tabs__item.is-active {
    color: #005695 !important;
  }

  .el-tabs__active-bar {
    background-color: #005695 !important;
  }

  .el-tabs__item:hover {
    color: #005695 !important;
  }

  .el-tabs__item.is-disabled:hover {
    color: #c0c4cc !important;
  }

  .el-tabs__nav-wrap::after {
    background-color: transparent !important;
  }

  /* .el-button--primary {
  color: #fff;
  background-color: #005695 !important;
  border-color: #005695 !important;
} */

  /* el-table report styles */
  #facilityTable .el-table__header-wrapper thead tr th,
  #reasonTable .el-table__header-wrapper thead tr th,
  #CoderTable .el-table__header-wrapper thead tr th,
  #drgTable .el-table__header-wrapper thead tr th,
  #dxTable .el-table__header-wrapper thead tr th,
  #pxTable .el-table__header-wrapper thead tr th {
    text-align: center;
  }

  #facilityTable .el-table__body-wrapper table tbody tr td,
  #reasonTable .el-table__body-wrapper table tbody tr td,
  #CoderTable .el-table__body-wrapper table tbody tr td,
  #drgTable .el-table__body-wrapper table tbody tr td,
  #dxTable .el-table__body-wrapper table tbody tr td,
  #pxTable .el-table__body-wrapper table tbody tr td {
    text-align: right;
  }

  #facilityTable .el-table__footer-wrapper table tbody tr td,
  #reasonTable .el-table__footer-wrapper table tbody tr td,
  #CoderTable .el-table__footer-wrapper table tbody tr td,
  #drgTable .el-table__footer-wrapper table tbody tr td,
  #dxTable .el-table__footer-wrapper table tbody tr td,
  #pxTable .el-table__footer-wrapper table tbody tr td {
    text-align: right;
  }

  .textAlignLeft {
    text-align: left !important;
  }

  .textAlignCenter {
    text-align: center !important;
  }

  .negative {
    color: #f56c6c;
  }

  .negative::before {
    content: '(';
  }

  .negative::after {
    content: ')';
  }

  .el-table .cell {
    word-break: break-word !important;
  }

  .negative_td .cell {
    color: #f56c6c !important;
  }

  .negative_td .cell::before {
    content: '(';
  }

  .negative_td .cell::after {
    content: ')';
  }

  /* trucode overrides */
  .trucode .k-block,
  .trucode .k-header,
  .trucode .k-grid-header,
  .trucode .k-toolbar,
  .trucode .k-grouping-header,
  .trucode .k-pager-wrap,
  .trucode .k-button,
  .trucode .k-draghandle,
  .trucode .k-treemap-tile,
  .trucode html .km-pane-wrapper .k-header {
    background-color: #1b3c5f;
  }

  .trucode .k-header,
  .trucode .k-grid-header,
  .trucode .k-toolbar,
  .trucode .k-dropdown-wrap,
  .trucode .k-picker-wrap,
  .trucode .k-numeric-wrap,
  .trucode .k-grouping-header,
  .trucode .k-pager-wrap,
  .trucode .k-textbox,
  .trucode .k-progressbar,
  .trucode .k-draghandle,
  .trucode .k-autocomplete,
  .trucode .k-state-highlight,
  .trucode .k-tabstrip-items .k-item,
  .trucode .k-panelbar .k-tabstrip-items .k-item,
  .trucode .km-pane-wrapper>.km-pane>.km-view>.km-content {
    background-image: none;
    background-position: 50% 50%;
    background-color: #1b3c5f;
  }

  .trucode .k-tabstrip-items .k-state-active,
  .trucode .k-panelbar .k-tabstrip-items .k-state-active {
    background-color: #ffffff;
    background-image: none;
    border-color: #1b3c5f;
  }

  .trucode .k-tabstrip .k-content.k-state-active {
    background-color: #ffffff;
    color: #606266;
  }

  .trucode .pane-text {
    border-color: #1b3c5f;
    background-color: #fff;
  }

  .trucode .accordion-group {
    border-color: #1b3c5f;
  }

  .trucode .research-pane .heading {
    color: #1b3c5f;
  }

  div.el-input--mini>input[style*='height: 28px;'] {
    height: 22px !important;
  }
</style>