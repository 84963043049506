<template>
  <div class="home" style="text-align: left;">
    <el-card>
      <h2>Welcome to Atom Audit</h2>
      <p>
        Atom Audit is a coding review application that allows auditors to perform detailed reviews of medical coding. For each record, reviewers can view original coding and
        make specific diagnosis and procedure code additions, deletions and revisions. Reviewers can then document the review action taken, the location of supporting
        documentation and any applicable coding references.
      </p>
      <p>
        After records are reviewed, Atom Audit reports can be viewed and exported to show accuracy rates, financial impacts and record worksheets to support ongoing coder
        education efforts.
      </p>

      <el-collapse class="infoAccordion">
        <el-collapse-item title="Projects" name="1">
          <div>Auditing is organized by <router-link to="/projects">projects</router-link>. A project has a facility, start date and end date. Once a project is initially
            created, records to be audited can be added to the project directly or imported from a spreadsheet. Under a project, the list of records is shown and reviewers can
            open each record and perform their audits.</div>
        </el-collapse-item>
        <el-collapse-item title="Managed Lists" name="2">
          <div>
            Prior to starting any review projects, the system should be configured under the <router-link to="/managed-lists">Managed Lists</router-link> menu option. The
            following lists can be configured:
            <ul>
              <li><strong>Organization</strong> - Under Organization settings, you can upload a logo image for your organization, which will be used as the header image for
                reports. You can also set your organization name which will be used as part of the file name in printable reports.</li>
              <li><strong>Facilities</strong> - At least one facility is required. Each project will have a facility assigned to it, which supports reporting results at the
                facility level.</li>
              <li><strong>Project Tags</strong> - Project Tags are an optional way of classifying review projects. For example, the Project Tag "Quarterly" could be used to
                denote a project that is a quarterly audit.</li>
              <li><strong>Payors</strong> - Payors and Payor Details are setup to assign records to specific payors.</li>
              <li><strong>Coders</strong> - Coders are setup to assign records to specific coders, which supports reporting results at the coder level.</li>
              <li><strong>Providers</strong> - Providers are setup to assign records to specific providers.</li>
              <li><strong>Outpatient Patient Types</strong> - Outpatient Patient Types are used to organize Outpatient Records, for example Observation, Ancillary or Emergency
                Department. Patient Type is a required field for Outpatient records, so at least one Patient Type is needed if performing Outpatient Reviews.</li>
              <li><strong>Reason for Changes</strong> - When an auditor discovers a coding issue and makes recommended coding changes, a Reason for Change can be used to
                categorize the change. For example, the reason for change "Principal Dx" could be used to categorize all records with a Principal Dx change.</li>
              <li><strong>Code Types</strong> - Code Types are used to specify the role / job title / department of the person who performed the original coding at the
                individual code level. For example, a Code Type of HIM Assigned could be used to specify that the HIM department performed the original coding. Code Types are
                used for Outpatient and Provider Reviews, but not for Inpatient Reviews. Code Type is a required field for Outpatient and Provider codes, so at least one
                Patient Type is needed if performing Outpatient or Provider Reviews.</li>
              <li><strong>Service Lines</strong> - Service lines are another way of categorizing records for reporting purposes across organizational entities.</li>
              <li><strong>Recommendation Library</strong> - The Recommendation Library is a set of predefined audit recommendations that can be selected and brought into a
                reviewer's documented recommendations on a specific record.</li>
            </ul>
          </div>
        </el-collapse-item>
        <el-collapse-item title="Auditing a Record" name="3">
          <p>
            When a reviewer audits a record, they can view the original coding and make coding recommendations for each code on the record. The following is an example of the
            Diagnosis grid on a record. Original Coding is on the left side and any recommendations are made on the right side.
          </p>
          <p>Any recommendations made will affect the coder's accuracy and will be reflected in the audit reports, unless the Exclude (E icon) button is used to exclude the
            recommendation from the statistics.</p>

          <img style="padding: 20px 3px 20px 3px" src="static/images/dx-screenshot.png" />

          <h3 style="font-style: italic;">Recommendation Write-up</h3>
          <p>Once the coding recommendations are complete, the reviewer can document the findings in the Action Taken, Location of Supporting Documentation and Coding
            Reference rich text fields.</p>

          <img style="padding: 20px 3px 20px 3px" src="static/images/recommendations-screenshot.png" />
        </el-collapse-item>
        <el-collapse-item title="Coder Responses" name="4">
          <p>After auditing a record and marking the record Review Status as Completed, coders can view the audit results and respond with their agreement/disagreement of the
            findings. For this process to work, the coder must be setup with a Atom Audit user account with the role of Coder and the user account must be linked to the coder.
          </p>
          <p>The <router-link to="/my-projects">My Projects</router-link> menu option will list all the completed records for the logged in coder. From there, a coder can open
            individual records and provide their responses. Coders can agree or disagree with the coding changes and provide a comment or simply request a further response
            from the auditor.</p>
          <img src="static/images/CoderResponsesExample.png" />

          <p>Once a coder has provided a response, the auditor can then decide whether to uphold their original recommendation or change their original recommendation. The
            Final Outcome can be specified by the auditor in the Coder Responses section of the audit record along with any supporting comments or documentation.</p>

          <img src="static/images/CoderResponsesExample2.png" />
        </el-collapse-item>
        <el-collapse-item title="Audit Reports" name="5">
          <p>After record auditing is complete, the results can be analyzed via <router-link to="/reports/filtered-reports">Reports</router-link>. To produce a report, first
            filter and
            identify the needed records, then view the online dashboards or create a printable report.</p>

          <img src="static/images/printable-report-screenshot.png" />
        </el-collapse-item>
      </el-collapse>
      <h2>Need Help?</h2>
      Email us at <a href="mailto:support@atom-audit.com">support@atom-audit.com</a> for help.
    </el-card>

    <el-card v-loading="loading" element-loading-spinner="atom-audit-loader" class="blogFeed" style="margin-top: 40px;" shadow="always">
      <div slot="header" style="width: 100%">
        <el-row>
          <el-col :span="8">
            Coding Advice and Resources
          </el-col>
          <el-col :span="8">
            <el-input style="margin: 6px 0px 0px 0px;" placeholder="" v-model="blogSearch" prefix-icon="el-icon-search">
              <el-button slot="append" @click.stop="handleBlogSearch()">Blog Search</el-button>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div style="float: right;">
              <a href="https://www.facebook.com/HIAcode/" target="_blank"><i class="mdi mdi-facebook socialButton"></i></a>
              <a href="https://twitter.com/HIACode" target="_blank"><i class="mdi mdi-twitter socialButton"></i></a>
              <a href="https://www.instagram.com/hiacode/" target="_blank"><i class="mdi mdi-instagram socialButton"></i></a>
              <a href="https://www.linkedin.com/company/health-information-associates-inc-" target="_blank"><i class="mdi mdi-linkedin socialButton"></i></a>
              <a href="https://www.hiacode.com/hialearn-course-catalog/" target="_blank"><i class="mdi mdi-school socialButton"></i></a>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24">
          <template v-if="!loading">
            <article v-for="post in blogFeed.results" :key="post.id" id="post-10504" style="margin-bottom: 40px;">
              <el-row :gutter="20">
                <el-col :span="7" style="max-width: 330px;">
                  <a :href="post.link" target="_blank"> <img width="302px" :title="post.name" :src="getFeaturedImageSrc(post)" /> </a>
                </el-col>
                <el-col :span="17">
                  <h2 style="margin-top: 0px;"><a :href="post.url" v-html="post.name" target="_blank"></a></h2>
                  <p>
                    <span>{{ format(parseISO(post.publishDate), 'MMM dd, yyyy') }}</span>
                    <!-- <a v-for="(tag, index) in getTags(post)" :key="tag.link" :href="tag.link" v-html="`${tag.name}${index === getTags(post).length - 1 ? '' : ', '}`"
                      target="_blank"></a> -->
                  </p>
                  <div>
                    <p v-html="post.metaDescription"></p>
                    <a class="el-button el-button--primary el-button--mini is-round" :href="post.url" target="_blank">read more</a>
                  </div>
                </el-col>
              </el-row>
            </article>
            <span @click="fetchEntriesByOffset(postOffset + 5)" class="postNavigationButton"><i class="mdi mdi-chevron-double-left"></i>Older Entries</span>

            <span v-if="postOffset > 0" style="float: right;" @click="fetchEntriesByOffset(postOffset - 5)" class="postNavigationButton">Next Entries <i
                class="mdi mdi-chevron-double-right"></i></span>
          </template>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'Home',
    data: function () {
      return {
        blogSearch: '',
        format: format,
        parseISO: parseISO,
        loading: true,
        postOffset: 0,
        showMore: false
      }
    },
    components: {},
    methods: {
      ...mapActions('home/', ['GET_BLOG_FEED']),
      getFeaturedImageSrc(post) {
        if (post.featuredImage) {
          return post.featuredImage
        }
      },
      getTags(post) {
        if (post._embedded['wp:term']) {
          if (post._embedded['wp:term'][0]) {
            return post._embedded['wp:term'][0]
          }
        }
        return []
      },
      fetchEntriesByOffset(postOffset) {
        this.loading = true
        this.postOffset = postOffset
        this.GET_BLOG_FEED(this.postOffset).then(() => {
          this.loading = false
        })
      },
      handleBlogSearch() {
        if (this.blogSearch) {
          window.open(`https://hiacode.com/hs-search-results?term=${encodeURI(this.blogSearch)}`, '_blank')
        }
      }
    },
    created: function () {
      this.GET_BLOG_FEED(this.postOffset).then(() => {
        this.loading = false
      })
    },
    computed: {
      ...mapState('home/', ['blogFeed'])
    }
  }
</script>

<style scoped>
  h2 {
    margin-top: 30px;
  }

  img {
    border: 1px solid #b4bccc;
    border-radius: 5px;
    margin-left: 1rem;
    margin-top: 20px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }

  >>>.blogFeed {
    min-height: 80vh;
  }

  >>>.blogFeed .el-card__header {
    padding: 20px 20px;
    font-size: 1.5em;
    font-weight: 400;
  }

  .actionCard {
    /* background-color: #eeeeee; */
  }

  .socialButton {
    font-size: 20px;
    margin: 0px 8px 0px 0px;
    background: transparent;
    color: black;
    opacity: 0.8;
  }

  .socialButton:hover {
    color: #005695;
  }

  .actionCard:hover {
    border: 1px solid #005695;
  }

  .postNavigationButton {
    color: #005695;
    cursor: pointer;
  }

  .infoAccordion {
    margin: 20px 0px 0px 0px;
  }

  >>>.infoAccordion .el-collapse-item__header {
    font-size: 16px;
    height: 40px;
  }

  >>>.infoAccordion .el-collapse-item__content {
    font-size: 15px;
    margin: 10px 10px 0px 10px;
  }
</style>