import { updateField } from 'vuex-map-fields'

const SET_AVOIDABLEDAYREASONS_LOADING = (state, payload) => {
  state.avoidableDayReasonsListLoading = payload
}

const SET_AVOIDABLEDAYREASON = (state, payload) => {
  state.avoidableDayReasonsList = payload
}

const UPDATE_AVOIDABLEDAYREASON = (state, payload) => {
  if (payload) {
    const index = state.avoidableDayReasonsList.findIndex(f => f.avoidableDayReasonId === payload.avoidableDayReasonId)
    state.avoidableDayReasonsList.splice(index, 1, payload)
  }
}

const PUSH_AVOIDABLEDAYREASON = (state, payload) => {
  state.avoidableDayReasonsList.push(payload)
}

const SPLICE_AVOIDABLEDAYREASON = (state, avoidableDayReasonId) => {
  const index = state.avoidableDayReasonsList.findIndex(x => x.avoidableDayReasonId === avoidableDayReasonId)
  state.avoidableDayReasonsList.splice(index, 1)
}

export default {
  updateField,
  SET_AVOIDABLEDAYREASON,
  UPDATE_AVOIDABLEDAYREASON,
  PUSH_AVOIDABLEDAYREASON,
  SET_AVOIDABLEDAYREASONS_LOADING,
  SPLICE_AVOIDABLEDAYREASON
}
