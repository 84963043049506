
import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
    pullListBuilds: [],
    pullListBuild: {},
    targetTemplates: {}
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
