import axios from '@/utilities/axios-global'

const ADD_PROJECT = async (context, payload) => {
  try {
    const review = {
      clientId: payload.clientNumber,
      reviewStartDate: payload.startDate,
      reviewEndDate: payload.endDate
    }
    payload.review = review
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/projects/`, payload)
    const newProjectID = response.data.id
    const getDate = context.rootGetters['globals/GET_DATERANGE']
    context.commit('SET_NEW_PROJECT_ID', newProjectID)
    context.dispatch('LOAD_PROJECT_LIST', getDate())
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_PROJECT_LIST = async (context, param) => {
  try {
    context.commit('SET_PROJECT_LIST_LOADING', true)
    if ('isCoding' in param) {
      param = {
        startDate: param.dateRange.startDate,
        endDate: param.dateRange.endDate,
        isCoding: true
      }
    } else {
      param.isCoding = false
    }
    // let url = process.env.VUE_APP_API_BASE_URL + '/api/projects/reviews'
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/projects/list?startDate=${param.startDate}&endDate=${param.endDate}&isCoding=${param.isCoding}`)
    context.commit('SET_PROJECT_LIST_LOADING', false)
    context.commit('SET_PROJECT_LIST', response.data)
    context.commit('SET_PROJECT_LIST_BACKUP', JSON.parse(JSON.stringify(response.data)))
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_LIST', [])
    context.commit('SET_PROJECT_LIST_BACKUP', [])
    context.commit('SET_PROJECT_LIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const LOAD_PRO_ADVANCED_LIST = async (context, payload) => {
  try {
    context.commit('SET_PROJECT_LIST_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/projects/provider-advanced-list?startDate=${payload.dateRange.startDate}&endDate=${payload.dateRange.endDate}&codeType=${payload.codeType}&isCoding=${payload.isCoding ? payload.isCoding : false}`)
    const flattened = response.data.map(x => {
      return Object.assign(x.project, x.stats)
    })

    context.commit('SET_PROJECT_LIST_LOADING', false)
    context.commit('SET_PROJECT_LIST', flattened)
    context.commit('SET_PROJECT_LIST_BACKUP', JSON.parse(JSON.stringify(flattened)))
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_LIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const LOAD_OP_ADVANCED_LIST = async (context, payload) => {
  try {
    context.commit('SET_PROJECT_LIST_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/projects/outpatient-advanced-list?startDate=${payload.dateRange.startDate}&endDate=${payload.dateRange.endDate}&codeType=${payload.codeType}&opProjectType=${payload.opProjectType}&isCoding=${payload.isCoding ? payload.isCoding : false}`)
    const flattened = response.data.map(x => {
      return Object.assign(x.project, x.stats)
    })

    context.commit('SET_PROJECT_LIST_LOADING', false)
    context.commit('SET_PROJECT_LIST', flattened)
    context.commit('SET_PROJECT_LIST_BACKUP', JSON.parse(JSON.stringify(flattened)))
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_LIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const LOAD_IP_ADVANCED_LIST = async (context, payload) => {
  try {
    context.commit('SET_PROJECT_LIST_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/projects/inpatient-advanced-list?startDate=${payload.dateRange.startDate}&endDate=${payload.dateRange.endDate}&isCoding=${payload.isCoding ? payload.isCoding : false}`)
    const flattened = response.data.map(x => {
      return Object.assign(x.project, x.stats)
    })

    context.commit('SET_PROJECT_LIST_LOADING', false)
    context.commit('SET_PROJECT_LIST', flattened)
    context.commit('SET_PROJECT_LIST_BACKUP', JSON.parse(JSON.stringify(flattened)))
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_LIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const LOAD_CALENDAR_PROJECT_LIST = async (context, daterange) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/projects/list?startDate=${daterange[0]}&endDate=${daterange[1]}`)
    context.commit('SET_CALENDAR_PROJECT_LIST', response.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_PROJECT_DASHBOARD_IDS_STATS = async (context, payload) => {
  let statusFiltersParam = ''
  let codeTypeFiltersParam = ''
  if (payload.statusFilters) {
    statusFiltersParam = payload.statusFilters.map(x => `reviewStatusIds=${x}`).join('&')
  }

  if (payload.codeTypeFilters) {
    codeTypeFiltersParam = '&' + payload.codeTypeFilters.map(x => `codeTypes=${x}`).join('&')
  }

  const statsPayload = {
    ascending: 1,
    byColumn: 1,
    globalSearch: '',
    limit: 25,
    orderBy: 'patientNumber',
    page: 1,
    projectID: payload.id,
    codeTypes: payload.codeTypeFilters,
    query: {}
  }

  if (payload.projectTypeName === 'Outpatient' || payload.projectTypeName === 'Ambulatory') {
    try {
      const idsResponse = await axios.get(`${context.rootState.user.baseApiUrl}/projects/${payload.id}/ascTableIds?${statusFiltersParam}${codeTypeFiltersParam}&isCoding=${payload.isCoding ? payload.isCoding : false}`)
      context.commit('SET_PROJECT_ASCTABLEIDS', idsResponse.data)
      if (!payload.isCoding) {
        await context.dispatch('outpatientReviews/GET_OPLIST_PROJECT_STATS', statsPayload, { root: true })
      }
    } catch (e) {
      console.log('Error getting project data', e)
      context.commit('SET_PROJECT_ASCTABLEIDS', '')
      throw new Error(e?.response?.data?.message)
    }
  }

  if (payload.projectTypeName === 'Inpatient') {
    try {
      const idsResponse = await axios.get(`${context.rootState.user.baseApiUrl}/projects/${payload.id}/drgTableIds?${statusFiltersParam}&isCoding=${payload.isCoding ? payload.isCoding : false} `)
      context.commit('SET_PROJECT_DRGTABLEIDS', idsResponse.data)
      if (!payload.isCoding) {
        await context.dispatch('inpatientReviews/GET_IPLIST_PROJECT_STATS', statsPayload, { root: true })
      }
    } catch (e) {
      console.log('Error getting project data', e)
      context.commit('SET_PROJECT_DRGTABLEIDS', '')
      throw new Error(e?.response?.data?.message)
    }
  }

  if (payload.projectTypeName === 'Professional Fee') {
    try {
      const endpoint = `${context.rootState.user.baseApiUrl}/projects/${payload.id}/prorecordids?${statusFiltersParam}${codeTypeFiltersParam}&isCoding=${payload.isCoding ? payload.isCoding : false}`
      const idsResponse = await axios.get(endpoint)
      context.commit('SET_PROJECT_PRORECORDIDS', idsResponse.data)
      if (!payload.isCoding) {
        await context.dispatch('proReviews/GET_PROLIST_PROJECT_STATS', statsPayload, { root: true })
      }
    } catch (e) {
      console.log('Error getting project data', e)
      context.commit('SET_PROJECT_PRORECORDIDS', '')
      throw new Error(e?.response?.data?.message)
    }
  }
}

const LOAD_PROJECT = async (context, payload) => {
  try {
    context.commit('SET_PROJECTPK', payload.id)
    context.commit('SET_PROJECT_LOADING', true)
    // first fetch the project
    const projectResponse = await axios.get(`${context.rootState.user.baseApiUrl}/projects/${payload.id}`)
    const project = projectResponse.data
    // then fetch the IDs and stats for the applicable project type
    payload.projectTypeName = project.projectTypeName
    await GET_PROJECT_DASHBOARD_IDS_STATS(context, payload).catch(err => {
      console.log(err)
    })

    context.commit('SET_PROJECT', project)
    context.commit('SET_PROJECT_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_LOADING', false)
    throw new Error(err?.response?.data?.message)
  }
}

const UPDATE_PROJECT = async (context, project) => {
  try {
    context.commit('SET_PROJECT_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/projects/${project.id}`, project)
    context.commit('SET_PROJECT', response.data)
    context.commit('SET_PROJECT_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_PROJECT_TASK = async (context, payload) => {
  try {
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/projects/tasks/${payload.projectsTaskId}`, payload)
    context.commit('SET_PROJECT_TASK_ROW', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_PROJECTS_PAGINATED = async (context, data) => {
  const response = await axios.post(`${context.rootState.user.baseApiUrl}/projects/paginated`, data)
  return response.data
}

const DELETE_PROJECT = async (context, project) => {
  try {
    context.commit('SET_PROJECT_LIST_LOADING', true)
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/projects/${project.projectID}`)
    return response.data
  } catch (e) {
    context.commit('SET_PROJECT_LIST_LOADING', false)
    throw new Error(e)
  }
}

export default {
  ADD_PROJECT,
  LOAD_PROJECT_LIST,
  LOAD_CALENDAR_PROJECT_LIST,
  LOAD_PROJECT,
  UPDATE_PROJECT,
  LOAD_PROJECTS_PAGINATED,
  DELETE_PROJECT,
  GET_PROJECT_DASHBOARD_IDS_STATS,
  LOAD_PRO_ADVANCED_LIST,
  LOAD_IP_ADVANCED_LIST,
  LOAD_OP_ADVANCED_LIST,
  PUT_PROJECT_TASK
}
