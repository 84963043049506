
const SET_BLOG_FEED = (state, payload) => {
  state.blogFeed = payload
}

const SET_CLIENT_NAV_SELECTION = (state, payload) => {
  state.clientNavSelection = payload
}

export default {
  SET_BLOG_FEED,
  SET_CLIENT_NAV_SELECTION
}
