
import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
  reportQueries: [],
  defaultQueries: [],
  usersList: [],
  reportQueriesLoading: false
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
