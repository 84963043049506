import { updateField } from 'vuex-map-fields'

const SET_SHOWNOTIFICATIONS = (state, payload) => {
  state.showNotifications = payload
}

const UPDATE_USER_NOTIFICATIONS = (state, payload) => {
  const notification = state.notificationData.find(f => f.assigneeId === payload.id)
  if (notification) {
    notification.acknowledged = payload.acknowledged
  }
}

const DISMISSACTIVATE_USER_NOTIFICATIONS = (state, payload) => {
  const notification = state.notificationData.find(f => f.assigneeId === payload.id)
  if (notification) {
    notification.deleted = payload.deleted
  }
}

const DELETE_ADMIN_NOTIFICATIONS = (state, id) => {
  const index = state.adminNotificationList.findIndex(f => f.id === id)
  state.adminNotificationList.splice(index, 1)
}

const SET_ADMIN_NOTIFICATIONS = (state, payload) => {
  state.adminNotificationList = payload
}

const PUSH_ADMIN_NOTIFICATIONS = (state, payload) => {
  state.adminNotificationList.push(payload)
}

const SET_ADMIN_NOTIFICATION = (state, payload) => {
  state.adminNotification = payload
}

const CLEAR_ADMIN_NOTIFICATION_AUDIENCE = (state, payload) => {
  state.adminNotification.audience = payload
}

const SET_USER_NOTIFICATIONS = (state, payload) => {
  payload.forEach(e => {
    e.media = JSON.parse(e.media)
    e.links = JSON.parse(e.links)
  })
  state.notificationData = payload
}

const SET_NOTIFICATIONSLOADING = (state, payload) => {
  state.notificationsLoading = payload
}

export default {
  updateField,
  SET_SHOWNOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS,
  SET_ADMIN_NOTIFICATIONS,
  DELETE_ADMIN_NOTIFICATIONS,
  PUSH_ADMIN_NOTIFICATIONS,
  SET_ADMIN_NOTIFICATION,
  CLEAR_ADMIN_NOTIFICATION_AUDIENCE,
  SET_USER_NOTIFICATIONS,
  DISMISSACTIVATE_USER_NOTIFICATIONS,
  SET_NOTIFICATIONSLOADING
}
