import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'
import NotAuthorized from '@/views/NotAuthorized.vue'
import axios from '@/utilities/axios-global'
import store from '@/store/index'
import dateHelper from '@/mixins/date-helper'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: () => import(/* webpackChunkName: "about" */ '../views/About.vue') },
  { path: '/reviews/reports/inpatient/worksheets', name: 'inpatientWorksheets', component: () => import(/* webpackChunkName: "review" */ '@/views/reviews/inpatient/worksheets/InpatientWorksheetsReport.vue') },
  { path: '/reviews/reports/pro/worksheets', name: 'proWorksheets', component: () => import(/* webpackChunkName: "review" */ '@/views/reviews/pro/worksheets/ProWorksheetsReport.vue') },
  { path: '/reviews/reports/outpatient/worksheets', name: 'outpatientWorksheets', component: () => import(/* webpackChunkName: "review" */ '@/views/reviews/outpatient/worksheets/OutpatientWorksheetsReport.vue') },
  { path: '/reviews/inpatient/:drgTableId', name: 'inpatientReview', component: () => import(/* webpackChunkName: "review" */ '@/views/reviews/inpatient/InpatientReview.vue') },
  { path: '/reviews/recommendation-library/:id', component: () => import(/* webpackChunkName: "review" */ '@/views/reviews/recommendationLibrary/RecommendationLibrary') },
  { path: '/reviews/pro/:proRecordId', name: 'proReview', component: () => import(/* webpackChunkName: "review" */ '@/views/reviews/pro/proReview.vue') },
  { path: '/reviews/outpatient/:ascTableId', name: 'outpatientReview', component: () => import(/* webpackChunkName: "review" */ '@/views/reviews/outpatient/OutpatientReview.vue') },
  { path: '/user-admin', name: 'userAdminList', component: () => import(/* webpackChunkName: "userAdmin" */ '@/views/UserAdmin/UserAdminList.vue') },
  { path: '/user-admin/:id', name: 'userAdmin', component: () => import(/* webpackChunkName: "userAdmin" */ '@/views/UserAdmin/UserAdmin.vue') },
  { path: '/managed-lists', name: 'managedLists', component: () => import(/* webpackChunkName: "managedLists" */ '@/views/managedLists/managedLists.vue') },
  { path: '/managed-lists/setup', name: 'tenantSetup', component: () => import(/* webpackChunkName: "managedLists" */ '@/views/managedLists/tenantSetup.vue') },
  { path: '/managed-lists/:slug', name: 'managedLists', component: () => import(/* webpackChunkName: "managedLists" */ '@/views/managedLists/managedLists.vue') },
  { path: '/projects', name: 'projectList', component: () => import(/* webpackChunkName: "projectList" */ '@/views/projects/projectList.vue') },
  { path: '/projects/:id', name: 'project', component: () => import(/* webpackChunkName: "projectList" */ '@/views/projects/project.vue') },
  { path: '/trucode/codebooks', component: () => import(/* webpackChunkName: "trucode" */ '@/components/controls/truCode/codebooks/CodeBooks') },
  { path: '/trucode/references', component: () => import(/* webpackChunkName: "trucode" */ '@/components/controls/truCode/references') },
  { path: '/reports/filtered-reports', component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/reportLanding.vue') },
  { path: '/dashboard', component: () => import(/* webpackChunkName: "dashboard" */ '@/views/reports/dashboards.vue') },
  { path: '/reports/inpatient-audit-report', component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/PrintableInpatientAuditReport.vue') },
  { path: '/reports/pro-audit-report', component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/PrintableProAuditReport.vue') },
  { path: '/reports/outpatient-audit-report', component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/PrintableOutpatientAuditReport.vue') },
  { path: '/reports/auditor-metrics', component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/auditorMetrics.vue') },
  { path: '/coder', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/CoderHome.vue') },
  { path: '/coder/inpatient/:drgTableId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/inpatient/coderInpatientReviewPage.vue') },
  { path: '/coder/inpatient/worksheet/:drgTableId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/inpatient/worksheets/CoderWorksheetsReport.vue') },
  { path: '/coder/pro/:proRecordId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/pro/coderProReviewPage.vue') },
  { path: '/coder/pro/worksheet/:proRecordId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/pro/worksheets/CoderWorksheetsReport.vue') },
  { path: '/coder/outpatient/:ascTableId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/outpatient/coderOutpatientReviewPage.vue') },
  { path: '/coder/outpatient/worksheet/:ascTableId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/outpatient/worksheets/CoderWorksheetsReport.vue') },
  { path: '/reports/ambulatory-audit-report', component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/PrintableASCAuditReport.vue') },
  { path: '/my-profile', component: () => import(/* webpackChunkName: "userProfile" */ '@/views/userProfile.vue') },
  { path: '/reviews/pulllist-builds', name: 'pullListBuilds', component: () => import(/* webpackChunkName: "superAdmin" */ '@/views/reviews/pulllists/PullListBuilds.vue') },
  { path: '/reviews/pulllist-builds/:id', name: 'pullListBuild', component: () => import(/* webpackChunkName: "superAdmin" */ '@/views/reviews/pulllists/PullListBuild.vue') },
  { path: '/not-authorized', component: NotAuthorized },
  { path: '*', component: NotFound }, // fall back route will show a Page Not Found component,
  { path: '/my-projects', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/coderProjectList.vue') },
  { path: '/my-projects/:id', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/coderProject.vue') },
  { path: '/my-projects/inpatient/:drgTableId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/inpatient/coderInpatientReviewPage.vue') },
  { path: '/my-projects/inpatient/worksheet/:drgTableId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/inpatient/worksheets/CoderWorksheetsReport.vue') },
  { path: '/my-projects/pro/:proRecordId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/pro/coderProReviewPage.vue') },
  { path: '/my-projects/pro/worksheet/:proRecordId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/pro/worksheets/CoderWorksheetsReport.vue') },
  { path: '/my-projects/outpatient/:ascTableId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/outpatient/coderOutpatientReviewPage.vue') },
  { path: '/my-projects/outpatient/worksheet/:ascTableId', component: () => import(/* webpackChunkName: "coder" */ '@/views/coder/outpatient/worksheets/CoderWorksheetsReport.vue') },
  { path: '/reports/pro-risk-analysis', component: () => import(/* webpackChunkName: "coder" */ '@/views/riskAnalysis/ProRiskAnalysis3.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // move on to route
  next()
  // check build number on every request
  axios
    .get(`${store.state.user.baseApiUrl}/utilities/version`)
    .then(response => {
      try {
        if (response.data) {
          if (window.buildNumber !== undefined && window.buildNumber !== response.data.buildNumber && process.env.NODE_ENV !== 'development') {
            // set new build number and show alert
            window.buildNumber = response.data.buildNumber
            store.commit('globals/SET_HELIXUPDATEMESSAGE', true)
          }
        }
      } catch (e) {}
    })
    .catch(error => {
      console.log(error)
    })

  // check notifications on every request
  const dateRange = dateHelper.methods.newDateRange(-91, 0)
  const params = {
    startDate: dateRange[0],
    endDate: dateRange[1],
    userId: store.state.user.currentTenantUserId
  }
  store.dispatch('notifications/GET_USER_NOTIFICATIONS', params)
})

export default router
