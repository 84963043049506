const GET_DATERANGE = state => () => {
  const params = {
    startDate: state.dateRange[0],
    endDate: state.dateRange[1]
  }
  return params
}

export default {
  GET_DATERANGE
}
