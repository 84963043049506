import axios from '@/utilities/axios-global'

const LOAD_ACCOUNTS = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/inpatient/paginatedall`, payload)
    const wrapper = response.data
    return wrapper
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_AUDITOR_DASHBOARD = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reports/dashboard/auditordashboard`, payload)
    context.commit('SET_AUDITOR_DASHBOARD', response.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_AUDITOR_DASHBOARD_USERS = async (context) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reports/dashboard/auditordashboardusers`)
    context.commit('SET_AUDITOR_DASHBOARD_USERS', response.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  LOAD_ACCOUNTS,
  LOAD_AUDITOR_DASHBOARD,
  GET_AUDITOR_DASHBOARD_USERS
}
