import axios from '@/utilities/axios-global'

export function GET_USERS(context, payload) {
  return new Promise((resolve, reject) => {
    axios.get(context.rootState.user.baseApiUrl + '/useradmin').then(response => {
      // axios.post(context.rootState.user.baseApiUrl + '/useradmin', payload).then(response => {
      context.commit('SET_USERS', response.data)
      resolve()
    }, error => {
      console.log(error)
      reject(error)
    })
  })
}

export function GET_USER(context, id) {
  return new Promise((resolve, reject) => {
    const url = context.rootState.user.baseApiUrl + `/useradmin/${id}`
    axios.get(url).then((response) => {
      context.commit('SET_USER', response.data)
      resolve(true)
    }, (err) => {
      console.log(err)
      reject(err)
    })
  })
}

const POST_USER = async (context, payload) => {
  try {
    const response = await axios.post(context.rootState.user.baseApiUrl + `/useradmin/${payload.email}`, payload)
    context.commit('SET_USER', response.data)
    return response.data
  } catch (err) {
    throw new Error(err.response.data.message)
  }
}

const PUT_USER = (context, user) => {
  return new Promise((resolve, reject) => {
    let updateEmailURL = ''
    if (user.updateEmail) {
      updateEmailURL = `?updateEmail=${user.updateEmail}`
    }
    const url = context.rootState.user.baseApiUrl + `/useradmin/${user.userId}${updateEmailURL}`
    axios.put(url, user).then(() => {
      resolve(true)
    }, error => {
      console.log(error)
      reject(error)
    })
  })
}

const PUT_USER_ACTIVE = (context, user) => {
  return new Promise((resolve, reject) => {
    const url = context.rootState.user.baseApiUrl + `/useradmin/${user.userId}/status?active=${user.userTenantActive}`
    axios.put(url, user).then(() => {
      resolve(true)
    }, error => {
      console.log(error)
      reject(error)
    })
  })
}

export function GET_PW_RESET_TICKET(context, auth0UserId) {
  return new Promise((resolve, reject) => {
    const url = context.rootState.user.baseApiUrl + '/useradmin/pw-reset-ticket?auth0UserId=' + auth0UserId
    axios.get(url).then((response) => {
      context.commit('SET_PW_RESET_TICKET', response.data.ticket)
      resolve(true)
    }, (err) => {
      console.log(err)
      reject(err)
    })
  })
}

const POST_PW_RESET_EMAIL = (context, user) => {
  return new Promise((resolve, reject) => {
    const url = context.rootState.user.baseApiUrl + '/useradmin/pw-reset-email?email=' + user.email
    axios.post(url, user).then((response) => {
      resolve(response.data)
    }, error => {
      console.log(error)
      reject(error)
    })
  })
}

export function GET_ROLES(context, id) {
  return new Promise((resolve, reject) => {
    const url = context.rootState.user.baseApiUrl + '/useradmin/roles'
    axios.get(url).then((response) => {
      context.commit('SET_ROLES', response.data)
      resolve(true)
    }, (err) => {
      console.log(err)
      reject(err)
    })
  })
}

// const POST_PRO_TEMPLATE_MULTIPLE = async (context, list) => {
  const POST_USER_ROLE = async (context, payload) => {
  try {
    const url = context.rootState.user.baseApiUrl + `/useradmin/${payload.userId}/usersroles`
    const response = await axios.post(url, payload)
    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export function DELETE_USER_ROLE(context, payload) {
  return new Promise((resolve, reject) => {
    const url = context.rootState.user.baseApiUrl + `/useradmin/${payload.userId}/usersroles/${payload.roleId}`
    axios.delete(url).then((response) => {
      resolve(response.data)
    }, (err) => {
      console.log(err)
      reject(err.response.data)
    })
  })
}

export function GET_LICENSE(context, payload) {
  return new Promise((resolve, reject) => {
    axios.get(context.rootState.user.baseApiUrl + '/useradmin/userCount').then(response => {
      // axios.post(context.rootState.user.baseApiUrl + '/useradmin', payload).then(response => {
      context.commit('SET_LICENSE', response.data)
      resolve()
    }, error => {
      console.log(error)
      reject(error)
    })
  })
}

export default {
  GET_USERS,
  GET_USER,
  PUT_USER,
  PUT_USER_ACTIVE,
  GET_PW_RESET_TICKET,
  POST_PW_RESET_EMAIL,
  GET_ROLES,
  POST_USER_ROLE,
  POST_USER,
  DELETE_USER_ROLE,
  GET_LICENSE
}
