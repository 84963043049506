import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  inpatientList: [],
  inpatientReview: [],
  ipWorksheet: {},
  responseRow: {},
  responseDRG: null,
  responsePatStat: null,
  responsePOO: null,
  proList: [],
  proReview: [],
  proWorksheet: {},
  outpatientList: [],
  outpatientReview: [],
  opWorksheet: {},
  ipListStatistics: [],
  opListStatistics: [],
  proListStatistics: [],
  ambListStatistics: []
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
