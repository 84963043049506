import { updateField } from 'vuex-map-fields'

const SET_REPORTQUERIES = (state, payload) => {
  state.reportQueries = payload
}

const SET_REPORTQUERIES_USERS_LIST = (state, payload) => {
  state.usersList = payload
}

const PUSH_REPORTQUERY = (state, payload) => {
  state.reportQueries.push(payload)
}

const UPDATE_REPORTQUERY = (state, payload) => {
  if (payload) {
    const index = state.reportQueries.findIndex(f => f.reportQueryId === payload.reportQueryId)
    state.reportQueries.splice(index, 1, payload)
  }
}

const SPLICE_REPORTQUERY = (state, payload) => {
  const index = state.reportQueries.findIndex(f => f.reportQueryId === payload.reportQueryId)
  state.reportQueries.splice(index, 1)
}

const SET_REPORTQUERIES_LOADING = (state, payload) => {
  state.reportQueriesLoading = payload
}

const UPDATE_REPORTQUERY_ACTIVE = (state, payload) => {
  if (payload) {
    state.reportQueries.filter(f => f.type === payload.type).forEach((v, i) => {
      if (v.reportQueryId === payload.reportQuery.reportQueryId) {
        v.active = payload.active
      } else {
        v.active = false
      }
    })
  }
}

export default {
  updateField,
  SET_REPORTQUERIES,
  PUSH_REPORTQUERY,
  SPLICE_REPORTQUERY,
  UPDATE_REPORTQUERY,
  SET_REPORTQUERIES_LOADING,
  UPDATE_REPORTQUERY_ACTIVE,
  SET_REPORTQUERIES_USERS_LIST
}
