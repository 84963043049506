import axios from '@/utilities/axios-global'

const GET_PULLLIST_BUILDS = async (context) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reviews/pullListBuilds`)
    context.commit('SET_PULLLIST_BUILDS', response.data)
  } catch (err) {
    console.log(err)
  }
}

const GET_PULLLIST_BUILD = async (context, id) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reviews/pullListBuilds/${id}`)
    context.commit('SET_PULLLIST_BUILD', response.data)
  } catch (err) {
    console.log(err)
  }
}

const PUT_PULLLIST_BUILD = async (context, payload) => {
  try {
    await axios.put(`${context.rootState.user.baseApiUrl}/reviews/pullListBuilds/${payload.id}`, payload)
  } catch (err) {
    console.log(err)
  }
}

const POST_PULLLIST_BUILD = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pullListBuilds`, payload)
    return response
  } catch (err) {
    console.log(err)
  }
}

const DELETE_PULLLIST_BUILD = async (context, id) => {
  try {
    await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/pullListBuilds/${id}`)
  } catch (err) {
    console.log(err)
  }
}

const GET_TARGET_TEMPLATES = async (context) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reviews/pullListBuilds/targetTemplates`)
    context.commit('SET_TARGET_TEMPLATES', response.data)
  } catch (err) {
    console.log(err)
  }
}

export default {
  GET_PULLLIST_BUILDS,
  GET_TARGET_TEMPLATES,
  GET_PULLLIST_BUILD,
  PUT_PULLLIST_BUILD,
  POST_PULLLIST_BUILD,
  DELETE_PULLLIST_BUILD
}
