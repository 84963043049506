import { updateField } from 'vuex-map-fields'

const SET_REASONFORCHANGE = (state, payload) => {
  state.reasonForChanges = payload
}

const UPDATE_REASONFORCHANGE = (state, payload) => {
  if (payload) {
    const index = state.reasonForChanges.findIndex(x => x.reasonForChangeId === payload.reasonForChangeId)
    state.reasonForChanges.splice(index, 1, payload)
  }
}

const PUSH_REASONFORCHANGE = (state, payload) => {
  payload.isNew = true
  state.reasonForChanges.unshift(payload)
}

const SET_REASONFORCHANGE_LOADING = (state, payload) => {
  state.reasonForChangesLoading = payload
}

const CLEAR_REASONFORCHANGE = (state, payload) => {
  state.reasonForChanges = []
}

const SPLICE_REASONFORCHANGE = (state, reasonForChangeId) => {
  const index = state.reasonForChanges.findIndex(x => x.reasonForChangeId === reasonForChangeId)
  state.reasonForChanges.splice(index, 1)
}

export default {
  updateField,
  SET_REASONFORCHANGE,
  UPDATE_REASONFORCHANGE,
  PUSH_REASONFORCHANGE,
  CLEAR_REASONFORCHANGE,
  SET_REASONFORCHANGE_LOADING,
  SPLICE_REASONFORCHANGE
}
