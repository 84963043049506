import { updateField } from 'vuex-map-fields'

const SET_RECOMMENDATION_LIBRARY = (state, payload) => {
  state.recommendationLibrary = payload
}

const SET_RECOMMENDATION_LIBRARY_PRINTABLE = (state, payload) => {
  state.recommendationLibraryPrintable = payload
}

const SET_SHOW_RECOMMENDATION_LIBRARY_PICKER = (state, payload) => {
  state.showRecommendationsPicker = payload
}

const SET_CURRENT_RECOMMENDATION_TYPE = (state, payload) => {
  state.currentRecommendationType = payload
}

const SET_CURRENT_RECORD_TYPE = (state, payload) => {
  state.currentRecordType = payload
}

const SET_RECOMMENDATION = (state, payload) => {
  state.recommendationRecord = payload
}

const PUSH_RECOMMENDATION_LIBRARY = (state, payload) => {
  state.recommendationLibrary.push(payload)
}

const DELETE_RECOMMENDATION = (state, id) => {
  const len = state.recommendationLibrary.length
  for (let i = 0; i < len; i++) {
    if (state.recommendationLibrary[i].id === id) {
      state.recommendationLibrary.splice(i, 1)
      break
    }
  }
}

export default {
  updateField,
  SET_RECOMMENDATION,
  SET_RECOMMENDATION_LIBRARY,
  SET_RECOMMENDATION_LIBRARY_PRINTABLE,
  SET_SHOW_RECOMMENDATION_LIBRARY_PICKER,
  SET_CURRENT_RECORD_TYPE,
  SET_CURRENT_RECOMMENDATION_TYPE,
  PUSH_RECOMMENDATION_LIBRARY,
  DELETE_RECOMMENDATION
}
