const money = {
  methods: {
    formatMoney: function (val = 0, c, d, t) {
      let n = val
      c = isNaN(c = Math.abs(c)) ? 2 : c
      d = d === undefined ? '.' : d
      t = t === undefined ? ',' : t
      const s = n < 0 ? '-' : ''
      const i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + ''
      let j
      j = (j = i.length) > 3 ? j % 3 : 0
      const retValue = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')
      return retValue
    },
    formatInvoiceAmt(invoice, field) {
      return this.formatMoney(invoice[field], 2)
    }
  }
}

export default money
