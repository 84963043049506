import { updateField } from 'vuex-map-fields'

const SET_PROVIDERS = (state, payload) => {
  state.providers = payload
}

const PUSH_PROVIDER = (state, payload) => {
  state.providers.push(payload)
}

const SPLICE_PROVIDER = (state, payload) => {
  const index = state.providers.findIndex(x => x.providerId === payload.providerId)
  state.providers.splice(index, 1)
}

const SET_SELECTED = (state, payload) => {
  payload.forEach(x => {
    const found = state.providers.find(y => y.providerId === x.providerId)
    if (found) {
      found.selected = x.selected
    }
  })
  state.providers = [...state.providers] // copy the array to trigger reactivity
}

const SET_PROVIDERS_LOADING = (state, payload) => {
  state.providersLoading = payload
}

export default {
  updateField,
  SET_PROVIDERS,
  PUSH_PROVIDER,
  SPLICE_PROVIDER,
  SET_SELECTED,
  SET_PROVIDERS_LOADING
}
