import { updateField } from 'vuex-map-fields'

const SET_TENANT_SETUP = (state, payload) => {
  state.tenantSetup = payload
}

export default {
  updateField,
  SET_TENANT_SETUP
}
