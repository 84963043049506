import axios from '@/utilities/axios-global'

const GET_PRO_COMPLIANCE_REASONFORCHANGES = async (context) => {
  try {
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/proComplianceReasonForChange`)
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE', response.data)
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_PRO_COMPLIANCE_REASONFORCHANGES = async (context, payload) => {
  try {
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/proComplianceReasonForChange/${payload.reasonForChangeId}`
    const response = await axios.put(url, payload)
    context.commit('UPDATE_PRO_COMPLIANCE_REASONFORCHANGE', response.data)
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_PRO_COMPLIANCE_REASONFORCHANGES = async (context, payload) => {
  try {
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/proComplianceReasonForChange`, payload.proComplianceReasonForChange)
    context.commit('PUSH_PRO_COMPLIANCE_REASONFORCHANGE', response.data)
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_PRO_COMPLIANCE_REASONFORCHANGES = async (context, reasonForChangeId) => {
  try {
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/proComplianceReasonForChange/${reasonForChangeId}`)
    context.commit('SPLICE_PRO_COMPLIANCE_REASONFORCHANGE', reasonForChangeId)
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PRO_COMPLIANCE_REASONFORCHANGE_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_PRO_COMPLIANCE_REASONFORCHANGES,
  PUT_PRO_COMPLIANCE_REASONFORCHANGES,
  POST_PRO_COMPLIANCE_REASONFORCHANGES,
  DELETE_PRO_COMPLIANCE_REASONFORCHANGES
}
