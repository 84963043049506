import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
  outpatientReviews: [],
  outpatientReview: {
    project: {},
    ascRecommendationsCptsApcs: [],
    ascRecommendationsPx: [],
    ascRecommendationsDx: [],
    outpatientGrouperId: 0
  },
  opListSelected: [],
  opListRecordValidations: [],
  statistics: {},
  opProjectStatistics: {},
  dxLoading: false,
  pxLoading: false,
  cptLoading: false,
  apcList: [],
  refreshOpList: function() {}
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
