import { updateField } from 'vuex-map-fields'

const SET_USERS = (state, payload) => {
  state.users = payload
}

const SET_IP_WORKSHEET = (state, payload) => {
  state.ipWorksheet = payload
}

const SET_RESPONSE_ROW = (state, payload) => {
  state.responseRow = payload
}

const SET_RESPONSE_PATSTAT = (state, payload) => {
  if (JSON.stringify(state.responsePatStat) !== JSON.stringify(payload)) {
    state.responsePatStat = payload
  }
}

const SET_RESPONSE_DRG = (state, payload) => {
  if (JSON.stringify(state.responseDRG) !== JSON.stringify(payload)) {
    state.responseDRG = payload
  }
}

const SET_RESPONSE_POO = (state, payload) => {
  if (JSON.stringify(state.responsePOO) !== JSON.stringify(payload)) {
    state.responsePOO = payload
  }
}

const SET_PRO_WORKSHEET = (state, payload) => {
  state.proWorksheet = payload
}

const SET_OP_WORKSHEET = (state, payload) => {
  state.opWorksheet = payload
}

const SET_IP_LIST_STATISTICS = (state, payload) => {
  state.ipListStatistics = payload
}

const SET_OP_LIST_STATISTICS = (state, payload) => {
  state.opListStatistics = payload
}

const SET_PRO_LIST_STATISTICS = (state, payload) => {
  state.proListStatistics = payload
}

const SET_AMB_LIST_STATISTICS = (state, payload) => {
  state.ambListStatistics = payload
}

export default {
  updateField,
  SET_USERS,
  SET_IP_WORKSHEET,
  SET_RESPONSE_ROW,
  SET_RESPONSE_PATSTAT,
  SET_RESPONSE_DRG,
  SET_RESPONSE_POO,
  SET_PRO_WORKSHEET,
  SET_OP_WORKSHEET,
  SET_IP_LIST_STATISTICS,
  SET_OP_LIST_STATISTICS,
  SET_PRO_LIST_STATISTICS,
  SET_AMB_LIST_STATISTICS
}
