const SET_DROPDOWNS = (state, payload) => {
  const keys = Object.keys(payload)
  for (let i = 0; i <= keys.length - 1; i++) {
    const dropdown = {
      name: keys[i],
      refresh: Date.now(),
      list: payload[keys[i]]
    }
    state.dropdowns[keys[i]] = dropdown

    if (keys[i] === 'ProReasonsForChange') {
      // retrieved default RFC - store them in an separate list so we can swap in and out
      state.defaultProReasons = payload[keys[i]]
    }
  }
}

const REMOVE_CACHED_DROPDOWN = (state, dropdownName) => {
  state.dropdowns[dropdownName] = { list: [] }
}

const REMOVE_ALL_CACHED_DROPDOWNS = (state) => {
  for (const [key] of Object.entries(state.dropdowns)) {
    state.dropdowns[key] = { list: [] }
  }
}

const PUSH_NEWCODER = (state, payload) => {
  const val = {
    key: payload.coderId,
    value: payload.name
  }
  state.dropdowns.CoderDropDown.list[0].choices.push(val)
}

const PUSH_NEWCDI = (state, payload) => {
  state.dropdowns.cdiDropDown.list.push(payload)
}

const PUSH_NEWPROVIDER = (state, payload) => {
  if (payload.practiceId) {
    const provider = state.dropdowns.PracticesNew.list.find(x => x.practiceId === payload.practiceId)
    provider.clientPracticesProvidersNew.push({
      practiceId: payload.practiceId,
      providerId: payload.providerId,
      provider: {
        providerId: payload.providerId,
        name: payload.name
      }
    })
    state.dropdowns.PracticesNew.list.push({})
    state.dropdowns.PracticesNew.list.splice(-1, 1)
  }
}

export default {
  SET_DROPDOWNS,
  PUSH_NEWCODER,
  PUSH_NEWCDI,
  PUSH_NEWPROVIDER,
  REMOVE_CACHED_DROPDOWN,
  REMOVE_ALL_CACHED_DROPDOWNS
}
