import axios from '@/utilities/axios-global'

const ADD_CHECKLIST = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/record-checklist`, payload)
    context.commit('CONCAT_CHECKLIST_ITEMS', response.data)
    context.commit('SET_ACTIVE_CHECKLIST_FROM_ITEMS', response.data)

    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const DELETE_CHECKLIST = async (context, payload) => {
  try {
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/record-checklist?recordId=${payload.recordId}&recordType=${payload.recordType}&checkListId=${payload.checklistId}`)

    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_RECORD_CHECKLIST_ITEM = async (context, payload) => {
  try {
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/record-checklist/${payload.checklistRecordId}`, payload)

    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_CHECKLIST_CODETYPE = async (context, payload) => {
  try {
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/record-checklist/update-codetype?recordId=${payload.recordId}&recordType=${payload.recordType}&checkListId=${payload.checklistId}&newCodeTypeId=${payload.codeTypeId}`, {})

    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  ADD_CHECKLIST,
  PUT_RECORD_CHECKLIST_ITEM,
  DELETE_CHECKLIST,
  PUT_CHECKLIST_CODETYPE
}
