import Vue from 'vue'

const SET_PROLIST = (state, payload) => {
  state.proReviews = payload
}

const SET_AUDIT_RESULT = (state, auditResult) => {
  state.proReview.auditResult = auditResult
}

const SET_ADDED_PRORECORDID = (state, proID) => {
  state.newProRecordID = proID
}

const SET_ADDED_PRORECORDCODEDID = (state, proID) => {
  state.newProrecordCodedID = proID
}

const SET_ACCOUNTLOOKUP = (state, payload) => {
  state.accountLookupData = payload
}

const CLEAR_ACCOUNTLOOKUP = (state, payload) => {
  state.accountLookupData = []
}

const SET_PROLIST_SELECTED = (state, payload) => {
  if (payload.checked) {
    state.proListSelected.push(payload)
  } else {
    state.proListSelected = state.proListSelected.filter(x => x.proRecordId !== payload.proRecordId)
  }
}

const SET_PROLIST_ALLIDS = (state, payload) => {
  state.proListSelected = payload
}

const CLEAR_PROLIST_SELECTED = (state, payload) => {
  state.proListSelected = []
}

const SET_STATISTICS = (state, payload) => {
  state.statistics = payload
}

const SET_PROJECT_STATISTICS = (state, payload) => {
  state.proProjectStatistics = payload
}

const SET_PROLIST_RECORD_VALIDATIONS = (state, payload) => {
  state.proListRecordValidations = payload
}

const CLEAR_VALIDATION_LIST = (state, payload) => {
  state.proListRecordValidations = []
}

const SET_PRO_REVIEW = (state, payload) => {
  state.proReview = payload
}

const SET_PRO_DXS = (state, row) => {
  state.proReview.proRecordDxs = row
}

const SET_PRO_DX_ROW = (state, row) => {
  const index = state.proReview.proRecordDxs.findIndex(x => x.dxId === row.dxId)
  Vue.set(state.proReview.proRecordDxs, index, row)
}

const DELETE_DX_ROW = (state, row) => {
  const index = state.proReview.proRecordDxs.findIndex(x => x.dxId === row.dxId)
  state.proReview.proRecordDxs.splice(index, 1)
}

const PUSH_DX_ROW = (state, row) => {
  state.proReview.proRecordDxs.push(row)
}

const SET_PRO_CPTS = (state, row) => {
  state.proReview.proRecordCpts = row
}

const SET_PRO_CPT_ROW = (state, row) => {
  const index = state.proReview.proRecordCpts.findIndex(x => x.cptId === row.cptId)
  Vue.set(state.proReview.proRecordCpts, index, row)
}

const DELETE_CPT_ROW = (state, row) => {
  const index = state.proReview.proRecordCpts.findIndex(x => x.cptId === row.cptId)
  state.proReview.proRecordCpts.splice(index, 1)
}

const PUSH_CPT_ROW = (state, row) => {
  state.proReview.proRecordCpts.push(row)
}

const SET_PRO_MODIFIER_ROW = (state, row) => {
  const cpt = state.proReview.proRecordCpts.find(x => x.cptId === row.cptId)
  const index = cpt.proRecordModifiers.findIndex(x => x.modifierId === row.modifierId)
  Vue.set(cpt.proRecordModifiers, index, row)
}

const SET_TRUCODE_SUBSET = (state, payload) => {
  if (!payload) {
    return
  }

  if (state.proReview.proRecordDxs.length === payload.proRecordDxs.length) {
    // loop over codes and update Trucode only
    for (const i in payload.proRecordDxs) {
      const dxIndex = state.proReview.proRecordDxs.findIndex(f => f.dxId === payload.proRecordDxs[i].dxId)
      if (dxIndex !== -1) {
        state.proReview.proRecordDxs[dxIndex].lineSequence = payload.proRecordDxs[i].lineSequence
        // original
        state.proReview.proRecordDxs[dxIndex].originalDescCalc = payload.proRecordDxs[i].originalDescCalc
        state.proReview.proRecordDxs[dxIndex].edits = payload.proRecordDxs[i].edits
        state.proReview.proRecordDxs[dxIndex].originalHccs = payload.proRecordDxs[i].originalHccs
        // recommended
        state.proReview.proRecordDxs[dxIndex].recommendedDx = payload.proRecordDxs[i].recommendedDx // this is needed for code books integration
        state.proReview.proRecordDxs[dxIndex].recDescCalc = payload.proRecordDxs[i].recDescCalc
        state.proReview.proRecordDxs[dxIndex].recEdits = payload.proRecordDxs[i].recEdits
        state.proReview.proRecordDxs[dxIndex].recommendedHccs = payload.proRecordDxs[i].recommendedHccs
        state.proReview.proRecordDxs[dxIndex].newPrimary = payload.proRecordDxs[i].newPrimary
        // level2
        state.proReview.proRecordDxs[dxIndex].level2Dx = payload.proRecordDxs[i].level2Dx
        state.proReview.proRecordDxs[dxIndex].level2DescCalc = payload.proRecordDxs[i].level2DescCalc
        state.proReview.proRecordDxs[dxIndex].level2Edits = payload.proRecordDxs[i].level2Edits
        state.proReview.proRecordDxs[dxIndex].level2Hccs = payload.proRecordDxs[i].level2Hccs
        state.proReview.proRecordDxs[dxIndex].level2NewPrimary = payload.proRecordDxs[i].level2NewPrimary

        state.proReview.proRecordDxs[dxIndex].hasRecommendation = payload.proRecordDxs[i].hasRecommendation
      }
    }
  } else {
    state.proReview.proRecordDxs = payload.proRecordDxs
  }

  // CPT
  if (state.proReview.proRecordCpts.length === payload.proRecordCpts.length) {
    for (const y in payload.proRecordCpts) {
      const cptIndex = state.proReview.proRecordCpts.findIndex(f => f.cptId === payload.proRecordCpts[y].cptId)
      if (cptIndex !== -1) {
        state.proReview.proRecordCpts[cptIndex].lineSequence = payload.proRecordCpts[y].lineSequence
        // original
        state.proReview.proRecordCpts[cptIndex].edits = payload.proRecordCpts[y].edits
        state.proReview.proRecordCpts[cptIndex].originalDescCalc = payload.proRecordCpts[y].originalDescCalc
        state.proReview.proRecordCpts[cptIndex].originalDescCalcMed = payload.proRecordCpts[y].originalDescCalcMed

        // recommended
        state.proReview.proRecordCpts[cptIndex].recommendedCpt = payload.proRecordCpts[y].recommendedCpt // this is needed for code books integration
        state.proReview.proRecordCpts[cptIndex].recEdits = payload.proRecordCpts[y].recEdits
        state.proReview.proRecordCpts[cptIndex].recDescCalc = payload.proRecordCpts[y].recDescCalc
        state.proReview.proRecordCpts[cptIndex].recDescCalcMed = payload.proRecordCpts[y].recDescCalcMed

        // level2
        state.proReview.proRecordCpts[cptIndex].level2Cpt = payload.proRecordCpts[y].level2Cpt
        state.proReview.proRecordCpts[cptIndex].level2Edits = payload.proRecordCpts[y].level2Edits
        state.proReview.proRecordCpts[cptIndex].level2DescCalc = payload.proRecordCpts[y].level2DescCalc

        state.proReview.proRecordCpts[cptIndex].hasRecommendation = payload.proRecordCpts[y].hasRecommendation

        // update mods
        state.proReview.proRecordCpts[cptIndex].proRecordModifiers = payload.proRecordCpts[y].proRecordModifiers
      }
    }
  } else {
    state.proReview.proRecordCpts = payload.proRecordCpts
  }

  // Research pane related
  state.proReview.orAscResponse_Object = payload.orAscResponse_Object
  state.proReview.orMNEResponse_Object = payload.orMNEResponse_Object

  state.proReview.recAscResponse_Object = payload.recAscResponse_Object
  state.proReview.recMNEResponse_Object = payload.recMNEResponse_Object

  state.proReview.level2AscResponse_Object = payload.level2AscResponse_Object
  state.proReview.level2MNEResponse_Object = payload.level2MNEResponse_Object
  state.proReview.truCodeTimeStamp = payload.truCodeTimeStamp

  // Handle action plan recommendations adds/deletes
  state.proReview.actionPlansRecommendations = payload.actionPlansRecommendations

  // Handle physician fee schedule update
  state.proReview.proRecordCptsPhysicianFeeSchedule = payload.proRecordCptsPhysicianFeeSchedule

  state.proReview.decisionMaking2021 = payload.decisionMaking2021
  state.proReview.decisionMaking2023 = payload.decisionMaking2023
  state.proReview.emguidelines = payload.emguidelines
  state.proReview.serviceLine = payload.serviceLine
  state.proReview.provider = payload.provider
  state.proReview.clientCoder = payload.clientCoder
  state.proReview.payorDetail = payload.payorDetail
  state.proReview.recordValidations = payload.recordValidations

  state.proReview.identifierSelectionsArray = payload.identifierSelectionsArray
  state.proReview.identifierSelections = payload.identifierSelections

  state.proReview.auditResult = payload.auditResult
}

const SET_HISTORY_CALCULATED_FIELDS = (state, payload) => {
  const history = state.proReview.proRecordHistory.find(x => x.codeLevel === payload.codeLevel)
  Object.keys(history).forEach(key => {
    history[key] = payload[key]
  })
}

const SET_HISTORY_OVERRIDE_FIELDS = (state, payload) => {
  state.proReview.hpiPresent = payload.hpiPresent
  state.proReview.pfshPresent = payload.pfshPresent
  state.proReview.pfshPresent = payload.pfshPresent
  state.proReview.rosPresent = payload.rosPresent
  state.proReview.examPresent = payload.examPresent
  state.proReview.level1ExamTypeId = payload.level1ExamTypeId
  state.proReview.level2ExamTypeId = payload.level2ExamTypeId
}

const SET_DECISION_MAKING_CALCULATED_FIELDS = (state, payload) => {
  const dm = state.proReview.proRecordDecisionMaking.find(x => x.codeLevel === payload.codeLevel)
  dm.typeAndNumberOfPresentingProblems = payload.typeAndNumberOfPresentingProblems
  dm.levelOfRisk = payload.levelOfRisk
  dm.amtAndComplexityOfData = payload.amtAndComplexityOfData
  dm.decisionComplexity = payload.decisionComplexity

  dm.presentingProblemsLevel2021 = payload.presentingProblemsLevel2021
  dm.managementOptionsLevel2021 = payload.managementOptionsLevel2021
  dm.documentationComplexity2021 = payload.documentationComplexity2021
  dm.decisionComplexity2021 = payload.decisionComplexity2021
}

const SET_EXAM_ELEMENTS = (state, payload) => {
  state.examElements = payload
}

const SET_EXAM_SCORING = (state, payload) => {
  state.proReview.examScoring = payload
}

const PUSH_EXAM_DETAILS = (state, payload) => {
  state.proReview.examDetails.push(payload)
}

const SPLICE_EXAM_DETAILS = (state, payload) => {
  const index = state.proReview.examDetails.findIndex(x => x.examElelmentId === payload.examElementId && x.codeLevel === payload.codeLevel)
  state.proReview.examDetails.splice(index)
}

const SET_PROLIST_REFRESH_FUNC = (state, func) => {
  state.refreshProList = func
}

const SET_DX_LOADING = (state, payload) => {
  state.dxLoading = payload
}

const SET_CPT_LOADING = (state, payload) => {
  state.cptLoading = payload
}

export default {
  SET_PRO_REVIEW,
  SET_PRO_DXS,
  SET_PRO_DX_ROW,
  SET_AUDIT_RESULT,
  DELETE_DX_ROW,
  PUSH_DX_ROW,
  SET_DX_LOADING,
  SET_CPT_LOADING,
  SET_PRO_CPTS,
  SET_PRO_CPT_ROW,
  DELETE_CPT_ROW,
  PUSH_CPT_ROW,
  SET_PRO_MODIFIER_ROW,
  SET_PROLIST,
  SET_ADDED_PRORECORDID,
  SET_ACCOUNTLOOKUP,
  SET_ADDED_PRORECORDCODEDID,
  CLEAR_ACCOUNTLOOKUP,
  SET_PROLIST_SELECTED,
  SET_PROLIST_ALLIDS,
  SET_STATISTICS,
  CLEAR_PROLIST_SELECTED,
  SET_PROLIST_RECORD_VALIDATIONS,
  CLEAR_VALIDATION_LIST,
  SET_TRUCODE_SUBSET,
  SET_HISTORY_CALCULATED_FIELDS,
  SET_DECISION_MAKING_CALCULATED_FIELDS,
  SET_EXAM_ELEMENTS,
  SET_EXAM_SCORING,
  PUSH_EXAM_DETAILS,
  SPLICE_EXAM_DETAILS,
  SET_PROLIST_REFRESH_FUNC,
  SET_PROJECT_STATISTICS,
  SET_HISTORY_OVERRIDE_FIELDS
}
