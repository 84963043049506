// export const SET_IPLIST_LOADING = 'SET_IPLIST_LOADING'
// export const SET_IPLIST = 'SET_IPLIST'
// export const RUN_IP_MASSUPDATE = 'RUN_IP_MASSUPDATE'
// const mutations = {
// const SET_PROLIST = (state, payload) => {
//   state.proReviews = payload
// }

const SET_EXAMELEMENTS = (state, payload) => {
  state.examElements = payload
}

// const SET_PRORECORD_EXAMDETAILS = (state, payload) => {
//   state.proRecordExamDetails = payload
// }

// const PUSH_PRORECORD_EXAMDETAILS = (state, payload) => {
//   state.proRecordExamDetails.push(payload)
// }

// const SPLICE_PRORECORD_EXAMDETAILS = (state, payload) => {
//   let length = state.proRecordExamDetails.length
//   for (var i = 0; i < length; i++) {
//     let item = state.proRecordExamDetails[i]
//     if ((item.examElelmentId === payload.examElementId) && (item.codeLevel === payload.codeLevel)) {
//       state.proRecordExamDetails.splice(i)
//     }
//   }
// }

// const SET_ADDED_PRORECORDID = (state, proID) => {
//   state.newProRecordID = proID
// }

// const SET_ADDED_PRORECORDCODEDID = (state, proID) => {
//   state.newProrecordCodedID = proID
// }

// const SET_ACCOUNTLOOKUP = (state, payload) => {
//   state.accountLookupData = payload
// }

// const CLEAR_ACCOUNTLOOKUP = (state, payload) => {
//   state.accountLookupData = []
// }

export default {
  SET_EXAMELEMENTS
  // SET_PRORECORD_EXAMDETAILS,
  // PUSH_PRORECORD_EXAMDETAILS,
  // SPLICE_PRORECORD_EXAMDETAILS
}
