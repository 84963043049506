// import { updateField } from 'vuex-map-fields'

const SET_USER = (state, payload) => {
  state.user = payload
}

const SET_USER_META = (state, payload) => {
  if (payload) {
    state.user.fullName = payload.fullName
    state.user.hasAppAccess = payload.hasAppAccess
    state.user.userTenants = payload.userTenants
  }
}

const INITIALIZE_CURRENT_TENANT = (state) => {
  const storedCurrentTenant = window.localStorage.getItem('current_tenant_guid')
  if (storedCurrentTenant) {
    const fullTenant = state.user.userTenants.find(x => x.tenantGuid === storedCurrentTenant)
    if (fullTenant) {
      SET_CURRENT_TENANT(state, fullTenant)
      return
    }
  }

  if (state.user.userTenants.length > 0) {
    SET_CURRENT_TENANT(state, state.user.userTenants[0])
  }
}

const SET_CURRENT_TENANT = (state, payload) => {
  state.currentTenantGuid = payload.tenantGuid
  state.currentTenantName = payload.tenantName
  state.currentTenantUserId = payload.userId
  state.baseApiUrl = process.env.VUE_APP_API_BASE_URL + '/api/' + state.currentTenantGuid

  // set the user's isTruCodeEnabled property from the current tenant
  state.user.isTrucodeEnabled = payload.trucodeEnabled
  window.localStorage.setItem('current_tenant_guid', state.currentTenantGuid)
}

const SET_CURRENT_TENANT_BY_GUID = (state, guid) => {
  const tenant = state.user.userTenants.find(x => x.tenantGuid === guid)
  if (tenant) {
    SET_CURRENT_TENANT(state, tenant)
  }
}

export default {
  // updateField,
  SET_USER,
  SET_USER_META,
  SET_CURRENT_TENANT,
  INITIALIZE_CURRENT_TENANT,
  SET_CURRENT_TENANT_BY_GUID
}
