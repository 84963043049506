import auth0 from 'auth0-js'
import { AUTH_CONFIG } from './auth0-variables'
import EventEmitter from 'eventemitter3'

export default class AuthService {
  authenticated = this.isAuthenticated()
  authNotifier = new EventEmitter()

  constructor() {
    this.login = this.login.bind(this)
    this.setSession = this.setSession.bind(this)
    this.logout = this.logout.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.tokenRenewalTimeout = null
  }

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: 'token id_token',
    scope: 'openid email create:user_tickets'
  })

  randomString(length = 32) {
    const bytes = new Uint8Array(length)
    const result = []
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'

    const crypto = window.crypto || window.msCrypto
    const random = crypto.getRandomValues(bytes)
    for (let i = 0; i < random.length; i += 1) result.push(charset[random[i] % charset.length])

    return result.join('')
  }

  login() {
    // note we're generating and storing our own state due to IE cookie issues
    // with some clients
    const state = this.randomString()
    const nonce = this.randomString()

    window.localStorage.setItem('auth0state', state)
    window.localStorage.setItem('auth0nonce', nonce)

    this.auth0.authorize({
      audience: `https://${AUTH_CONFIG.domain}/api/v2/`,
      state: state,
      nonce: nonce
    })
  }

  handleAuthentication() {
    const state = window.localStorage.getItem('auth0state')
    const nonce = window.localStorage.getItem('auth0nonce')
    this.auth0.parseHash({ state: state, nonce: nonce }, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, true)
      } else if (err) {
        console.log(err)
      }
    })
  }

  renewToken() {
    this.auth0.checkSession({
      audience: `https://${AUTH_CONFIG.domain}/api/v2/`
    },
      (err, result) => {
        if (err) {
          console.log(err)
        } else {
          this.setSession(result, false)
        }
      }
    )
  }

  scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    let delay = expiresAt - Date.now()
    // renew 10 minutes early
    delay -= 600000

    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        console.log('renewal timeout reached')
        this.renewToken()
      }, delay)
    }
  }

  setSession(authResult, emitNotification) {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    )
    localStorage.setItem('access_token', authResult.accessToken)
    localStorage.setItem('id_token', authResult.idToken)
    localStorage.setItem('expires_at', expiresAt)
    this.scheduleRenewal()
    if (emitNotification) {
      this.authNotifier.emit('authChange', { authenticated: true })
    }
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    this.userProfile = null
    clearTimeout(this.tokenRenewalTimeout)
    this.authNotifier.emit('authChange', false)
    this.auth0.logout({
      returnTo: window.location.origin
    })
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    return new Date().getTime() < expiresAt
  }
}
