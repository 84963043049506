import axios from '@/utilities/axios-global'

const GET_PAYORLIST = async (context) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/payorlist`)
    context.commit('SET_PAYORLIST', response.data)
    context.commit('SET_PAYORLIST_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PAYORLIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_PAYORLIST = async (context, payload) => {
  try {
    context.commit('SET_PAYORLIST_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/payorlist/${payload.payorNumber}`
    const response = await axios.put(url, payload)
    context.commit('UPDATE_PAYORLIST', response.data)
    context.commit('SET_PAYORLIST_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PAYORLIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_PAYORLIST = async (context, payload) => {
  try {
    context.commit('SET_PAYORLIST_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/payorlist`, payload.payorList)
    context.commit('PUSH_PAYORLIST', response.data)
    context.commit('SET_PAYORLIST_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PAYORLIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_PAYORLIST = async (context, payorNumber) => {
  try {
    context.commit('SET_PAYORLIST_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/payorlist/${payorNumber}`)
    context.commit('SPLICE_PAYORLIST', payorNumber)
    context.commit('managedLists/payorDetails/REMOVE_PAYORDETAIL_BY_PAYORNUMBER', payorNumber, { root: true })
    context.commit('SET_PAYORLIST_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PAYORLIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_PAYORLIST,
  PUT_PAYORLIST,
  POST_PAYORLIST,
  DELETE_PAYORLIST
}
