<template>
  <div :class="`VueTables VueTables--${props.source}`" slot-scope="props">
    <div style="position: relative; width: 100%;">
      <el-dropdown :class="props.opts.actionsPosition ? props.opts.actionsPosition : 'none'" trigger="click" @command="handleAction">
        <span class="el-dropdown-link">
          <i title="table actions" class="actionsAction mdi mdi-dots-horizontal" style="font-size: 18px; cursor: pointer;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="download">Download</el-dropdown-item>
          <el-dropdown-item v-for="item in getActionOptions()" :key="item.label" :command="item.label">
            {{item.label}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div :class="props.theme.row">
      <div :class="props.theme.column">
        <div v-if="!props.opts.filterByColumn && props.opts.filterable" :class="`${props.theme.field} ${props.theme.inline} ${props.theme.left} VueTables__search`">
          <vnodes :vnodes="props.slots.beforeFilter" />
          <vt-generic-filter ref="genericFilter" />
          <vnodes :vnodes="props.slots.afterFilter" />
        </div>
        <vnodes :vnodes="props.slots.afterFilterWrapper" />

        <div v-if="props.perPageValues.length > 1 || props.opts.alwaysShowPerPageSelect"
          :class="`${props.theme.field} ${props.theme.inline} ${props.theme.right} VueTables__limit`">
          <vnodes :vnodes="props.slots.beforeLimit" />
          <vt-per-page-selector />
          <vnodes :vnodes="props.slots.afterLimit" />

        </div>

        <div class="VueTables__pagination-wrapper" v-if="props.opts.pagination.dropdown && props.totalPages > 1">
          <div :class="`${props.theme.field} ${props.theme.inline} ${props.theme.right} VueTables__dropdown-pagination`">
            <vt-dropdown-pagination />
          </div>
        </div>

        <div v-if="props.opts.columnsDropdown" :class="`VueTables__columns-dropdown-wrapper ${props.theme.right} ${props.theme.dropdown.container}`">
          <vt-columns-dropdown />
        </div>
      </div>
    </div>

    <vnodes :vnodes="props.slots.beforeTable" />
    <div class="table-responsive">
      <vt-table ref="vt_table" />
    </div>
    <vnodes :vnodes="props.slots.afterTable" />

    <vt-pagination />

  </div>
</template>

<script>
  import VtColumnsDropdown from 'vue-tables-2/compiled/components/VtColumnsDropdown'
  import VtDropdownPagination from 'vue-tables-2/compiled/components/VtDropdownPagination'
  import VtGenericFilter from 'vue-tables-2/compiled/components/VtGenericFilter'
  import VtPerPageSelector from 'vue-tables-2/compiled/components/VtPerPageSelector'
  import VtPagination from 'vue-tables-2/compiled/components/VtPagination'
  import VtTable from 'vue-tables-2/compiled/components/VtTable'
  import csvGenerator from '@/mixins/csvGenerator'

  export default {
    name: 'HiaVueTable2',
    mixins: [csvGenerator],
    props: ['props'],
    components: {
      VtGenericFilter,
      VtPerPageSelector,
      VtColumnsDropdown,
      VtDropdownPagination,
      VtTable,
      VtPagination,
      vnodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes
      }
    },
    methods: {
      async handleAction(command) {
        if (command === 'download') {
          const rootParent = this.$parent

          let arr = []
          if (rootParent._data.source === 'server') {
            arr = await this.getServerTableData(rootParent)
          } else {
            arr = rootParent._data.allFilteredData
          }
          if (arr.length > 0) {
            arr = this.mapListColumns(arr)
            const keyValueMappedHeadings = this.getKeyValueMappedHeadings(rootParent)
            this.arrayToCsv(arr, 'table.csv', keyValueMappedHeadings)
          }
        } else {
          const actionOption = this.props.opts.actionsOptions.find(x => x.label === command)
          actionOption.handler()
        }
      },
      async getServerTableData(rootParent) {
        const fetchFunction = this.props.opts.requestFunction
        const payload = {
          ascending: rootParent.orderBy.ascending,
          orderBy: rootParent.orderBy.column,
          query: Object.fromEntries(Object.entries(rootParent._data.query).filter(([_, v]) => v !== '')),
          page: 1,
          limit: rootParent._data.count
        }
        try {
          const response = await fetchFunction(payload)
          return response.data
        } catch (e) {
          this.$message({
            message: 'Error Creating Download ',
            type: 'error'
          })
        }
      },
      mapListColumns(arr) {
        if (this.props.opts.listColumns) {
          arr = arr.map(x => {
            for (const [key, value] of Object.entries(this.props.opts.listColumns)) {
              if (x[key]) {
                const displayObject = value.find(y => y.id === x[key])
                x[key] = displayObject ? displayObject.text : x[key]
              }
            }
            return x
          })
        }
        return arr
      },
      getKeyValueMappedHeadings(rootParent) {
        const headings = this.props.opts.headings
        const keyValueMappedHeadings = rootParent.$options.propsData.columns.map(x => {
          let heading
          if (typeof headings[x] === 'function') {
            try {
              heading = headings[x]()
            } catch (e) {
              return null
            }
          } else {
            heading = headings[x]
          }

          // if the heading is explicity blank, e.g Select All heading, skip
          if (heading === '') {
            return null
          }

          if (heading) {
            return {
              key: x,
              value: heading
            }
          } else {
            return {
              key: x,
              value: x
            }
          }
        })
        return keyValueMappedHeadings.filter(x => x !== undefined && x !== null)
      },
      getActionOptions() {
        if (!this.props.opts.actionsOptions) {
          return []
        }
        return this.props.opts.actionsOptions.filter(x => x.security === undefined || x.security())
      }
    }
  }
</script>

<style scoped>
  .left {
    top: -20px;
    left: 4px;
    position: absolute;
  }

  .right {
    top: -20px;
    right: 4px;
    position: absolute;
  }

  .none {
    display: none;
  }
</style>