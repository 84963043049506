import axios from '@/utilities/axios-global'

const GET_DROPDOWNS = (context, dropdownsNeeded) => {
  return new Promise((resolve, reject) => {
    const len = dropdownsNeeded.length
    const dropdownsToGet = []
    for (let i = 0; i < len; i++) {
      let refreshDropdown = false
      const dropVal = dropdownsNeeded[i]
      const existingDropdown = context.state.dropdowns[dropVal]

      if (!existingDropdown) { // not found - fire get
        refreshDropdown = true
      } else { // was found - now need to check refresh interval
        const previousRefresh = existingDropdown.refresh || 0
        const filtered = context.state.dropdownsDetails.filter(dropdown => dropdown.name === dropVal)

        // default to 8 hours if refreshInterval is not set in the store
        const eightHoursInMilliseconds = 28800000
        let refreshInterval = eightHoursInMilliseconds
        if (filtered.length > 0) {
          refreshInterval = filtered[0].refreshInterval
        }

        const currentDate = Date.now()

        if ((currentDate - previousRefresh) >= refreshInterval) {
          refreshDropdown = true
        }
      }

      if (refreshDropdown) {
        dropdownsToGet.push(dropVal)
      }
    }

    if (dropdownsToGet.length > 0) {
      const url = context.rootState.user.baseApiUrl + '/dropdowns'
      axios.post(url, dropdownsToGet).then(response => {
        context.commit('SET_DROPDOWNS', response.data)
        resolve()
      }, error => {
        console.log(error)
        reject(error)
      })
    } else {
      resolve()
    }
  })
}

const GET_DROPDOWNS_FORCE = (context, dropdownsNeeded) => {
  return new Promise((resolve, reject) => {
    const url = context.rootState.user.baseApiUrl + '/dropdowns'
    axios.post(url, dropdownsNeeded).then(response => {
      context.commit('SET_DROPDOWNS', response.data)
      resolve()
    }, error => {
      console.log(error)
      reject(error)
    })
  })
}

const CONVERT_DROPDOWN_COLUMNFILTERARRAY = (context, payload) => {
  const returnArray = []
  let currArray = []
  currArray = context.state.dropdowns[payload].list
  const length = currArray.length

  for (let j = 0; j < length; j++) {
    const newElement = {
      id: currArray[j].key,
      text: currArray[j].value
    }
    returnArray[j] = newElement
  }
  return returnArray
}

export default {
  GET_DROPDOWNS,
  CONVERT_DROPDOWN_COLUMNFILTERARRAY,
  GET_DROPDOWNS_FORCE
}
