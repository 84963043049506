import payorDetails from './payorDetails/store'
import payors from './payors/store'
import reasonForChanges from './reasonForChanges/store'
import coders from './coders/store'
import clients from './clients/store'
import practices from './practices/store'
import providers from './providers/store'
import proComplianceReasonForChanges from './proComplianceReasonForChanges/store'
import serviceLines from './serviceLines/store'
import avoidableDayReasons from './avoidableDayReasons/store'
import projectIdentifiers from './projectIdentifiers/store'
import projects from './projects/store'
import tenantSetup from './tenantSetup/store'
import codeTypes from './codeTypes/store'
import ascPatientTypes from './ascPatientTypes/store'
import blendedRates from './blendedRates/store'
import modifiers from './modifiers/store'
import cdiStaff from './cdiStaff/store'
import requiredFields from './requiredFields/store'
import projectTasks from './projectTasks/store'
import projectProcesses from './projectProcesses/store'
import clientChecklists from './clientChecklists/store'
import clientChecklistItems from './clientChecklistItems/store'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    payorDetails,
    payors,
    reasonForChanges,
    coders,
    cdiStaff,
    clients,
    practices,
    providers,
    proComplianceReasonForChanges,
    serviceLines,
    avoidableDayReasons,
    projectIdentifiers,
    projects,
    modifiers,
    tenantSetup,
    codeTypes,
    ascPatientTypes,
    blendedRates,
    requiredFields,
    projectTasks,
    projectProcesses,
    clientChecklists,
    clientChecklistItems
  }
}

export default module
