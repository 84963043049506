import { updateField } from 'vuex-map-fields'

const SET_REPORTS_INPATIENT_DATA = (state, payload) => {
  // state.reports.reportType = payload.reportType
  state.inpatientReport.globalSearch = payload.globalSearch
  state.inpatientReport.defaultDateRange = payload.defaultDateRange
  state.inpatientReport.inpatientData = payload.inpatientData
}

const SET_REPORTS_OUTPATIENT_DATA = (state, payload) => {
  // state.proReport.reportType = payload.reportType
  state.outpatientReport.globalSearch = payload.globalSearch
  state.outpatientReport.defaultDateRange = payload.defaultDateRange
  state.outpatientReport.outpatientData = payload.outpatientData
}

const SET_REPORTS_ASC_DATA = (state, payload) => {
  state.ambulatoryReport.globalSearch = payload.globalSearch
  state.ambulatoryReport.defaultDateRange = payload.defaultDateRange
  state.ambulatoryReport.outpatientData = payload.outpatientData
}

const SET_REPORTS_PRO_DATA = (state, payload) => {
  // state.proReport.reportType = payload.reportType
  state.proReport.globalSearch = payload.globalSearch
  state.proReport.defaultDateRange = payload.defaultDateRange
  state.proReport.proData = payload.proData
}

const SET_REPORTS_INPATIENT_VUETABLEDATA = (state, payload) => {
  if (state.inpatientReport.inpatientData.vueTableData) {
    state.inpatientReport.inpatientData.vueTableData = payload
  }
}

const SET_REPORTS_OUTPATIENT_VUETABLEDATA = (state, payload) => {
  if (state.outpatientReport.outpatientData.vueTableData) {
    state.outpatientReport.outpatientData.vueTableData = payload
  }
}

const SET_REPORTS_ASC_VUETABLEDATA = (state, payload) => {
  if (state.ambulatoryReport.outpatientData.vueTableData) {
    state.ambulatoryReport.outpatientData.vueTableData = payload
  }
}

const SET_REPORTS_PRO_VUETABLEDATA = (state, payload) => {
  if (state.proReport.proData.vueTableData) {
    state.proReport.proData.vueTableData = payload
  }
}

const SET_REPORTS_INPATIENT_FILTER = (state, payload) => {
  if (payload) {
    const index = state.inpatientReport.inpatientData.filters.findIndex(f => f.column === payload.column)
    state.inpatientReport.inpatientData.filters.splice(index, 1, payload)
  }
}

const SET_REPORTS_INPATIENT_REPORTFILTER = (state, payload) => {
  if (payload) {
    state.inpatientReport.inpatientData.filters = payload
  }
}

const RESET_REPORTS_INPATIENT_FILTER = (state, payload) => {
  state.inpatientReport.inpatientData.filters = []
}

const SET_REPORTS_OUTPATIENT_FILTER = (state, payload) => {
  if (payload) {
    const index = state.outpatientReport.outpatientData.filters.findIndex(f => f.column === payload.column)
    state.outpatientReport.outpatientData.filters.splice(index, 1, payload)
  }
}

const SET_REPORTS_OUTPATIENT_REPORTFILTER = (state, payload) => {
  if (payload) {
    state.outpatientReport.outpatientData.filters = payload
  }
}

const RESET_REPORTS_OUTPATIENT_FILTER = (state, payload) => {
  state.outpatientReport.outpatientData.filters = state.outpatientReport.outpatientData.filters.filter(x => x.hidden)
}

const SET_REPORTS_AMBULATORY_FILTER = (state, payload) => {
  if (payload) {
    const index = state.ambulatoryReport.outpatientData.filters.findIndex(f => f.column === payload.column)
    state.ambulatoryReport.outpatientData.filters.splice(index, 1, payload)
  }
}

const SET_REPORTS_AMBULATORY_REPORTFILTER = (state, payload) => {
  if (payload) {
    state.ambulatoryReport.outpatientData.filters = payload
  }
}

const RESET_REPORTS_AMBULATORY_FILTER = (state, payload) => {
  state.ambulatoryReport.outpatientData.filters = state.ambulatoryReport.outpatientData.filters.filter(x => x.hidden)
}

const SET_REPORTS_PRO_FILTER = (state, payload) => {
  if (payload) {
    const index = state.proReport.proData.filters.findIndex(f => f.column === payload.column)
    state.proReport.proData.filters.splice(index, 1, payload)
  }
}

const SET_REPORTS_PRO_REPORTFILTER = (state, payload) => {
  if (payload) {
    state.proReport.proData.filters = payload
  }
}

const RESET_REPORTS_PRO_FILTER = (state, payload) => {
  state.proReport.proData.filters = []
}

const SET_REPORTS_INPATIENT_GLOBALSEARCH = (state, payload) => {
  state.inpatientReport.globalSearch = payload || null
}

const SET_REPORTS_OUTPATIENT_GLOBALSEARCH = (state, payload) => {
  state.outpatientReport.globalSearch = payload || null
}

const SET_REPORTS_AMBULATORY_GLOBALSEARCH = (state, payload) => {
  state.ambulatoryReport.globalSearch = payload || null
}

const SET_REPORTS_PRO_GLOBALSEARCH = (state, payload) => {
  state.proReport.globalSearch = payload || null
}

const SET_REPORTS_DEFAULTCOMPLETED = state => {
  const status = state.inpatientReport.inpatientData.filters.find(f => f.column === 'status')
  if (status) {
    status.active = true
    status.filterValues = ['Completed'] || null
  } else {
    state.inpatientReport.inpatientData.filters.push({
      column: 'status',
      columnLabel: 'Review Status',
      columnType: 'text',
      filterValues: ['Completed'],
      filterText: null,
      moreThanDate: 0,
      withinDate: 0,
      dateOption: 'days',
      dateCriteria: null,
      tagCriteria: null,
      uniqueValues: [],
      dateBetween: [],
      active: true,
      includeUniqueValues: true,
      reportType: 'ip'
    })
  }

  const opStatus = state.outpatientReport.outpatientData.filters.find(f => f.column === 'status')
  if (opStatus) {
    opStatus.filterValues = ['Completed'] || null
  } else {
    state.outpatientReport.outpatientData.filters.push({
      column: 'status',
      columnLabel: 'Review Status',
      columnType: 'text',
      filterValues: ['Completed'],
      filterText: null,
      moreThanDate: 0,
      withinDate: 0,
      dateOption: 'days',
      dateCriteria: null,
      tagCriteria: null,
      uniqueValues: [],
      dateBetween: [],
      active: true,
      includeUniqueValues: true,
      reportType: 'op'
    })
  }

  const ascStatus = state.ambulatoryReport.outpatientData.filters.find(f => f.column === 'status')
  if (ascStatus) {
    ascStatus.filterValues = ['Completed'] || null
  } else {
    state.ambulatoryReport.outpatientData.filters.push({
      column: 'status',
      columnLabel: 'Review Status',
      columnType: 'text',
      filterValues: ['Completed'],
      filterText: null,
      moreThanDate: 0,
      withinDate: 0,
      dateOption: 'days',
      dateCriteria: null,
      tagCriteria: null,
      uniqueValues: [],
      dateBetween: [],
      active: true,
      includeUniqueValues: true,
      reportType: 'op'
    })
  }

  const proStatus = state.proReport.proData.filters.find(f => f.column === 'status')
  if (proStatus) {
    proStatus.filterValues = ['Completed'] || null
  } else {
    state.proReport.proData.filters.push({
      column: 'status',
      columnLabel: 'Review Status',
      columnType: 'text',
      filterValues: ['Completed'],
      filterText: null,
      moreThanDate: 0,
      withinDate: 0,
      dateOption: 'days',
      dateCriteria: null,
      tagCriteria: null,
      uniqueValues: [],
      dateBetween: [],
      active: true,
      includeUniqueValues: true,
      reportType: 'pro'
    })
  }
}

const SET_REPORTS_DEFAULTDATERANGE = (state, payload) => {
  // state.reports.defaultDateRange = payload || null
  const reviewDate = state.inpatientReport.inpatientData.filters.find(f => f.column === 'reviewDate')
  if (reviewDate) {
    reviewDate.active = !!payload
    reviewDate.dateBetween = payload || null
  } else {
    state.inpatientReport.inpatientData.filters.push({
      active: true,
      column: 'reviewDate',
      columnLabel: 'Review Date',
      columnType: 'date',
      dateBetween: payload || null,
      dateCriteria: 'dateBetween',
      dateOption: 'days',
      tagCriteria: null,
      filterText: null,
      filterValues: null,
      includeUniqueValues: false,
      moreThanDate: 0,
      uniqueValues: [],
      withinDate: 0,
      reportType: 'ip'
    })
  }

  // outpatient
  const opReviewDate = state.outpatientReport.outpatientData.filters.find(f => f.column === 'reviewDate')
  if (opReviewDate) {
    opReviewDate.active = !!payload
    opReviewDate.dateBetween = payload || null
  } else {
    state.outpatientReport.outpatientData.filters.push({
      active: true,
      column: 'reviewDate',
      columnLabel: 'Review Date',
      columnType: 'date',
      dateBetween: payload || null,
      dateCriteria: 'dateBetween',
      dateOption: 'days',
      tagCriteria: null,
      filterText: null,
      filterValues: null,
      includeUniqueValues: false,
      moreThanDate: 0,
      uniqueValues: [],
      withinDate: 0,
      reportType: 'op'
    })
  }

  const ascReviewDate = state.ambulatoryReport.outpatientData.filters.find(f => f.column === 'reviewDate')
  if (ascReviewDate) {
    ascReviewDate.active = !!payload
    ascReviewDate.dateBetween = payload || null
  } else {
    state.ambulatoryReport.outpatientData.filters.push({
      active: true,
      column: 'reviewDate',
      columnLabel: 'Review Date',
      columnType: 'date',
      dateBetween: payload || null,
      dateCriteria: 'dateBetween',
      dateOption: 'days',
      tagCriteria: null,
      filterText: null,
      filterValues: null,
      includeUniqueValues: false,
      moreThanDate: 0,
      uniqueValues: [],
      withinDate: 0,
      reportType: 'op'
    })
  }

  // pro
  const proReviewDate = state.proReport.proData.filters.find(f => f.column === 'reviewDate')
  if (proReviewDate) {
    proReviewDate.active = !!payload
    proReviewDate.dateBetween = payload || null
  } else {
    state.proReport.proData.filters.push({
      active: true,
      column: 'reviewDate',
      columnLabel: 'Review Date',
      columnType: 'date',
      dateBetween: payload || null,
      dateCriteria: 'dateBetween',
      dateOption: 'days',
      tagCriteria: null,
      filterText: null,
      filterValues: null,
      includeUniqueValues: false,
      moreThanDate: 0,
      uniqueValues: [],
      withinDate: 0,
      reportType: 'pro'
    })
  }
}

const SET_REPORTS_OUTPATIENTGROUPERS = state => {
  // outpatient
  if (!state.outpatientReport.outpatientData.filters.some(f => f.column === 'outpatientGrouper')) {
    state.outpatientReport.outpatientData.filters.push({
      active: true,
      column: 'outpatientGrouper',
      columnLabel: 'Grouper',
      columnType: 'text',
      hidden: true,
      dateBetween: [],
      dateCriteria: null,
      dateOption: 'days',
      tagCriteria: null,
      filterText: null,
      filterValues: ['APC'],
      includeUniqueValues: true,
      moreThanDate: 0,
      uniqueValues: [{ key: 'APC', value: 'APC', inactive: false }],
      withinDate: 0,
      reportType: 'op'
    })
  }

  if (!state.ambulatoryReport.outpatientData.filters.some(f => f.column === 'outpatientGrouper')) {
    state.ambulatoryReport.outpatientData.filters.push({
      active: true,
      column: 'outpatientGrouper',
      columnLabel: 'Grouper',
      columnType: 'text',
      hidden: true,
      dateBetween: [],
      dateCriteria: null,
      dateOption: 'days',
      tagCriteria: null,
      filterText: null,
      filterValues: ['ASC'],
      includeUniqueValues: true,
      moreThanDate: 0,
      uniqueValues: [{ key: 'ASC', value: 'ASC', inactive: false }],
      withinDate: 0,
      reportType: 'op'
    })
  }
}

const SET_REPORTS_IP_REPORTDATALOADING = (state, payload) => {
  state.ipReportDataLoading = payload
}

const SET_REPORTS_OP_REPORTDATALOADING = (state, payload) => {
  state.opReportDataLoading = payload
}

const SET_REPORTS_PRO_REPORTDATALOADING = (state, payload) => {
  state.proReportDataLoading = payload
}

const SET_REPORTS_TYPE = (state, payload) => {
  state.inpatientReport.reportType = payload
}

export default {
  updateField,
  SET_REPORTS_INPATIENT_DATA,
  SET_REPORTS_PRO_DATA,
  SET_REPORTS_INPATIENT_VUETABLEDATA,
  SET_REPORTS_INPATIENT_FILTER,
  SET_REPORTS_INPATIENT_GLOBALSEARCH,
  RESET_REPORTS_INPATIENT_FILTER,
  SET_REPORTS_DEFAULTDATERANGE,
  SET_REPORTS_DEFAULTCOMPLETED,
  SET_REPORTS_INPATIENT_REPORTFILTER,
  SET_REPORTS_IP_REPORTDATALOADING,
  SET_REPORTS_PRO_VUETABLEDATA,
  SET_REPORTS_TYPE,
  SET_REPORTS_PRO_FILTER,
  SET_REPORTS_PRO_REPORTFILTER,
  RESET_REPORTS_PRO_FILTER,
  SET_REPORTS_PRO_GLOBALSEARCH,
  SET_REPORTS_PRO_REPORTDATALOADING,
  SET_REPORTS_OP_REPORTDATALOADING,
  SET_REPORTS_OUTPATIENT_DATA,
  SET_REPORTS_OUTPATIENT_VUETABLEDATA,
  SET_REPORTS_OUTPATIENT_FILTER,
  SET_REPORTS_OUTPATIENT_REPORTFILTER,
  RESET_REPORTS_OUTPATIENT_FILTER,
  SET_REPORTS_OUTPATIENT_GLOBALSEARCH,
  SET_REPORTS_OUTPATIENTGROUPERS,
  SET_REPORTS_ASC_VUETABLEDATA,
  SET_REPORTS_ASC_DATA,
  SET_REPORTS_AMBULATORY_FILTER,
  SET_REPORTS_AMBULATORY_REPORTFILTER,
  RESET_REPORTS_AMBULATORY_FILTER,
  SET_REPORTS_AMBULATORY_GLOBALSEARCH
}
