import { updateField } from 'vuex-map-fields'

const SET_PROJECTIDENTIFIERS_LOADING = (state, payload) => {
  state.avoidableDayReasonsListLoading = payload
}

const SET_PROJECTIDENTIFIERS = (state, payload) => {
  state.projectIdentifiersList = payload
}

const UPDATE_PROJECTIDENTIFIERS = (state, payload) => {
  if (payload) {
    const index = state.projectIdentifiersList.findIndex(f => f.identifierId === payload.identifierId)
    state.projectIdentifiersList.splice(index, 1, payload)
  }
}

const SPLICE_PROJECTIDENTIFIERS = (state, identifierId) => {
  const index = state.projectIdentifiersList.findIndex(x => x.identifierId === identifierId)
  state.projectIdentifiersList.splice(index, 1)
}

const PUSH_PROJECTIDENTIFIERS = (state, payload) => {
  payload.isNew = true
  state.projectIdentifiersList.unshift(payload)
}

export default {
  updateField,
  SET_PROJECTIDENTIFIERS,
  UPDATE_PROJECTIDENTIFIERS,
  PUSH_PROJECTIDENTIFIERS,
  SET_PROJECTIDENTIFIERS_LOADING,
  SPLICE_PROJECTIDENTIFIERS
}
