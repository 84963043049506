import { updateField } from 'vuex-map-fields'

const SET_CLIENTS = (state, payload) => {
  state.clientList = payload
}

const SET_SELECTED = (state, payload) => {
  payload.forEach(x => {
    const found = state.coderList.find(y => y.coderId === x.coderId)
    if (found) {
      found.selected = x.selected
    }
  })
  state.coderList = [...state.coderList] // copy the array to trigger reactivity
}

const PUSH_CLIENT = (state, payload) => {
  state.clientList.push(payload)
}

const SPLICE_CLIENT = (state, clientId) => {
  const index = state.clientList.findIndex(x => x.clientId === clientId)
  state.clientList.splice(index, 1)
}

const UPDATE_CLIENT = (state, payload) => {
  if (payload) {
    const index = state.clientList.findIndex(f => f.clientId === payload.clientId)
    state.clientList.splice(index, 1, payload)
  }
}

const SET_CLIENT_LOADING = (state, payload) => {
  state.clientListLoading = payload
}

export default {
  updateField,
  SET_CLIENTS,
  SET_SELECTED,
  PUSH_CLIENT,
  SET_CLIENT_LOADING,
  UPDATE_CLIENT,
  SPLICE_CLIENT
}
