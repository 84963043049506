
import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
  ipAccountList: {
    header: '',
    popOverData: [],
    popperClickTarget: undefined,
    tableType: '',
    reviewType: 'ip',
    row: {},
    isOpen: false
  },
  auditorDashboard: {
    allData: [],
    categories: [],
    statusSeries: [],
    userSeries: []
  },
  auditorDashboardUsers: []
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
