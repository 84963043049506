import axios from '@/utilities/axios-global'

const POST_FINDING = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/findings`, payload.finding)
    context.commit('PUSH_FINDING', response.data.newFinding)
    context.commit('SET_ACTIVE_FINDING', response.data.newFinding)

    if (payload.recordType === 'IP') {
      context.commit('inpatientReviews/SET_AUDIT_RESULT', response.data.auditResult, { root: true })
    }

    if (payload.recordType === 'OP') {
      context.commit('outpatientReviews/SET_AUDIT_RESULT', response.data.auditResult, { root: true })
    }

    if (payload.recordType === 'PRO') {
      context.commit('proReviews/SET_AUDIT_RESULT', response.data.auditResult, { root: true })
    }

    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_FINDING = async (context, payload) => {
  try {
    await axios.put(`${context.rootState.user.baseApiUrl}/reviews/findings/${payload.id}`, payload)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const DELETE_FINDING = async (context, payload) => {
  try {
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/findings/${payload.id}`)
    context.commit('SET_FINDINGS', response.data.allFindings)

    if (payload.recordType === 'IP') {
      context.commit('inpatientReviews/SET_AUDIT_RESULT', response.data.auditResult, { root: true })
    }

    if (payload.recordType === 'OP') {
      context.commit('outpatientReviews/SET_AUDIT_RESULT', response.data.auditResult, { root: true })
    }

    if (payload.recordType === 'PRO') {
      context.commit('proReviews/SET_AUDIT_RESULT', response.data.auditResult, { root: true })
    }
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_FINDINGS_FOR_RESEQUENCE = async (context, payload) => {
  try {
    await axios.put(`${context.rootState.user.baseApiUrl}/reviews/findings/resequence`, payload)
    // context.commit('SET_FINDINGS', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_FINDING = async (context, id) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reviews/findings/${id}`)
    context.commit('SET_ACTIVE_FINDING', response.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  POST_FINDING,
  DELETE_FINDING,
  PUT_FINDING,
  PUT_FINDINGS_FOR_RESEQUENCE,
  GET_FINDING
}
