import axios from '@/utilities/axios-global'

const GET_PAYORDETAIL = async (context, clientNumber) => {
  try {
    context.commit('SET_PAYORDETAIL_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/payordetail`)
    context.commit('SET_PAYORDETAIL', response.data)
    context.commit('SET_PAYORDETAIL_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PAYORDETAIL_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_PAYORDETAIL = async (context, payload) => {
  try {
    context.commit('SET_PAYORDETAIL_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/payordetail`
    const response = await axios.post(url, payload.payorDetail)
    context.commit('PUSH_PAYORDETAIL', response.data)
    context.commit('SET_PAYORDETAIL_LOADING', false)
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_PAYORDETAIL_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_PAYORDETAIL = async (context, payorDetail) => {
  try {
    context.commit('SET_PAYORDETAIL_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/payordetail/${payorDetail.payorDetailId}`
    const response = await axios.put(url, payorDetail)
    context.commit('UPDATE_PAYORDETAIL', response.data)
    context.commit('SET_PAYORDETAIL_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PAYORDETAIL_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const LOAD_PAYAORDETAIL_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/payordetail/paginated`, payload.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const DELETE_PAYORDETAIL = async (context, payorDetailId) => {
  try {
    context.commit('SET_PAYORDETAIL_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/payordetail/${payorDetailId}`)
    context.commit('SPLICE_PAYORDETAIL', payorDetailId)
    context.commit('SET_PAYORDETAIL_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PAYORDETAIL_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_PAYORDETAIL,
  POST_PAYORDETAIL,
  PUT_PAYORDETAIL,
  LOAD_PAYAORDETAIL_PAGINATED,
  DELETE_PAYORDETAIL
}
