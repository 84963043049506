
const SET_DATERANGE = (state, payload) => {
  state.dateRange = [payload[0], payload[1]]
}

const SET_HELIXUPDATEMESSAGE = (state, payload) => {
  state.helixUpdateMessage = payload
}

export default {
  SET_DATERANGE,
  SET_HELIXUPDATEMESSAGE
}
