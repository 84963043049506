import { updateField } from 'vuex-map-fields'

const SET_REQUIREDFIELDS = (state, payload) => {
  state.requiredFields = payload

  state.ipRequiredFieldsGridData = state.requiredFields.find(f => f.reviewType === 'ip').requiredFieldsData
  state.opRequiredFieldsGridData = state.requiredFields.find(f => f.reviewType === 'op').requiredFieldsData
  state.proRequiredFieldsGridData = state.requiredFields.find(f => f.reviewType === 'pro').requiredFieldsData
}

const SET_REQUIREDFIELDS_LOADING = (state, payload) => {
  state.requiredFieldsLoading = payload
}

export default {
  updateField,
  SET_REQUIREDFIELDS,
  SET_REQUIREDFIELDS_LOADING
}
