import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  inpatientReview: {
    project: {},
    drgRecommendationsDx: [],
    drgRecommendationsPx: []
  },
  ipListSelected: [],
  ipListRecordValidations: [],
  statistics: {},
  ipProjectStatistics: {},
  dxLoading: false,
  pxLoading: false,
  refreshIpList: function() {}
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
