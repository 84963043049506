import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'
import exams from './exams/store'

const state = {
  proReviews: [],
  proReview: {
    project: {},
    proRecordDxs: [],
    proRecordCpts: [],
    proRecordHistory: [],
    examScoring: [],
    proRecordDecisionMaking: [],
    auditFindings: [],
    checklistItems: []
  },
  newProRecordID: 0,
  newProrecordCodedID: 0,
  accountLookupData: [],
  proListSelected: [],
  proListRecordValidations: [],
  statistics: {},
  proProjectStatistics: {},
  examElements: [],
  cptLoading: false,
  dxLoading: false,
  refreshProList: function () { }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    exams
  }
}
