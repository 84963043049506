import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  projectIdentifiersList: [],
  projectIdentifiersLoading: false,
  newprojectDefaultValues: {
    projectID: '',
    clientId: '',
    coderId: '',
    identifierID: '',
    startDate: '',
    endDate: '',
    projectTypeId: null,
    userId: null,
    leadId: null,
    review: {},
    identifierSelections: [],
    identifierSelectionsArray: [],
    projectColor: null,
    projectStatusId: 0
  },
  showAddProjectForm: false,
  newProjectID: 0,
  projectList: [],
  projectListBackup: [],
  calendarProjectList: [],
  projectLoading: false,
  recentProjects: [],
  projectPK: 0,
  project: {},
  drgTableIds: '',
  proRecordIds: '',
  ascTableIds: ''
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
