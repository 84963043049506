import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
  examElements: [],
  examElementsRefresh: 0,
  proRecordExamDetails: []
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
