import { updateField } from 'vuex-map-fields'

const SET_PROJECT_TASKS = (state, payload) => {
  state.taskList = payload
}

const PUSH_PROJECT_TASK = (state, payload) => {
  state.taskList.push(payload)
}

const SPLICE_PROJECT_TASK = (state, payload) => {
  const index = state.taskList.findIndex(x => x.taskId === payload.taskId)
  state.taskList.splice(index, 1)
}

const SET_PROJECT_TASK_LOADING = (state, payload) => {
  state.taskListLoading = payload
}

const UPDATE_PROJECT_TASK = (state, payload) => {
  const index = state.taskList.findIndex(x => x.taskId === payload.taskId)
  state.taskList.splice(index, 1, payload)
}

export default {
  updateField,
  SET_PROJECT_TASKS,
  PUSH_PROJECT_TASK,
  SPLICE_PROJECT_TASK,
  SET_PROJECT_TASK_LOADING,
  UPDATE_PROJECT_TASK
}
