import { updateField } from 'vuex-map-fields'

const SET_CODERS = (state, payload) => {
  state.coderList = payload
}

const SET_SELECTED = (state, payload) => {
  payload.forEach(x => {
    const found = state.coderList.find(y => y.coderId === x.coderId)
    if (found) {
      found.selected = x.selected
    }
  })
  state.coderList = [...state.coderList] // copy the array to trigger reactivity
}

const PUSH_CODER = (state, payload) => {
  state.coderList.push(payload)
}

const SPLICE_CODER = (state, payload) => {
  const index = state.coderList.findIndex(x => x.coderId === payload.coderId)
  state.coderList.splice(index, 1)
}

const SET_CODER_LOADING = (state, payload) => {
  state.coderListLoading = payload
}

const UPDATE_CODER = (state, payload) => {
  const index = state.coderList.findIndex(x => x.coderId === payload.coderId)
  state.coderList.splice(index, 1, payload)
}

export default {
  updateField,
  SET_CODERS,
  SET_SELECTED,
  PUSH_CODER,
  SPLICE_CODER,
  SET_CODER_LOADING,
  UPDATE_CODER
}
