import axios from '@/utilities/axios-global'

const GET_REQUIREDFIELDS = async context => {
  try {
    context.commit('SET_REQUIREDFIELDS_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/requiredfields`)
    context.commit('SET_REQUIREDFIELDS', response.data)
    context.commit('SET_REQUIREDFIELDS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_REQUIREDFIELDS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_REQUIREDFIELDS = async (context, payload) => {
  try {
    context.commit('SET_REQUIREDFIELDS_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/requiredfields`
    const response = await axios.put(url, payload)
    context.commit('SET_REQUIREDFIELDS', response.data)
    context.commit('SET_REQUIREDFIELDS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_REQUIREDFIELDS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_REQUIREDFIELDS,
  PUT_REQUIREDFIELDS
}
