import axios from '@/utilities/axios-global'

export function GET_PROFILE(context) {
  return new Promise((resolve, reject) => {
    axios.get(process.env.VUE_APP_API_BASE_URL + '/api/catalog/userprofile').then(response => {
      context.commit('SET_USER_META', response.data)
      context.commit('INITIALIZE_CURRENT_TENANT')
      resolve(response)
    }, error => {
      console.log(error)
      reject(error)
    })
  })
}

export function CHANGE_TENANT(context, guid) {
  return new Promise((resolve, reject) => {
    context.commit('SET_CURRENT_TENANT_BY_GUID', guid)
    resolve(true)
  })
}

export function CHANGE_PASSWORD() {
  const redirect = window.location.href
  axios.get(process.env.VUE_APP_API_BASE_URL + '/api/user/pwticket?redirect=' + redirect).then(response => {
    if (response.data.ticket) {
      window.location = response.data.ticket
    }
  }, error => {
    console.log(error)
  })
}

export default {
  GET_PROFILE,
  CHANGE_TENANT,
  CHANGE_PASSWORD
}
