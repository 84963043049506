import axios from '@/utilities/axios-global'

const GET_PROJECT_PROCESSES = async (context) => {
  try {
    context.commit('SET_PROJECT_PROCESSES_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/client-project-processes`)
    context.commit('SET_PROJECT_PROCESSES', response.data)
    context.commit('SET_PROJECT_PROCESSES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_PROCESSES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_PROJECT_PROCESS = async (context, payload) => {
  try {
    context.commit('SET_PROJECT_PROCESSES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/client-project-processes`
    const response = await axios.post(url, payload.process)
    context.commit('PUSH_PROJECT_PROCESS', response.data)
    context.commit('SET_PROJECT_PROCESSES_LOADING', false)
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_PROCESSES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_PROJECT_PROCESS = async (context, process) => {
  try {
    context.commit('SET_PROJECT_PROCESSES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/client-project-processes/${process.processId}`
    const response = await axios.put(url, process)
    context.commit('SET_PROJECT_PROCESSES_LOADING', false)
    context.commit('UPDATE_PROJECT_PROCESS', response.data)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_PROCESSES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_PROJECT_PROCESS = async (context, process) => {
  try {
    context.commit('SET_PROJECT_PROCESSES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/client-project-processes/${process.processId}`
    await axios.delete(url)
    context.commit('SPLICE_PROJECT_PROCESS', process)
    context.commit('SET_PROJECT_PROCESSES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECT_PROCESSES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_PROJECT_PROCESSES,
  DELETE_PROJECT_PROCESS,
  PUT_PROJECT_PROCESS,
  POST_PROJECT_PROCESS
}
