import axios from '@/utilities/axios-global'

const GET_PROJECTIDENTIFIERS = async (context) => {
  try {
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/projectsidentifiers`)
    context.commit('SET_PROJECTIDENTIFIERS', response.data)
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_PROJECTIDENTIFIERS = async (context, payload) => {
  try {
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/projectsidentifiers/${payload.identifierId}`
    const response = await axios.put(url, payload)
    context.commit('UPDATE_PROJECTIDENTIFIERS', response.data)
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_PROJECTIDENTIFIERS = async (context, payload) => {
  try {
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/projectsidentifiers`, payload.projectIdentifier)
    context.commit('PUSH_PROJECTIDENTIFIERS', response.data)
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_PROJECTIDENTIFIERS = async (context, identifierId) => {
  try {
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/projectsidentifiers/${identifierId}`)
    context.commit('SPLICE_PROJECTIDENTIFIERS', identifierId)
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROJECTIDENTIFIERS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_PROJECTIDENTIFIERS,
  PUT_PROJECTIDENTIFIERS,
  POST_PROJECTIDENTIFIERS,
  DELETE_PROJECTIDENTIFIERS
}
