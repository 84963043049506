import axios from '@/utilities/axios-global'

const GET_CODERS = async (context, clientNumber) => {
  try {
    context.commit('SET_CODER_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/coders`)
    context.commit('SET_CODERS', response.data)
    context.commit('SET_CODER_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CODER_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_CODER = async (context, payload) => {
  try {
    context.commit('SET_CODER_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/coders`
    const response = await axios.post(url, payload.coder)
    context.commit('PUSH_CODER', response.data)
    context.commit('SET_CODER_LOADING', false)
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_CODER_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_MERGE_CODER = (context, payload) => {
  return new Promise((resolve, reject) => {
    context.commit('SET_CODER_LOADING', true)
    const url = context.rootState.user.baseApiUrl + `/coders/merge?targetcoderId=${payload.targetCoderId}`
    axios.put(url, payload.coder).then(response => {
      context.commit('SET_CODER_LOADING', false)
      payload.coder.forEach(x => {
        context.commit('SPLICE_CODER', x)
      })
      resolve()
    }, error => {
      context.commit('SET_CODER_LOADING', false)
      console.log(error)
      reject(error)
    })
  })
}

const PUT_CODER = async (context, coder) => {
  try {
    context.commit('SET_CODER_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/coders/${coder.coderId}`
    const response = await axios.put(url, coder)
    context.commit('SET_CODER_LOADING', false)
    context.commit('UPDATE_CODER', response.data)
  } catch (err) {
    console.log(err)
    context.commit('SET_CODER_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_CODER = async (context, coder) => {
  try {
    context.commit('SET_CODER_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/coders/${coder.coderId}`
    await axios.delete(url)
    context.commit('SPLICE_CODER', coder)
    context.commit('SET_CODER_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_CODER_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const LOAD_CODER_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/coders/paginated?`, payload.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_CODERS,
  DELETE_CODER,
  PUT_CODER,
  POST_CODER,
  PUT_MERGE_CODER,
  LOAD_CODER_PAGINATED
}
