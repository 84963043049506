import axios from '@/utilities/axios-global'

const GET_PROVIDERS_NEW = async (context, clientNumber) => {
  try {
    context.commit('SET_PROVIDERS_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/providers`)
    context.commit('SET_PROVIDERS', response.data)
    context.commit('SET_PROVIDERS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROVIDERS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_PROVIDER_NEW = async (context, payload) => {
  try {
    context.commit('SET_PROVIDERS_LOADING', true)
    await axios.delete(`${context.rootState.user.baseApiUrl}/providers/${payload.providerId}`)
    context.commit('SPLICE_PROVIDER', payload)
    context.commit('SET_PROVIDERS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROVIDERS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_PROVIDER_NEW = async (context, payload) => {
  try {
    context.commit('SET_PROVIDERS_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/providers?practiceId=${payload.practiceId ? payload.practiceId : 0}`, payload.provider)
    context.commit('PUSH_PROVIDER', response.data)
    context.commit('SET_PROVIDERS_LOADING', false)
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_PROVIDERS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_PROVIDER_NEW = async (context, payload) => {
  try {
    context.commit('SET_PROVIDERS_LOADING', true)
    await axios.put(`${context.rootState.user.baseApiUrl}/providers/${payload.providerId}`, payload)
    context.commit('SET_PROVIDERS_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_PROVIDERS_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_MERGE_PROVIDERS = (context, payload) => {
  return new Promise((resolve, reject) => {
    context.commit('SET_PROVIDERS_LOADING', true)
    const url = context.rootState.user.baseApiUrl + `/providers/merge?targetProviderId=${payload.targetProviderId}`
    axios.put(url, payload.providers).then(response => {
      context.commit('SET_PROVIDERS_LOADING', true)
      // Message({
      //   message: 'Providers Merged Successfully',
      //   type: 'success'
      // })

      payload.providers.forEach(x => {
        context.commit('SPLICE_PROVIDER', x)
      })

      resolve(response)
    }, error => {
      context.commit('SET_PROVIDERS_LOADING', false)
      // Message({
      //   message: 'Error Merging Providers' + error,
      //   type: 'error'
      // })
      console.log(error)
      reject(error)
    })
  })
}

const LOAD_PROVIDER_PAGINATED = async (context, payload) => {
  try {
    const reponse = await axios.post(`${context.rootState.user.baseApiUrl}/providers/paginated?`, payload.data)
    return reponse.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_PRO_PROVIDER_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/providers/pro/${payload.practiceNumber}/paginated?`, payload.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_PROVIDERS_NEW,
  DELETE_PROVIDER_NEW,
  POST_PROVIDER_NEW,
  PUT_PROVIDER_NEW,
  PUT_MERGE_PROVIDERS,
  LOAD_PROVIDER_PAGINATED,
  LOAD_PRO_PROVIDER_PAGINATED
}
