import { format, parseISO, isDate } from 'date-fns'

const dateHelper = {
  methods: {
    formatDate: function (date) {
      if (!date) {
        return ''
      }

      if (isDate(date)) {
        return format(date, 'MM/dd/yyyy')
      }

      return format(parseISO(date), 'MM/dd/yyyy')
    },
    formatLongDate: function (date) {
      //   return moment(date).format('dddd, MMMM Do YYYY')
    },
    formatDateTime(dateTimeVal) {
      //   let formattedDateTime = moment(dateTimeVal).format('MM/DD/YYYY hh:mm:ss')
      //   if (formattedDateTime === 'Invalid date') {
      //     return ''
      //   }
      //   return formattedDateTime
    },
    newDateRange(startDateOffset, endDateOffset) {
      const startDate = new Date()
      startDate.setDate(startDate.getDate() + startDateOffset)

      const endDate = new Date()
      endDate.setDate(endDate.getDate() + endDateOffset)

      return [this.formatDate(startDate), this.formatDate(endDate)]
    },
    numberDaysBetween(startDate, endDate) {
      //   let fromDate = moment(startDate)
      //   let toDate = moment(endDate)

      //   return toDate.diff(fromDate, 'days')
    },
    enumerateDateRange(startDate, endDate, excludeWeekends) {
      // let numDays = this.numberDaysBetween(startDate, endDate)
      // let days = []
      // let dateVal = moment(startDate)
      // let valid = true
      // for (let i = 0; i <= numDays; i++) {
      //   valid = true
      //   if (excludeWeekends) {
      //     let weekDay = dateVal.weekday()
      //     if ((weekDay === 0) || (weekDay === 6)) {
      //       valid = false
      //     }
      //   }
      //   if (valid) {
      //     days.push(dateVal.toISOString())
      //   }
      //   dateVal.add(1, 'days')
      // }
      // return days
    },
    enumberateDateRangeLongDate(startDate, endDate) {
      // let arr = this.enumerateDateRange(startDate, endDate)
      // let formattedArray = []
      // arr.forEach(dt => {
      //   formattedArray.push({
      //     key: dt,
      //     value: moment(dt).format('dddd MMMM Do YYYY')
      //   })
      // })
      // console.log(formattedArray)
      // return formattedArray
    },
    datesEqual(date1, date2) {
      // let firstDate = moment(date1)
      // let secondDate = moment(date2)
      // let valid = (firstDate.month() === secondDate.month()) && (firstDate.year() === secondDate.year()) && (firstDate.date() === secondDate.date())
      // // console.log(firstDate.month())
      // // console.log(firstDate.year())
      // // console.log(firstDate.date())
      // // console.log(secondDate.month())
      // // console.log(secondDate.year())
      // // console.log(secondDate.date())
      // // console.log(secondDate)
      // // console.log(valid)
      // return valid
    },
    getLastDayOfMonth(date) {
      const localDate = new Date(date)
      return new Date(localDate.getFullYear(), localDate.getMonth() + 1, 0)
    },
    getCurrentYear() {
      return new Date().getFullYear()
    }
  }
}

export default dateHelper
