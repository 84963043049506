import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user/store'
import userAdmin from './modules/userAdmin/store'
import managedLists from './modules/reviews/managedLists/store'
import recommendationLibrary from './modules/reviews/recommendationLibrary/store'
import dropdowns from './modules/dropdowns/store'
import globals from './modules/globals/store'
import inpatientReviews from './modules/reviews/inpatient/store'
import proReviews from './modules/reviews/pro/store'
import outpatientReviews from './modules/reviews/outpatient/store'
import rebuttals from './modules/reviews/rebuttals/store'
import auditFindings from './modules/reviews/auditFindings/store'
import reports from './modules/reports/store'
import home from './modules/home/store'
import coder from './modules/coder/store'
import notifications from './modules/notifications/store'
import userProfile from './modules/userProfile/store'
import pulllists from './modules/reviews/pulllists/store'
import auditChecklists from './modules/reviews/auditChecklists/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    userAdmin,
    managedLists,
    dropdowns,
    globals,
    inpatientReviews,
    proReviews,
    rebuttals,
    recommendationLibrary,
    reports,
    home,
    coder,
    notifications,
    userProfile,
    outpatientReviews,
    auditFindings,
    pulllists,
    auditChecklists
  }
})
