import axios from '@/utilities/axios-global'

const GET_SERVICELINESLIST = async (context) => {
  try {
    context.commit('SET_SERVICELINESLIST_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/clientservicelines`)
    context.commit('SET_SERVICELINESLIST', response.data)
    context.commit('SET_SERVICELINESLIST_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_SERVICELINESLIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_SERVICELINESLIST = async (context, payload) => {
  try {
    context.commit('SET_SERVICELINESLIST_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/clientservicelines/${payload.clientServiceLineId}`
    const response = await axios.put(url, payload)
    context.commit('UPDATE_SERVICELINESLIST', response.data)
    context.commit('SET_SERVICELINESLIST_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_SERVICELINESLIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const POST_SERVICELINESLIST = async (context, payload) => {
  try {
    context.commit('SET_SERVICELINESLIST_LOADING', true)
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/clientservicelines`, payload.clientServiceLine)
    context.commit('PUSH_SERVICELINESLIST', response.data)
    context.commit('SET_SERVICELINESLIST_LOADING', false)
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_SERVICELINESLIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_SERVICELINELIST = async (context, serviceLine) => {
  try {
    context.commit('SET_SERVICELINESLIST_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/clientservicelines/${serviceLine.clientServiceLineId}`
    await axios.delete(url)
    context.commit('SPLICE_SERVICELINELIST', serviceLine)
    context.commit('SET_SERVICELINESLIST_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_SERVICELINESLIST_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_MERGE_SERVICELINELIST = (context, payload) => {
  return new Promise((resolve, reject) => {
    context.commit('SET_SERVICELINESLIST_LOADING', true)
    const url = context.rootState.user.baseApiUrl + `/coders/merge?targetcoderId=${payload.targetCoderId}`
    axios.put(url, payload.coder).then(response => {
      context.commit('SET_SERVICELINESLIST_LOADING', false)
      // Message({
      //   message: 'Coder Staff Merged Successfully',
      //   type: 'success'
      // })

      payload.coder.forEach(x => {
        context.commit('SPLICE_CODER', x)
      })

      resolve(response)
    }, error => {
      context.commit('SET_SERVICELINESLIST_LOADING', false)
      // Message({
      //   message: 'Error Merging Coder Staff' + error,
      //   type: 'error'
      // })
      console.log(error)
      reject(error)
    })
  })
}

const LOAD_SERVICELINE_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/clientservicelines/paginated?`, payload.data)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_SERVICELINESLIST,
  PUT_SERVICELINESLIST,
  POST_SERVICELINESLIST,
  DELETE_SERVICELINELIST,
  PUT_MERGE_SERVICELINELIST,
  LOAD_SERVICELINE_PAGINATED
}
