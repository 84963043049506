import { format, parseISO } from 'date-fns'
import money from '@/mixins/money'
const hiaFilters = {
  methods: {
    filterList: function (array, criteria, columns = [], noDeleteFalsy = false) {
      // set array keys
      if ((array) && array.length) {
        const objKeys = Object.keys(array[0])
        const objKeysLength = objKeys.length
        let response = []
        // filter on every key for the given criteria
        response = array.filter(function (obj) {
          // delete falsy attributes in this obj
          if (!noDeleteFalsy) {
            for (let j = 0; j < objKeysLength; j++) {
              if (Object.prototype.hasOwnProperty.call(obj, objKeys[j]) && obj[objKeys[j]] !== 0 && !obj[objKeys[j]]) {
                delete obj[objKeys[j]]
              }
            }
          }

          // return match
          for (const i in obj) {
            // column check flag
            let col = true
            if (columns.length && !columns.some(x => x === i)) {
              col = false
            }

            // skip arrays and objects
            if (!hiaFilters.methods.isArrayObject(obj[i])) {
              // column flag
              if (col) {
                const regEx = new RegExp(`${hiaFilters.methods.escapeRegExp(criteria)}`, 'ig')
                // convert to HIA date format if date

                if (!noDeleteFalsy) {
                  if (!isNaN(Date.parse(obj[i].toString())) && isNaN(obj[i])) {
                    try {
                      const dt = parseISO(obj[i].toString())
                      if (dt) {
                        const dateString = format(dt, 'M/d/yyyy hh:mm a')
                        if (dateString.toString().toLowerCase().match(regEx)) {
                          return obj
                        }
                      }
                    } catch (e) {
                      // do nothing...this is just a try parse and its ok if it fails
                    }
                  }
                }

                // is decimal
                if (typeof (obj[i]) !== typeof (String())) {
                  let decimalString = ''
                  const checkDecimal = (obj[i] - Math.floor(obj[i])) !== 0
                  const sigFigCheck = checkDecimal ? obj[i].toString().split('.')[1].length : 0
                  // percentage values have more than 2 sig figs
                  if (checkDecimal && sigFigCheck > 2) {
                    decimalString = `${(obj[i] * 100).toFixed(2)}%`
                  }
                  // most HIA dollar figures come back with 2 sig figs
                  if (checkDecimal && sigFigCheck === 2) {
                    decimalString = `$${money.methods.formatMoney(obj[i])}`
                  }
                  // check for 100%
                  if (`${(obj[i] * 100).toFixed(2)}%` === '100.00%') {
                    decimalString = `${(obj[i] * 100).toFixed(2)}%`
                  }

                  if (decimalString.toString().toLowerCase().match(regEx)) {
                    return obj
                  }
                }

                if (obj[i] && obj[i].toString().toLowerCase().match(regEx)) {
                  return obj
                }
              }
            }
          }
          return false
        })

        return response
      }
      return []
    },
    highlightValue: function (value, criteria) {
      if (value === '<Not Specified>') {
        value = '&lt;Not Specified&gt;'
      }

      if (!criteria.length) {
        return value
      }

      if (!hiaFilters.methods.isArrayObject(value) && value !== undefined) {
        const regEx = new RegExp(`${hiaFilters.methods.escapeRegExp(criteria)}`, 'ig')

        const findme = value.toString().match(regEx)
        const dedupe = [...new Set(findme)]
        for (const l in dedupe) {
          const regEx2 = new RegExp(hiaFilters.methods.escapeRegExp(dedupe[l]), 'g')
          value = value.toString().replace(regEx2, `<span style="background: rgba(255,255,0,1);padding: 0px 0px 0px 0px">${dedupe[l]}</span>`)
        }
        return value
      }
    },
    boolSortComparer(prop) {
      return (a, b) => {
        const a1 = !!a[prop]
        const b1 = !!b[prop]

        if (a1 < b1) {
          return 1
        }
        if (a1 > b1) {
          return -1
        }
        return 0
      }
    },
    isArrayObject: function (value) {
      if (Array.isArray(value)) { return true }
      if (value && typeof value === 'object' && value.constructor === Object) { return true }
    },
    escapeRegExp: function (text) {
      return text.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&')
    }
  }
}

export default hiaFilters
