import { updateField } from 'vuex-map-fields'

const SET_ASC_PATIENT_TYPES_LOADING = (state, payload) => {
  state.ascPatientTypesLoading = payload
}

const SET_ASC_PATIENT_TYPES = (state, payload) => {
  state.ascPatientTypesList = payload
}

const UPDATE_ASC_PATIENT_TYPES = (state, payload) => {
  if (payload) {
    const index = state.ascPatientTypesList.findIndex(f => f.patientTypeId === payload.patientTypeId)
    state.ascPatientTypesList.splice(index, 1, payload)
  }
}

const SPLICE_ASC_PATIENT_TYPES = (state, patientTypeId) => {
  const index = state.ascPatientTypesList.findIndex(x => x.patientTypeId === patientTypeId)
  state.ascPatientTypesList.splice(index, 1)
}

const PUSH_ASC_PATIENT_TYPES = (state, payload) => {
  state.ascPatientTypesList.push(payload)
}

export default {
  updateField,
  SET_ASC_PATIENT_TYPES_LOADING,
  SET_ASC_PATIENT_TYPES,
  UPDATE_ASC_PATIENT_TYPES,
  SPLICE_ASC_PATIENT_TYPES,
  PUSH_ASC_PATIENT_TYPES
}
