import axios from '@/utilities/axios-global'

const GET_REPORTQUERIES = async (context, clientNumber) => {
  try {
    context.commit('SET_REPORTQUERIES_LOADING', true)
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reportqueries`)
    context.commit('SET_REPORTQUERIES', response.data)
    context.commit('SET_REPORTQUERIES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_REPORTQUERIES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const GET_REPORTQUERIES_USERS_LIST = async (context, clientNumber) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reportqueries/users-list`)
    context.commit('SET_REPORTQUERIES_USERS_LIST', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_REPORTQUERIES = async (context, payload) => {
  try {
    context.commit('SET_REPORTQUERIES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/reportqueries`
    const response = await axios.post(url, payload)
    context.commit('PUSH_REPORTQUERY', response.data)
    context.commit('SET_REPORTQUERIES_LOADING', false)
    return response.data
  } catch (err) {
    console.log(err)
    context.commit('SET_REPORTQUERIES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_REPORTQUERIES = async (context, reportQuery) => {
  try {
    context.commit('SET_REPORTQUERIES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/reportqueries/${reportQuery.reportQueryId}`
    const response = await axios.put(url, reportQuery)
    context.commit('UPDATE_REPORTQUERY', response.data)
    context.commit('SET_REPORTQUERIES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_REPORTQUERIES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const PUT_SHAREREPORTQUERIES = async (context, payload) => {
  try {
    context.commit('SET_REPORTQUERIES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/reportqueries/share/${payload.reportQuery.reportQueryId}`
    await axios.put(url, payload.userIds)
    // context.commit('UPDATE_REPORTQUERY', response.data)
    context.commit('SET_REPORTQUERIES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_REPORTQUERIES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

const DELETE_REPORTQUERIES = async (context, reportQuery) => {
  try {
    context.commit('SET_REPORTQUERIES_LOADING', true)
    const url = `${context.rootState.user.baseApiUrl}/reportqueries/${reportQuery.reportQueryId}`
    await axios.delete(url)
    context.commit('SPLICE_REPORTQUERY', reportQuery)
    context.commit('SET_REPORTQUERIES_LOADING', false)
  } catch (err) {
    console.log(err)
    context.commit('SET_REPORTQUERIES_LOADING', false)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_REPORTQUERIES,
  POST_REPORTQUERIES,
  PUT_REPORTQUERIES,
  DELETE_REPORTQUERIES,
  PUT_SHAREREPORTQUERIES,
  GET_REPORTQUERIES_USERS_LIST
}
