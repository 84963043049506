import { getField } from 'vuex-map-fields'

const GET_PROJECT_IDENTIFIER = state => primaryKey => {
    const project = state.projectList.find(p => p.id === primaryKey)
    if (project) {
        return project.projectID
    }
    return null
}

export default {
    getField,
    GET_PROJECT_IDENTIFIER
}
