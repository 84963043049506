import { updateField } from 'vuex-map-fields'
import Vue from 'vue'

const SET_OUTPATIENT_REVIEW = (state, payload) => {
  state.outpatientReview = payload
}

const SET_AUDIT_RESULT = (state, auditResult) => {
  state.outpatientReview.auditResult = auditResult
}

const SET_TRUCODE_SUBSET = (state, payload) => {
  if (state.outpatientReview.ascRecommendationsDx.length === payload.ascRecommendationsDx.length) {
    // loop over codes and update Trucode only
    for (const i in payload.ascRecommendationsDx) {
      const dxIndex = state.outpatientReview.ascRecommendationsDx.findIndex(f => f.dxId === payload.ascRecommendationsDx[i].dxId)
      if (dxIndex !== -1) {
        state.outpatientReview.ascRecommendationsDx[dxIndex].lineSequence = payload.ascRecommendationsDx[i].lineSequence
        state.outpatientReview.ascRecommendationsDx[dxIndex].grouperFlags = payload.ascRecommendationsDx[i].grouperFlags

        state.outpatientReview.ascRecommendationsDx[dxIndex].originalDescCalc = payload.ascRecommendationsDx[i].originalDescCalc
        state.outpatientReview.ascRecommendationsDx[dxIndex].edits = payload.ascRecommendationsDx[i].edits
        state.outpatientReview.ascRecommendationsDx[dxIndex].originalHccs = payload.ascRecommendationsDx[i].originalHccs
        state.outpatientReview.ascRecommendationsDx[dxIndex].hac = payload.ascRecommendationsDx[i].hac
        state.outpatientReview.ascRecommendationsDx[dxIndex].originalPoa = payload.ascRecommendationsDx[i].originalPoa

        state.outpatientReview.ascRecommendationsDx[dxIndex].recommendedDx = payload.ascRecommendationsDx[i].recommendedDx
        state.outpatientReview.ascRecommendationsDx[dxIndex].newPrimary = payload.ascRecommendationsDx[i].newPrimary
        state.outpatientReview.ascRecommendationsDx[dxIndex].recDescCalc = payload.ascRecommendationsDx[i].recDescCalc
        state.outpatientReview.ascRecommendationsDx[dxIndex].recEdits = payload.ascRecommendationsDx[i].recEdits
        state.outpatientReview.ascRecommendationsDx[dxIndex].recommendedHccs = payload.ascRecommendationsDx[i].recommendedHccs
        state.outpatientReview.ascRecommendationsDx[dxIndex].rechac = payload.ascRecommendationsDx[i].rechac
        state.outpatientReview.ascRecommendationsDx[dxIndex].recommendedPoa = payload.ascRecommendationsDx[i].recommendedPoa

        state.outpatientReview.ascRecommendationsDx[dxIndex].level2Dx = payload.ascRecommendationsDx[i].level2Dx
        state.outpatientReview.ascRecommendationsDx[dxIndex].level2Edits = payload.ascRecommendationsDx[i].level2Edits
        state.outpatientReview.ascRecommendationsDx[dxIndex].level2NewPrimary = payload.ascRecommendationsDx[i].level2NewPrimary
        state.outpatientReview.ascRecommendationsDx[dxIndex].level2Hccs = payload.ascRecommendationsDx[i].level2Hccs
        state.outpatientReview.ascRecommendationsDx[dxIndex].level2DescCalc = payload.ascRecommendationsDx[i].level2DescCalc
        state.outpatientReview.ascRecommendationsDx[dxIndex].level2Poa = payload.ascRecommendationsDx[i].level2Poa

        state.outpatientReview.ascRecommendationsDx[dxIndex].hasRecommendation = payload.ascRecommendationsDx[i].hasRecommendation
      }
    }
  } else {
    state.outpatientReview.ascRecommendationsDx = payload.ascRecommendationsDx
  }

  if (state.outpatientReview.ascRecommendationsPx.length === payload.ascRecommendationsPx.length) {
    for (const j in payload.ascRecommendationsPx) {
      const pxIndex = state.outpatientReview.ascRecommendationsPx.findIndex(f => f.pxId === payload.ascRecommendationsPx[j].pxId)
      if (pxIndex !== -1) {
        state.outpatientReview.ascRecommendationsPx[pxIndex].lineSequence = payload.ascRecommendationsPx[j].lineSequence
        state.outpatientReview.ascRecommendationsPx[pxIndex].grouperFlags = payload.ascRecommendationsPx[j].grouperFlags

        state.outpatientReview.ascRecommendationsPx[pxIndex].originalDescCalc = payload.ascRecommendationsPx[j].originalDescCalc
        state.outpatientReview.ascRecommendationsPx[pxIndex].edits = payload.ascRecommendationsPx[j].edits

        state.outpatientReview.ascRecommendationsPx[pxIndex].recommendedPx = payload.ascRecommendationsPx[j].recommendedPx
        state.outpatientReview.ascRecommendationsPx[pxIndex].recDescCalc = payload.ascRecommendationsPx[j].recDescCalc
        state.outpatientReview.ascRecommendationsPx[pxIndex].recEdits = payload.ascRecommendationsPx[j].recEdits

        state.outpatientReview.ascRecommendationsPx[pxIndex].level2Px = payload.ascRecommendationsPx[j].level2Px
        state.outpatientReview.ascRecommendationsPx[pxIndex].level2DescCalc = payload.ascRecommendationsPx[j].level2DescCalc
        state.outpatientReview.ascRecommendationsPx[pxIndex].level2Edits = payload.ascRecommendationsPx[j].level2Edits

        state.outpatientReview.ascRecommendationsPx[pxIndex].hasRecommendation = payload.ascRecommendationsPx[j].hasRecommendation
      }
    }
  } else {
    state.outpatientReview.ascRecommendationsPx = payload.ascRecommendationsPx
  }

  if (state.outpatientReview.ascRecommendationsCptsApcs.length === payload.ascRecommendationsCptsApcs.length) {
    for (const y in payload.ascRecommendationsCptsApcs) {
      const cptIndex = state.outpatientReview.ascRecommendationsCptsApcs.findIndex(f => f.cptId === payload.ascRecommendationsCptsApcs[y].cptId)
      if (cptIndex !== -1) {
        // original
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].orGroupingProcResult = payload.ascRecommendationsCptsApcs[y].orGroupingProcResult
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].orPricingProcResult = payload.ascRecommendationsCptsApcs[y].orPricingProcResult

        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].orAscGroupingProcResult = payload.ascRecommendationsCptsApcs[y].orAscGroupingProcResult
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].orAscPricingProcResult = payload.ascRecommendationsCptsApcs[y].orAscPricingProcResult

        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].edits = payload.ascRecommendationsCptsApcs[y].edits
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].originalDescCalc = payload.ascRecommendationsCptsApcs[y].originalDescCalc
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].originalDescCalcMed = payload.ascRecommendationsCptsApcs[y].originalDescCalcMed

        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].origApcPayment = payload.ascRecommendationsCptsApcs[y].origApcPayment
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].origAscPayment = payload.ascRecommendationsCptsApcs[y].origAscPayment

        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].recommendedCpt = payload.ascRecommendationsCptsApcs[y].recommendedCpt
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].recGroupingProcResult = payload.ascRecommendationsCptsApcs[y].recGroupingProcResult
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].recPricingProcResult = payload.ascRecommendationsCptsApcs[y].recPricingProcResult
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].recAscGroupingProcResult = payload.ascRecommendationsCptsApcs[y].recAscGroupingProcResult
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].recAscPricingProcResult = payload.ascRecommendationsCptsApcs[y].recAscPricingProcResult

        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].recEdits = payload.ascRecommendationsCptsApcs[y].recEdits
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].recDescCalc = payload.ascRecommendationsCptsApcs[y].recDescCalc
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].recDescCalcMed = payload.ascRecommendationsCptsApcs[y].recDescCalcMed

        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].recApcPayment = payload.ascRecommendationsCptsApcs[y].recApcPayment
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].recAscPayment = payload.ascRecommendationsCptsApcs[y].recAscPayment

        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].level2Cpt = payload.ascRecommendationsCptsApcs[y].level2Cpt
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].lvl2GroupingProcResult = payload.ascRecommendationsCptsApcs[y].lvl2GroupingProcResult
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].lvl2PricingProcResult = payload.ascRecommendationsCptsApcs[y].lvl2PricingProcResult
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].lvl2AscGroupingProcResult = payload.ascRecommendationsCptsApcs[y].lvl2AscGroupingProcResult
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].lvl2AscPricingProcResult = payload.ascRecommendationsCptsApcs[y].lvl2AscPricingProcResult
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].level2DescCalc = payload.ascRecommendationsCptsApcs[y].level2DescCalc
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].level2Edits = payload.ascRecommendationsCptsApcs[y].level2Edits

        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].hasRecommendation = payload.ascRecommendationsCptsApcs[y].hasRecommendation
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].hasApcRecommendation = payload.ascRecommendationsCptsApcs[y].hasApcRecommendation
        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].ascRecommendationsCptsGrouping = payload.ascRecommendationsCptsApcs[y].ascRecommendationsCptsGrouping

        state.outpatientReview.ascRecommendationsCptsApcs[cptIndex].ascRecommendationsModifiers = payload.ascRecommendationsCptsApcs[y].ascRecommendationsModifiers
      }
    }
  } else {
    state.outpatientReview.ascRecommendationsCptsApcs = payload.ascRecommendationsCptsApcs
  }

  state.outpatientReview.provider = payload.provider
  state.outpatientReview.clientCoder = payload.clientCoder
  state.outpatientReview.serviceLine = payload.serviceLine
  state.outpatientReview.payorDetail = payload.payorDetail

  state.outpatientReview.orResponse_Object = payload.orResponse_Object
  state.outpatientReview.recResponse_Object = payload.recResponse_Object
  state.outpatientReview.orMNEResponse_Object = payload.orMNEResponse_Object
  state.outpatientReview.recMNEResponse_Object = payload.recMNEResponse_Object
  state.outpatientReview.truCodeTimeStamp = payload.truCodeTimeStamp
  state.outpatientReview.auditResult = payload.auditResult

  state.outpatientReview.identifierSelectionsArray = payload.identifierSelectionsArray
  state.outpatientReview.identifierSelections = payload.identifierSelections
}

const SET_OUTPATIENT_DXS = (state, row) => {
  state.outpatientReview.ascRecommendationsDx = row
}

const SET_OUTPATIENT_DX_ROW = (state, row) => {
  const index = state.outpatientReview.ascRecommendationsDx.findIndex(x => x.dxId === row.dxId)
  Vue.set(state.outpatientReview.ascRecommendationsDx, index, row)
}

const DELETE_DX_ROW = (state, row) => {
  const index = state.outpatientReview.ascRecommendationsDx.findIndex(x => x.dxId === row.dxId)
  state.outpatientReview.ascRecommendationsDx.splice(index, 1)
}

const PUSH_DX_ROW = (state, row) => {
  state.outpatientReview.ascRecommendationsDx.push(row)
}

const SET_OUTPATIENT_PXS = (state, row) => {
  state.outpatientReview.ascRecommendationsPx = row
}

const SET_OUTPATIENT_PX_ROW = (state, row) => {
  const index = state.outpatientReview.ascRecommendationsPx.findIndex(x => x.pxId === row.pxId)
  Vue.set(state.outpatientReview.ascRecommendationsPx, index, row)
}

const DELETE_PX_ROW = (state, row) => {
  const index = state.outpatientReview.ascRecommendationsPx.findIndex(x => x.pxId === row.pxId)
  state.outpatientReview.ascRecommendationsPx.splice(index, 1)
}

const PUSH_PX_ROW = (state, row) => {
  state.outpatientReview.ascRecommendationsPx.push(row)
}

const SET_OUTPATIENT_CPT_ROW = (state, row) => {
  const index = state.outpatientReview.ascRecommendationsCptsApcs.findIndex(x => x.cptId === row.cptId)
  Vue.set(state.outpatientReview.ascRecommendationsCptsApcs, index, row)
}

const DELETE_CPT_ROW = (state, row) => {
  const index = state.outpatientReview.ascRecommendationsCptsApcs.findIndex(x => x.cptId === row.cptId)
  state.outpatientReview.ascRecommendationsCptsApcs.splice(index, 1)
}

const PUSH_CPT_ROW = (state, row) => {
  state.outpatientReview.ascRecommendationsCptsApcs.push(row)
}

const SET_OUTPATIENT_CPTS = (state, payload) => {
  state.outpatientReview.ascRecommendationsCptsApcs = payload
}

const SET_CPT_LOADING = (state, payload) => {
  state.cptLoading = payload
}

const SET_DX_LOADING = (state, payload) => {
  state.dxLoading = payload
}

const SET_PX_LOADING = (state, payload) => {
  state.pxLoading = payload
}

const SET_OUTPATIENT_MODIFIER_ROW = (state, row) => {
  const cpt = state.outpatientReview.ascRecommendationsCptsApcs.find(x => x.cptId === row.cptId)
  const index = cpt.ascRecommendationsModifiers.findIndex(x => x.modifierId === row.modifierId)
  Vue.set(cpt.ascRecommendationsModifiers, index, row)
}

const SET_STATISTICS = (state, payload) => {
  state.statistics = payload
}

const SET_PROJECT_STATISTICS = (state, payload) => {
  state.opProjectStatistics = payload
}

const SET_IPLIST_SELECTED = (state, payload) => {
  if (payload.checked) {
    state.opListSelected.push(payload)
  } else {
    state.opListSelected = state.opListSelected.filter(x => x.drgTableId !== payload.drgTableId)
  }
}

const SET_IPLIST_ALLIDS = (state, payload) => {
  state.opListSelected = payload
}

const CLEAR_IPLIST_SELECTED = (state, payload) => {
  state.opListSelected = []
}

const SET_OPLIST_RECORD_VALIDATIONS = (state, payload) => {
  state.opListRecordValidations = payload
}

const SET_OPLIST_ALLIDS = (state, payload) => {
  state.opListSelected = payload
}

const SET_OPLIST_SELECTED = (state, payload) => {
  if (payload.checked) {
    state.opListSelected.push(payload)
  } else {
    state.opListSelected = state.opListSelected.filter(x => x.ascTableId !== payload.ascTableId)
  }
}

const CLEAR_OPLIST_SELECTED = (state, payload) => {
  state.opListSelected = []
}

const SET_OPLIST_REFRESH_FUNC = (state, func) => {
  state.refreshOpList = func
}

const SET_APCS = (state, payload) => {
  state.apcList = payload
}

export default {
  updateField,
  SET_OUTPATIENT_REVIEW,
  SET_OUTPATIENT_DX_ROW,
  SET_AUDIT_RESULT,
  DELETE_DX_ROW,
  PUSH_DX_ROW,
  SET_TRUCODE_SUBSET,
  SET_OUTPATIENT_DXS,
  SET_OUTPATIENT_PXS,
  SET_OUTPATIENT_PX_ROW,
  DELETE_PX_ROW,
  PUSH_PX_ROW,
  SET_OUTPATIENT_CPT_ROW,
  PUSH_CPT_ROW,
  DELETE_CPT_ROW,
  SET_OUTPATIENT_CPTS,
  SET_CPT_LOADING,
  SET_DX_LOADING,
  SET_PX_LOADING,
  SET_STATISTICS,
  SET_IPLIST_ALLIDS,
  SET_IPLIST_SELECTED,
  CLEAR_IPLIST_SELECTED,
  SET_OPLIST_RECORD_VALIDATIONS,
  SET_OPLIST_ALLIDS,
  SET_OPLIST_SELECTED,
  CLEAR_OPLIST_SELECTED,
  SET_OUTPATIENT_MODIFIER_ROW,
  SET_OPLIST_REFRESH_FUNC,
  SET_APCS,
  SET_PROJECT_STATISTICS
}
