import axios from '@/utilities/axios-global'

const GET_OUTPATIENT_REVIEW = async (context, ascTableId) => {
  try {
    const response = await axios.get(context.rootState.user.baseApiUrl + `/reviews/outpatient/${ascTableId}`)
    context.commit('SET_OUTPATIENT_REVIEW', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const PUT_OUTPATIENT_REVIEW = async (context, payload) => {
  try {
    const response = await axios.put(context.rootState.user.baseApiUrl + `/reviews/outpatient/${payload.outpatientReview.ascTableId}?changedField=${payload.changedField}`, payload.outpatientReview)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_OUTPATIENT_DX = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload.dxRow.ascTableId}/dx/${payload.dxRow.dxId}?changedField=${payload.changedField}`, payload.dxRow)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const PUT_OUTPATIENT_DX_POAASSIGNMENTS = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload.ascTableId}/dx/poaassignments?changedField=${payload.changedField}`, payload.allDxCodes)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const POST_OUTPATIENT_DX = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const ascTableId = payload.length ? payload[0].ascTableId : payload.ascTableId
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${ascTableId}/dx`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const DELETE_OUTPATIENT_DX = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload.ascTableId}/dx/${payload.dxId}`, payload)
    context.commit('DELETE_DX_ROW', payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const PUT_OUTPATIENT_DXS_FOR_RESEQUENCE = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload[0].ascTableId}/dx/resequence`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const PUT_OUTPATIENT_PX = async (context, payload) => {
  try {
    context.commit('SET_PX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload.pxRow.ascTableId}/px/${payload.pxRow.pxId}?changedField=${payload.changedField}`, payload.pxRow)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_PX_LOADING', false)
  }
}

const POST_OUTPATIENT_PX = async (context, payload) => {
  try {
    context.commit('SET_PX_LOADING', true)
    const ascTableId = payload.length ? payload[0].ascTableId : payload.ascTableId
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${ascTableId}/px`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_PX_LOADING', false)
  }
}

const DELETE_OUTPATIENT_PX = async (context, payload) => {
  try {
    context.commit('SET_PX_LOADING', true)
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload.ascTableId}/px/${payload.pxId}`, payload)
    context.commit('DELETE_PX_ROW', payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_PX_LOADING', false)
  }
}

const PUT_OUTPATIENT_PXS_FOR_RESEQUENCE = async (context, payload) => {
  try {
    context.commit('SET_PX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload[0].ascTableId}/px/resequence`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_PX_LOADING', false)
  }
}

const RUN_OP_MASSUPDATE = (context, massUpdateModel) => {
  return new Promise((resolve, reject) => {
    const url = `${context.rootState.user.baseApiUrl}/reviews/outpatient/massupdate`
    axios.put(url, massUpdateModel).then(
      response => {
        resolve()
      },
      error => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const POST_VALIDATION = (context, payload) => {
  return new Promise((resolve, reject) => {
    const url = process.env.VUE_APP_API_BASE_URL + '/api/reviews/outpatient/validaterecords'
    axios.post(url, payload).then(
      response => {
        context.commit('SET_OPLIST_RECORD_VALIDATIONS', response.data)
        resolve()
      },
      error => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const POST_OP_RECORD = async (context, newReview) => {
  try {
    const userEmail = context.rootState.user.user.email
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient?userEmail=${userEmail}`, newReview)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const DELETE_OP_RECORD = async (context, ascTableId) => {
  try {
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${ascTableId}`)
    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const RUN_OP_MASSDELETE = (context, ids) => {
  try {
    const response = axios.put(`${context.rootState.user.baseApiUrl}/reviews/outpatient/massdelete/`, ids)
    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_OP_LIST_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/paginated/${payload.projectID}`, payload)
    const wrapper = response.data
    if (context.state.opListSelected.length > 0) {
      wrapper.data = wrapper.data.map(x => {
        x.checked = context.state.opListSelected.some(y => y.ascTableId === x.ascTableId)
        return x
      })
    }
    return wrapper
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_OP_LIST_ALLIDS = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/allids/${payload.projectID}`, payload)
    context.commit('SET_OPLIST_ALLIDS', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_OPLIST_STATS = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/stats/${payload.projectID}`, payload)
    context.commit('SET_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_OPLIST_PROJECT_STATS = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/stats/${payload.projectID}`, payload)
    context.commit('SET_PROJECT_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_OP_RECORD_MULTIPLE = async (context, list) => {
  try {
    await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/multiple`, list)
  } catch (e) {
    if (e.response.data.message) {
      throw new Error(e.response.data.message)
    }
    throw new Error('Error Creating Records')
  }
}

const POST_OP_TEMPLATE_MULTIPLE = async (context, list) => {
  try {
    await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/importTemplate`, list)
  } catch (e) {
    if (e.response.data.message) {
      throw new Error(e.response.data.message)
    }
    throw new Error('Error Creating Records')
  }
}

const OP_RECORD_EXISTS = async (context, newReview) => {
  try {
    return axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/exists`, newReview)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_NOTIFY_CODERS = async (context, payload) => {
  try {
    await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/notifycoders/`, payload)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_OUTPATIENT_CPT = async (context, payload) => {
  try {
    context.commit('SET_CPT_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload.cptRow.ascTableId}/cpt/${payload.cptRow.cptId}?changedField=${payload.changedField}`, payload.cptRow)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_CPT_LOADING', false)
  }
}

const POST_OUTPATIENT_CPT = async (context, payload) => {
  try {
    context.commit('SET_CPT_LOADING', true)
    const ascTableId = payload.length ? payload[0].ascTableId : payload.ascTableId
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${ascTableId}/cpt`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_CPT_LOADING', false)
  }
}

const DELETE_OUTPATIENT_CPT = async (context, payload) => {
  try {
    context.commit('SET_CPT_LOADING', true)
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload.ascTableId}/cpt/${payload.cptId}`, payload)
    context.commit('DELETE_CPT_ROW', payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_CPT_LOADING', false)
  }
}

const PUT_OUTPATIENT_CPTS_FOR_RESEQUENCE = async (context, payload) => {
  try {
    context.commit('SET_CPT_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload[0].ascTableId}/cpt/resequence`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_CPT_LOADING', false)
  }
}

const PUT_OUTPATIENT_MODIFIERS_FOR_RESEQUENCE = async (context, payload) => {
  try {
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload[0].ascTableId}/modifiers/resequence`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const PUT_OUTPATIENT_MODIFIER = async (context, payload) => {
  try {
    const ascTableId = payload.ascTableId
    const modRow = payload.modRow
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${ascTableId}/modifiers/${modRow.modifierId}?changedField=${payload.changedField}`, modRow)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const POST_OUTPATIENT_MODIFIER = async (context, payload) => {
  try {
    const ascTableId = payload.ascTableId
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${ascTableId}/modifiers`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const DELETE_OUTPATIENT_MODIFIER = async (context, payload) => {
  try {
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/outpatient/${payload.ascTableId}/modifiers/${payload.modifierId}`, payload)
    // context.commit('DELETE_MODIFIER_ROW', payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const GET_APCS = async (context, dateOfService) => {
  try {
    const response = await axios.get(context.rootState.user.baseApiUrl + `/reviews/outpatient/apcList?dateOfService=${dateOfService}`)
    context.commit('SET_APCS', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

export default {
  GET_OUTPATIENT_REVIEW,
  PUT_OUTPATIENT_REVIEW,
  PUT_OUTPATIENT_DX,
  DELETE_OUTPATIENT_DX,
  POST_OUTPATIENT_DX,
  PUT_OUTPATIENT_DX_POAASSIGNMENTS,
  PUT_OUTPATIENT_DXS_FOR_RESEQUENCE,
  PUT_OUTPATIENT_PX,
  POST_OUTPATIENT_PX,
  DELETE_OUTPATIENT_PX,
  PUT_OUTPATIENT_PXS_FOR_RESEQUENCE,
  LOAD_OP_LIST_PAGINATED,
  GET_OPLIST_STATS,
  OP_RECORD_EXISTS,
  GET_OP_LIST_ALLIDS,
  POST_OP_RECORD,
  POST_OP_RECORD_MULTIPLE,
  POST_OP_TEMPLATE_MULTIPLE,
  RUN_OP_MASSUPDATE,
  POST_VALIDATION,
  DELETE_OP_RECORD,
  RUN_OP_MASSDELETE,
  POST_NOTIFY_CODERS,
  PUT_OUTPATIENT_CPT,
  POST_OUTPATIENT_CPT,
  DELETE_OUTPATIENT_CPT,
  PUT_OUTPATIENT_CPTS_FOR_RESEQUENCE,
  PUT_OUTPATIENT_MODIFIERS_FOR_RESEQUENCE,
  PUT_OUTPATIENT_MODIFIER,
  POST_OUTPATIENT_MODIFIER,
  DELETE_OUTPATIENT_MODIFIER,
  GET_APCS,
  GET_OPLIST_PROJECT_STATS
}
