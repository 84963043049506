import { updateField } from 'vuex-map-fields'

const SET_SERVICELINESLIST_LOADING = (state, payload) => {
  state.serviceLinesLoading = payload
}

const SET_SERVICELINESLIST = (state, payload) => {
  state.serviceLinesList = payload
}

const UPDATE_SERVICELINESLIST = (state, payload) => {
  if (payload) {
    const index = state.serviceLinesList.findIndex(f => f.clientServiceLineId === payload.clientServiceLineId)
    state.serviceLinesList.splice(index, 1, payload)
  }
}

const PUSH_SERVICELINESLIST = (state, payload) => {
  state.serviceLinesList.push(payload)
}

const SET_SELECTED = (state, payload) => {
  payload.forEach(x => {
    const found = state.serviceLinesList.find(y => y.clientServiceLineId === x.clientServiceLineId)
    if (found) {
      found.selected = x.selected
    }
  })
  state.serviceLinesList = [...state.serviceLinesList] // copy the array to trigger reactivity
}

const SPLICE_SERVICELINELIST = (state, payload) => {
  const index = state.serviceLinesList.findIndex(x => x.clientServiceLineId === payload.clientServiceLineId)
  state.serviceLinesList.splice(index, 1)
}

export default {
  updateField,
  SET_SERVICELINESLIST,
  UPDATE_SERVICELINESLIST,
  SET_SERVICELINESLIST_LOADING,
  PUSH_SERVICELINESLIST,
  SET_SELECTED,
  SPLICE_SERVICELINELIST
}
